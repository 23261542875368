import { SheetMusicTypes } from "@mom/types"
import { Clock } from "./clock"
import { EventEmitter } from "./event-emitter"

interface NoteTrackerBeatPosition {
    x: number
    y: number
    startBeat: number
    beat: number
    staveCount: number
}

function calculateNoteTrackerTimestamps (renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails, timeline: any): NoteTrackerBeatPosition[] {
    const positions = renderingDetails.lines.flatMap(line => line.measures).flatMap(measure => measure.noteGroups).map(noteGroup => ({
        x: (noteGroup.staveNotes.find(x => x)?.position.x ?? 0) - 0.5,
        y: noteGroup.position.y - 4,
        staveCount: noteGroup.staveNotes.length
    }))

    const noteGroups = timeline.measures.flatMap((measure: any) => measure.noteGroups)

    return positions.map((position, index) => ({
        ...position,
        startBeat: noteGroups[index].startDuration * 4,
        beat: (noteGroups[index].endDuration - noteGroups[index].startDuration) * 4
    }))
}

type NoteTrackerEvents = {
    changePosition: (position?: { x: number, y: number, staveCount: number }) => void
}

interface NoteTrackerOptions {
    clock: Clock
    timeline: any
}

export class NoteTracker extends EventEmitter<NoteTrackerEvents> {
    private clock: Clock
    private beatPositionIndex: number
    private lastBeat: number
    private beatPositions: NoteTrackerBeatPosition[]
    private width: number
    private animationFrame: number
    private timeline: any

    constructor (options: NoteTrackerOptions) {
        super ()

        this.clock = options.clock
        this.timeline = options.timeline
        this.beatPositionIndex = 0
        this.lastBeat = 0
    }

    setRenderingDetails (renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails) {
        this.beatPositions = calculateNoteTrackerTimestamps(renderingDetails, this.timeline)
        this.width = renderingDetails.width
    }

    start () {
        this.calculatePosition()
    }

    pause () {
        window.cancelAnimationFrame (this.animationFrame)
    }

    stop () {
        window.cancelAnimationFrame (this.animationFrame)
        this.trigger ('changePosition', undefined)
    }

    private calculatePosition () {
        const currentBeat = this.clock.current
        if (currentBeat && currentBeat.beat >= 0) {
            if (currentBeat.beat < this.lastBeat) {
                this.beatPositionIndex = 0
            }
            this.lastBeat = currentBeat.beat

            for (; this.beatPositionIndex < this.beatPositions.length && currentBeat.beat >= this.beatPositions[this.beatPositionIndex].startBeat; this.beatPositionIndex++) {}
            const current = this.beatPositions[this.beatPositionIndex - 1]

            if (current) {
                const nextX = this.beatPositions[this.beatPositionIndex] && current.y === this.beatPositions[this.beatPositionIndex].y ? this.beatPositions[this.beatPositionIndex].x : this.width

                this.trigger ('changePosition', {
                    x: current.x + (currentBeat.beat - current.startBeat) * (nextX - current.x) / current.beat,
                    y: current.y,
                    staveCount: this.beatPositions[this.beatPositionIndex]?.staveCount ?? this.beatPositions[this.beatPositionIndex - 1]?.staveCount ?? 1
                })
            } else {
                this.trigger ('changePosition', undefined)
            }
        } else {
            this.trigger ('changePosition', undefined)
        }

        this.animationFrame = window.requestAnimationFrame(this.calculatePosition.bind(this))
    }
}
