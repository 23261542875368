import { PropsWithChildren } from "react"
import { mergeObjects } from "./utils"
import { css } from "@emotion/css"
import { CommonAutoProps } from "./types"

import styles from './index.module.scss'

const defaults: AutoProps = {
    width: 'fill',
    height: 'hug',
    direction: 'vertical',
    verticalAlignment: 'top',
    horizontalAlignment: 'left'
}

interface AutoProps extends CommonAutoProps {
    /**
     * Width of the auto layout element
     * @default fill
     */
    width?: number | 'fill' | 'hug'
    /**
     * Height of the auto layout element
     * @default fill
     */
    height?: number | 'fill' | 'hug'
}

export function Auto (props: PropsWithChildren<AutoProps>) {
    const {
        children,
        width,
        height,
        direction,
        spacing,
        padding,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        verticalAlignment,
        horizontalAlignment,
        tooltip,
        className,
        onClick
    } = mergeObjects(defaults, props)

    const hasPadding = Boolean(padding || paddingTop || paddingRight || paddingBottom || paddingLeft)
    const paddingClasses = [
        styles['auto'],
        styles['padding'],
        direction === 'horizontal' ? styles['horizontal'] : styles['vertical'],
        styles[`valign-${verticalAlignment}`],
        styles[`halign-${horizontalAlignment}`],

        css(
            padding ? {
                padding: typeof padding === 'number' ? `${padding}px` : padding
            } : {},
            paddingTop ? {
                paddingTop: `${paddingTop}px`
            } : {},
            paddingBottom ? {
                paddingBottom: `${paddingBottom}px`
            } : {},
            paddingLeft ? {
                paddingLeft: `${paddingLeft}px`
            } : {},
            paddingRight ? {
                paddingRight: `${paddingRight}px`
            } : {},
            spacing ? {
                gap: `${spacing}px`
            } : {}
        )
    ]

    const classes = [
        styles['auto'],
        direction === 'horizontal' ? styles['horizontal'] : styles['vertical'],
        height === 'fill' || height === 'hug' ? styles[`height-${height}`] : undefined,
        width === 'fill' || width === 'hug' ? styles[`width-${width}`] : undefined,
        styles[`valign-${verticalAlignment}`],
        styles[`halign-${horizontalAlignment}`],

        css(
            width && typeof width === 'number' ? {
                width: `${width}px`
            } : {},
            height && typeof height === 'number' ? {
                height: `${height}px`
            } : {},
            spacing && !hasPadding ? {
                gap: `${spacing}px`
            } : {}
        ),
        className
    ].filter(x => x)

    return (
        <div className={classes.join(' ')} onClick={onClick} title={tooltip}>
            {hasPadding ? (
                <div className={paddingClasses.join(' ')}>
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    )
}