import axios, { AxiosInstance } from 'axios'
import { AuthServiceResponse } from '../types'

interface AuthServiceClientOptions {
    baseUrl: string
}

export class AuthServiceClient {
    private client: AxiosInstance

    constructor (private options: AuthServiceClientOptions) {
        this.client = axios.create({
            baseURL: options.baseUrl
        })
    }

    async login (idToken: string): Promise<AuthServiceResponse> {
        let response = await this.client.post('/auth/login', {
            idToken
        })

        if (response.status === 200) {
            return response.data
        }

        throw new Error (`Failed: ${response.data}`)
    }

    async refreshToken (refreshToken: string): Promise<AuthServiceResponse> {
        let response = await this.client.post('/auth/token', {
            refreshToken
        })

        if (response.status === 200) {
            return response.data
        }

        throw new Error (`Failed: ${response.data}`)
    }
}