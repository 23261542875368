import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Etude } from '../../types'

interface Exercise {
    id: string
    status?: string
    parsed?: boolean
    processed?: boolean
    previewImageReady?: boolean
    title: string
    description: string
}

interface PreviewImageExerciseProperties {
    backgroundColor?: string
}

type PreviewImage = {
    type: 'exercise'
    exerciseId: string
    properties?: PreviewImageExerciseProperties
    dataUrl?: string
} | {
    type: 'my-image'
    dataUrl?: string
}

interface EtudeForm {
    id?: string
    title: string
    description: string
    previewImageSource?: string
    previewImage?: PreviewImage
    exercises: Exercise[]
}

interface EtudeEditorState {
    form: EtudeForm
    upload?: {
        percentage: number
    }
}
  
const initialState: EtudeEditorState = {
    form: {
        title: '',
        description: '',
        exercises: []
    }
}

export default createSlice({
    name: 'etude-editor',
    initialState,
    reducers: {
        resetEtudeForm (state) {
            state.form = {
                title: '',
                description: '',
                exercises: []
            }
        },
        setEtudeId(state, action: PayloadAction<string>) {
            state.form.id = action.payload
        },
        setEtudeTitle(state, action: PayloadAction<string>) {
            state.form.title = action.payload
        },
        setEtudeDescription(state, action: PayloadAction<string>) {
            state.form.description = action.payload
        },
        setPreviewImageExerciseProperties(state, action: PayloadAction<PreviewImageExerciseProperties>) {
            if (state.form.previewImage?.type === 'exercise') {
                state.form.previewImage = {
                    ...state.form.previewImage,
                    properties: {
                        ...(state.form.previewImage.properties ?? {}),
                        ...action.payload
                    },
                    dataUrl: undefined
                }
            }
        },
        setPreviewImageDataUrl(state, action: PayloadAction<string>) {
            if (state.form.previewImage) {
                state.form.previewImage = {
                    ...state.form.previewImage,
                    dataUrl: action.payload
                }
            }
        },
        setPreviewImageSource(state, action: PayloadAction<string>) {
            state.form.previewImageSource = action.payload
        },
        setPreviewImage(state, action: PayloadAction<PreviewImage>) {
            state.form.previewImage = {
                ...action.payload
            }
        },
        addEtudeExercise(state, action: PayloadAction<{ id: string, title: string }[]>) {
            state.form.exercises.push(...action.payload.map(x => ({ ...x, description: '' })))
        },
        setUploadPercentage(state, action: PayloadAction<number>) {
            state.upload = {
                percentage: action.payload
            }
        },
        resetUpload(state) {
            state.upload = undefined
        },
        setEtude (state, action: PayloadAction<Etude>) {
            const { id, title, description, exercises } = action.payload
            state.form = {
                id,
                title,
                description,
                exercises: exercises.map(e => ({
                    ...e,
                    parsed: true,
                    processed: true,
                    previewImageReady: true
                }))
            }
        },
        setEtudeExerciseParsingStatus(state, action: PayloadAction<{ id: string, success: boolean }>) {
            const { id, success } = action.payload
            const exercise = state.form.exercises.find(x => x.id === id)
            if (exercise) {
                if (!exercise.processed) {
                    exercise.parsed = true
                    exercise.status = success ? 'processing' : 'parsing-error'
                }
            }
        },
        setEtudeExerciseProcessingStatus(state, action: PayloadAction<{ id: string, status: string }>) {
            const { id, status } = action.payload
            const exercise = state.form.exercises.find(x => x.id === id)
            if (exercise) {
                exercise.parsed = true
                exercise.processed = true
                exercise.status = status
            }
        },
        setEtudeExercisePreviewImageStatus(state, action: PayloadAction<{ id: string }>) {
            const { id } = action.payload
            const exercise = state.form.exercises.find(x => x.id === id)
            if (exercise) {
                exercise.parsed = true
                exercise.processed = true
                exercise.previewImageReady = true
            }
        },
        setEtudeExerciseDetails(state, action: PayloadAction<{ id: string, title: string, description: string }>) {
            const { id, title, description } = action.payload
            const exercise = state.form.exercises.find(x => x.id === id)
            if (exercise) {
                exercise.title = title
                exercise.description = description
            }
        },
        removeEtudeExercise(state, action: PayloadAction<string>) {
            const index = state.form.exercises.findIndex(x => x.id === action.payload)
            if (index >= 0) {
                state.form.exercises.splice(index, 1)
            }
        }
    }
})
