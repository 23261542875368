import { AuthServiceClient } from "./auth-service-client";
import { CognitoClient } from "./cognito-client";
import { AuthResult, AuthServiceResponse, AuthStatus, ConfirmationDetails, Identity, SigninDetails, SignupDetails } from "../types";

interface AuthDataRepository {
    get (): [AuthStatus, Identity]
    set ( authStatus: AuthStatus, response: AuthServiceResponse): void
    clear (): void
}

class LocalStorageAuthDataRepository implements AuthDataRepository {
    get(): [AuthStatus, Identity] {
        const authStatus = localStorage.getItem('mom-auth-status') as AuthStatus
        const storedIdentity = localStorage.getItem('mom-auth-identity')
        const identity = storedIdentity ? JSON.parse(atob(storedIdentity)) : null

        return [authStatus, identity]
    }
    set(authStatus: AuthStatus, response: AuthServiceResponse): void {
        localStorage.setItem('mom-auth-status', authStatus)
        localStorage.setItem('mom-auth-identity', btoa(JSON.stringify(response.identity)))
        localStorage.setItem('mom-auth-access-token', response.accessToken)
        localStorage.setItem('mom-auth-refresh-token', response.refreshToken)
    }
    clear(): void {
        localStorage.removeItem('mom-auth-identity')
        localStorage.removeItem('mom-auth-status')
        localStorage.removeItem('mom-auth-access-token')
        localStorage.removeItem('mom-auth-refresh-token')
    }
}

interface AuthClientOptions {
    cognito: {
        userPoolId: string
        clientId: string
    }
    authServiceBaseUrl: string
}

export class AuthClient {
    private cognitoClient: CognitoClient
    private authServiceClient: AuthServiceClient
    private authDataRepository: AuthDataRepository

    constructor (options: AuthClientOptions) {
        const { cognito, authServiceBaseUrl } = options
        this.authDataRepository = new LocalStorageAuthDataRepository()
        this.cognitoClient = new CognitoClient({
            ...cognito
        })
        this.authServiceClient = new AuthServiceClient({
            baseUrl: authServiceBaseUrl
        })
    }

    get authResult (): AuthResult {
        const [authStatus, identity] = this.authDataRepository.get()

        return {
            authStatus,
            identity
        }
    }
    
    async signup (details: SignupDetails): Promise<AuthResult> {
        const authResult = await this.cognitoClient.signup(details)
        if (authResult.authStatus === 'complete') {
            const response = await this.authServiceClient.login(authResult.idToken)
            await this.authDataRepository.set(authResult.authStatus, response)
            return {
                authStatus: 'complete',
                identity: response.identity
            }
        } else {
            return authResult
        }
    }

    async signin (details: SigninDetails): Promise<AuthResult> {
        const authResult = await this.cognitoClient.signin(details)
        if (authResult.authStatus === 'complete') {
            const response = await this.authServiceClient.login(authResult.idToken)
            await this.authDataRepository.set(authResult.authStatus, response)
            return {
                authStatus: 'complete',
                identity: response.identity
            }
        } else {
            return authResult
        }
    }

    async confirmCode (details: ConfirmationDetails): Promise<AuthResult> { 
        const authResult = await this.cognitoClient.confirmCode(details)
        if (authResult.authStatus === 'complete') {
            const response = await this.authServiceClient.login(authResult.idToken)
            await this.authDataRepository.set(authResult.authStatus, response)
            return {
                authStatus: 'complete',
                identity: response.identity
            }
        } else {
            return authResult
        }
    }

    async resendCode () {
        await this.cognitoClient.resendCode()
    }

    async signout () {
        await this.cognitoClient.signout()
        await this.authDataRepository.clear()
    }
}
