import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { useState } from "react";
import { Auto } from "../../component/auto-layout";
import { useTheme } from "../../context/theme";
import { Button } from "../../hoc/button";
import { Panel } from "../../hoc/panel";
import { useAppSelector } from "../../store";
import { ThumbnailEditor } from "./thumbnail-editor";

const PreviewImage = styled.img({
    width: '100%',
    aspectRatio: '16 / 9',
    borderRadius: '12px',
    overflow: 'hidden',

    '@supports not (aspect-ratio: 16 / 9)': {
        '&::before': {
            float: 'left',
            paddingTop: '56.25%',
            content: '""'
        },

        '&::after': {
            display: 'block',
            content: '""',
            clear: 'both'
        }
    }
})

const DummyPreviewImage = styled.div({
    width: '100%',
    aspectRatio: '16 / 9',
    borderRadius: '12px',
    overflow: 'hidden',

    '@supports not (aspect-ratio: 16 / 9)': {
        '&::before': {
            float: 'left',
            paddingTop: '56.25%',
            content: '""'
        },

        '&::after': {
            display: 'block',
            content: '""',
            clear: 'both'
        }
    }
})

export function Thumbnail () {
    const { colors } = useTheme()
    const [openEditor, setOpenEditor] = useState (false)
    const previewImage = useAppSelector(x => x.etudeEditor.form.previewImage)
    const previewImageSource = useAppSelector(x => x.etudeEditor.form.previewImageSource)

    return (
        <>
            <Panel labelKey="thumbnail" height='fill'>
                <Auto spacing={16}>
                    <Auto>
                        {previewImage?.dataUrl || previewImageSource ? (
                            <PreviewImage src={previewImage?.dataUrl || previewImageSource} />
                        ) : (
                            <DummyPreviewImage className={css({
                                backgroundColor: colors.primaryColor.shade2
                            })} />
                        )}
                    </Auto>
                    <Auto horizontalAlignment="center">
                        <Button textKey="edit-thumbnail" onClick={() => setOpenEditor(true)} />
                    </Auto>
                </Auto>
            </Panel>
            {openEditor && (
                <ThumbnailEditor onClose={() => setOpenEditor(false)} />
            )}
        </>
    )
}