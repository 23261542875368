import { useEffect, useState } from "react"
import { SheetMusicNoteEvaluationResult } from "../../types"
import { usePlayer } from "./player-repository"

export const useNoteEvaluationResult = (id: string) => {
    const { feedback } = usePlayer(id)
    const [noteEvaluationResult, setNoteEvaluationResult] = useState <SheetMusicNoteEvaluationResult[]> (feedback.evaluationResults)

    useEffect(() => {
        feedback.on('change', setNoteEvaluationResult)
        
        return () => {
            feedback.off('change', setNoteEvaluationResult)
        }
    })

    return noteEvaluationResult
}
