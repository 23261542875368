import { Global } from "@emotion/react";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { primaryColors, secondaryColors } from "./colors";
import { Colors, SecondaryColorType, SelectableTheme, Theme } from "./types";
import { extractContextColorVariables, extractCSSVariables, getSystemDefaultTheme, getVariableName } from "./utils";

export * from './colors'
export * from './types'

const THEME_STORAGE_KEY = 'MOM_THEME'
const THEME_SECONDARY_COLOR_STORAGE_KEY = 'MOM_THEME_SECONDARY_COLOR'

interface ThemeContext {
    colors: Colors
    selectTheme (theme: SelectableTheme): void
    selectSecondaryColor (secondaryColor: SecondaryColorType): void
}

type FunctionArgument <T extends Function> = T extends (args: infer TArg) => void ? TArg : unknown

type DefaultThemeContextEvents = {
    'change': (event: { theme: SelectableTheme, secondaryColor: SecondaryColorType }) => void
}

class DefaultThemeContext implements ThemeContext {
    private _handlers: { 'change': (DefaultThemeContextEvents['change'])[] }
    private _selectedTheme: SelectableTheme
    private _selectedSecondaryColor: SecondaryColorType

    constructor (public colors: Colors) {
        this._handlers = {
            change: []
        }
        this.selectTheme(this.selectedTheme)
    }

    selectSecondaryColor(secondaryColor: SecondaryColorType): void {
        this.selectedSecondaryColor = secondaryColor
        this.reapplySelectedTheme()
    }

    selectTheme(theme: Theme | "system-default"): void {
        this.selectedTheme = theme
        this.reapplySelectedTheme()
    }

    applyNewSystemDefault () {
        if (this._selectedTheme === 'system-default') {
            this.reapplySelectedTheme()
        }
    }

    reapplySelectedTheme () {
        document.getElementsByTagName('html')[0].setAttribute('theme', this.selectedTheme === 'system-default' ? getSystemDefaultTheme() : this.selectedTheme)
        document.getElementsByTagName('html')[0].setAttribute('theme-secondary-color', this.selectedSecondaryColor)
    }

    get selectedTheme(): SelectableTheme {
        if (!this._selectedTheme) {
            this._selectedTheme = (localStorage && localStorage.getItem(THEME_STORAGE_KEY) as SelectableTheme) || 'system-default'
        }
        return this._selectedTheme
    }

    set selectedTheme(theme: SelectableTheme) {
        if (localStorage) {
            localStorage.setItem(THEME_STORAGE_KEY, theme)
        }
        this._selectedTheme = theme
        this.trigger ('change', { theme: this._selectedTheme, secondaryColor: this._selectedSecondaryColor })
    }

    get selectedSecondaryColor(): SecondaryColorType {
        if (!this._selectedSecondaryColor) {
            this._selectedSecondaryColor = (localStorage && localStorage.getItem(THEME_SECONDARY_COLOR_STORAGE_KEY) as SecondaryColorType) || 'green'
        }
        return this._selectedSecondaryColor
    }

    set selectedSecondaryColor(theme: SecondaryColorType) {
        if (localStorage) {
            localStorage.setItem(THEME_SECONDARY_COLOR_STORAGE_KEY, theme)
        }
        this._selectedSecondaryColor = theme
        this.trigger ('change', { theme: this._selectedTheme, secondaryColor: this._selectedSecondaryColor })
    }

    on <Event extends keyof DefaultThemeContextEvents> (event: Event, cb: DefaultThemeContextEvents[Event]): void {
        this._handlers[event].push (cb)
    }

    off <Event extends keyof DefaultThemeContextEvents> (event: Event, cb: DefaultThemeContextEvents[Event]): void {
        const index = this._handlers[event].findIndex(x => x === cb)
        if (index >= 0) {
            this._handlers[event].splice(index, 1)
        }
    }

    trigger <Event extends keyof DefaultThemeContextEvents> (event: Event, args: FunctionArgument<DefaultThemeContextEvents[Event]>) {
        this._handlers[event].forEach(handler => handler(args))
    }
}

const themeContext = createContext <ThemeContext> ({
    colors: undefined as any,
    selectTheme: () => undefined,
    selectSecondaryColor: () => undefined
})

export const useTheme = () => useContext (themeContext)

export const useSelectedTheme = () => {
    const theme = useTheme () as DefaultThemeContext
    const [selectedTheme, setSelectedTheme] = useState({
        theme: theme.selectedTheme,
        secondaryColor: theme.selectedSecondaryColor
    })

    useEffect (() => {
        theme.on ('change', setSelectedTheme)
        return () => {
            theme.off ('change', setSelectedTheme)
        }
    })

    return selectedTheme
}

export function ThemeProvider (props: PropsWithChildren<{}>) {
    const { children } = props
    const contextValue  = useMemo(() => new DefaultThemeContext(extractContextColorVariables()), [])

    useEffect (() => {
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', contextValue.applyNewSystemDefault.bind(contextValue))
        return () => {
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', contextValue.applyNewSystemDefault.bind(contextValue))
        }
    })

    return (
        <themeContext.Provider value={contextValue}>
            <Global styles={{
                ...({
                    body: {
                        backgroundColor: `var(${getVariableName('primaryColor', 'shade1')})`,
                        color: `var(${getVariableName('primaryColor', 'shade6')})`
                    },
                    'input, button': {
                        border: `1px solid var(${getVariableName('primaryColor', 'shade2')})`,
                        borderRadius: '4px',
                        backgroundColor: 'transparent',
                        color: `var(${getVariableName('primaryColor', 'shade6')})`
                    },
                    svg: {
                        stroke: `var(${getVariableName('primaryColor', 'shade6')})`,
                        fill: `var(${getVariableName('primaryColor', 'shade6')})`
                    },
                    '.no-theme': {
                        'svg': {
                            stroke: 'inherit',
                            fill: 'inherit'
                        }
                    }
                }),
                ...Object.entries(primaryColors).reduce((prev, [theme, color]) => {
                    return {
                        ...prev,
                        [`html[theme=${theme}]`]: extractCSSVariables('primaryColor', color)
                    }
                }, {}),
                ...Object.entries(secondaryColors).reduce((prev, [colorType, color]) => {
                    return {
                        ...prev,
                        [`html[theme-secondary-color=${colorType}]`]: extractCSSVariables('secondaryColor', color)
                    }
                }, {})
            }}/>
            {children}
        </themeContext.Provider>
    )
}