import { useState } from "react";
import { Auto } from "../../component/auto-layout";
import { Button } from "../../hoc/button";
import { LocalisedText } from "../../hoc/text";
import { Textbox } from "../../hoc/textbox";
import { SignupDetails } from "../types";

interface SignupProps {
    onSignup: (details: SignupDetails) => void
    onNavigate: (authState: 'signin' | 'signup') => void
}

export default function Signup (props: SignupProps) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const signup = () => {
        props.onSignup({ name, email, password })
    }

    const navigateToSignin = async () => {
        props.onNavigate('signin')
    }


    return (
        <Auto direction='vertical' spacing={12}>
            <Auto>
                <Textbox labelKey='name' value={name} onChange={setName}></Textbox>
            </Auto>
            <Auto>
                <Textbox labelKey='email' type='email' value={email} onChange={setEmail}></Textbox>
            </Auto>
            <Auto paddingBottom={12}>
                <Textbox labelKey='password' type='password' value={password} onChange={setPassword}></Textbox>
            </Auto>
            <Auto direction='horizontal' horizontalAlignment='center' paddingBottom={12}>
                <Button textKey='signup' variant="inverted" fullWidth onClick={signup} />
            </Auto>
            <Auto paddingBottom={12} horizontalAlignment='center'>
                <LocalisedText textKey='navigate-to-signin-message' variant="link" onClick={navigateToSignin} />
            </Auto>
        </Auto>
    )
}