import { actions, AppDispatch, AppState } from ".."
import { Services } from "../../types"

export default {
    loadExerciseList: () => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { practiceAndHistoryClient } = services

        if (!state.exercise.subscribedExerciseDetails.length) {
            const exerciseList = await practiceAndHistoryClient.getSubscribedExercises()
            dispatch (actions.exercise.setSubscribedExerciseDetails(exerciseList))
        }
    },
    loadExerciseDetails: (etudeId: string, exerciseId: string) => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { musicDataClient, practiceAndHistoryClient } = services

        if (!state.exercise.exerciseDetails[exerciseId]) {
            const [timeline, exerciseDetails] = await Promise.all([
                musicDataClient.getMusicTimeline(exerciseId),
                practiceAndHistoryClient.getExercise(etudeId, exerciseId)
            ])

            dispatch(actions.exercise.setExerciseDetails({
                id: exerciseId,
                title: exerciseDetails.title,
                etudeId: exerciseDetails.etudeId,
                etudeTitle: exerciseDetails.etudeTitle,
                timeline: timeline.parts?.[0]
            }))
        }
    }
}