import { Auto, AutoHug } from "../component/auto-layout"
import { LocalisationInterpolationParam, useLocalisationContext } from "../context/localisation"
import { Button } from "./button"
import { Modal } from "./modal"


interface ConfirmationProps {
    open: boolean
    messageKey: string
    messageParam?: LocalisationInterpolationParam
    onConfirm: () => void
    onCancel?: () => void
}

export default function Confirmation (props: ConfirmationProps) {
    const {
        messageKey,
        messageParam,
        onConfirm,
        onCancel
    } = props
    const localisation = useLocalisationContext()
    const message = localisation.interpolate (messageKey, messageParam)

    return (
        <Modal open={props.open} onClose={onCancel}>
            <AutoHug spacing={16} padding={32}>
                <AutoHug>
                    {message}
                </AutoHug>
                <Auto direction="horizontal" horizontalAlignment="center" spacing={16}>
                    <Button textKey="confirm" variant="inverted" onClick={onConfirm}></Button>
                    <Button textKey="cancel" variant="inverted" onClick={onCancel}></Button>
                </Auto>
            </AutoHug>
        </Modal>
    )
}