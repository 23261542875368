import { OrientationEnforcer } from "../../hoc/orientation-enforcer"
import { usePlayerLoader } from "../../context/player"
import { ControlPanel } from "./control-panel"
import { EvaluatedNotes } from "./evaluated-notes"
import { NoteTracker } from "./note-tracker"
import { SheetMusic } from "./sheet-music"
import { ExerciseList } from "./exercise-list"
import { Header } from "./header"
import { useState } from "react"
import { Result } from "./result"
import { useAuth } from "../../auth"

interface PlayerProps {
    etudeId: string
    exerciseId: string
}

export function Player (props: PlayerProps) {
    const {
        etudeId,
        exerciseId
    } = props
    const player = usePlayerLoader (etudeId, exerciseId)

    return (
        <OrientationEnforcer orientation="landscape" phone>
            {player && (
                <InternalPlayer exerciseId={exerciseId} />
            )}
        </OrientationEnforcer>
    )
}

interface InternalPlayerProps {
    exerciseId: string
}

function InternalPlayer (props: InternalPlayerProps) {
    const {
        exerciseId
    } = props
    const auth = useAuth ()
    const [openExercises, setOpenExercises] = useState (false)

    const toggleExerciseList = () => {
        setOpenExercises (!openExercises)
    }

    const openExerciseList = () => {
        setOpenExercises (true)
    }

    const closeExerciseList = () => {
        setOpenExercises (false)
    }

    return (
        <>
            <Header exerciseId={exerciseId} onOpenExerciseList={toggleExerciseList} hideExerciseList={!auth.isAuthenticated} />
            <SheetMusic exerciseId={exerciseId}>
                <NoteTracker exerciseId={exerciseId} />
                <EvaluatedNotes exerciseId={exerciseId} />
            </SheetMusic>
            <ControlPanel exerciseId={exerciseId} onOpenExerciseList={openExerciseList} hideExerciseList={!auth.isAuthenticated} />
            {auth.isAuthenticated && (
                <ExerciseList open={openExercises} closeExerciseList={closeExerciseList} />
            )}
            <Result exerciseId={exerciseId} />
        </>
    )
}