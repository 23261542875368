import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { useEffect } from "react"
import { useNavigate } from "react-router"
import { useAuth } from "../../auth"
import { AutoFill, AutoHug } from "../../component/auto-layout"
import { Col, Row } from "../../component/grid-layout"
import { constants } from "../../constants"
import { useServices } from "../../context/services"
import { useTheme } from "../../context/theme"
import { Button } from "../../hoc/button"
import { LocalisedText } from "../../hoc/text"
import { Textarea } from "../../hoc/textarea"
import { Textbox } from "../../hoc/textbox"
import { actions, asyncActions, useAppDispatch, useAppSelector } from "../../store"
import { mediaQuery } from "../../util/device-detection"
import { Exercise } from "./exercise"
import { Thumbnail } from "./thumbnail"
import { Upload } from "./upload"

const HeaderReference = styled.div({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: `${constants.ui.width.pageMaxWidth}px`,
    height: 0,
    margin: '0 auto',
    zIndex: 2
})

const Header = styled.div({
    height: `${constants.ui.height.header}px`,
    marginTop: `${constants.ui.height.header}px`,
    padding: '16px',

    [mediaQuery.device('lg')]: {
        marginLeft: `${constants.ui.width.leftMenu}px`
    }
})

const Content = styled.div({
    marginTop: `${constants.ui.height.header + 32}px`
})

interface EtudeFormProps {
    id?: string
}

export function EtudeForm (props: EtudeFormProps) {
    const auth = useAuth ()
    const navigate = useNavigate ()
    const services = useServices ()
    const { colors } = useTheme()
    const etudeForm = useAppSelector(x => x.etudeEditor.form)
    const dispatch = useAppDispatch()
    
    const isUpdateOperation = !!etudeForm.id

    const changeTitle = (title: string) => {
        dispatch(actions.etudeEditor.setEtudeTitle(title))
    }

    const changeDescription = (description: string) => {
        dispatch(actions.etudeEditor.setEtudeDescription(description))
    }

    const musicDataParsed = (data: { id: string, success: boolean }) => {
        dispatch(actions.etudeEditor.setEtudeExerciseParsingStatus(data))
    }

    const musicDataProcessed = (data: { id: string, status: string }) => {
        dispatch(actions.etudeEditor.setEtudeExerciseProcessingStatus(data))
    }

    const previewImageCreated = (data: { id: string } ) => {
        dispatch(actions.etudeEditor.setEtudeExercisePreviewImageStatus(data))
    }

    const createOrUpdate = async () => {
        dispatch(asyncActions.etudeEditor.submitEtude())
    }

    useEffect(() => {
        if (!auth.isAuthenticated) {
            navigate('/')
        } else {
            services.webSocketClient.subscribe('MusicDataParsed', musicDataParsed)
            services.webSocketClient.subscribe('MusicDataProcessed', musicDataProcessed)
            services.webSocketClient.subscribe('PreviewImageCreated', previewImageCreated)
        }
        
        return () => {
            services.webSocketClient.unsubscribe('MusicDataCreated', musicDataParsed)
            services.webSocketClient.unsubscribe('MusicDataProcessed', musicDataProcessed)
            services.webSocketClient.unsubscribe('PreviewImageCreated', previewImageCreated)
        }
    })

    useEffect(() => {
        dispatch(actions.etudeEditor.resetEtudeForm())
        if (auth.isAuthenticated && props.id) {
            dispatch(asyncActions.etudeEditor.getEtude(props.id))
        }
    }, [props.id])

    return (
        <>
            <HeaderReference>
                <Header className={css({
                    backgroundColor: colors.primaryColor.shade1
                })}>
                    <AutoFill direction="horizontal">
                        <AutoFill>
                            <LocalisedText variant="h1" textKey="new-etude" />
                        </AutoFill>
                        <AutoHug>
                            <Button variant="inverted" textKey={isUpdateOperation ? 'update' : 'create'} onClick={createOrUpdate} />
                        </AutoHug>
                    </AutoFill>
                </Header>
            </HeaderReference>
            <Content>
                <Row className={css({
                    flexDirection: 'column-reverse',

                    [mediaQuery.device('md')]: {
                        flexDirection: 'unset'
                    }
                })}>
                    <Col all={12} md={6}>
                        <Row>
                            <Col>
                                <Textbox labelKey="title" value={etudeForm.title} onChange={changeTitle} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Textarea labelKey="description" text={etudeForm.description} onChange={changeDescription} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Upload />
                            </Col>
                        </Row>
                    </Col>
                    <Col all={12} md={6} className={css({
                        marginBottom: '16px',

                        [mediaQuery.device('md')]: {
                            marginBottom: 0
                        }
                    })}>
                        <Thumbnail />
                    </Col>
                </Row>
                {Boolean(etudeForm.exercises && etudeForm.exercises.length) && (
                    <>
                        <Row>
                            <Col>
                                <LocalisedText variant="h2" textKey="exercises" className={css({
                                    marginBottom: '8px'
                                })} />
                            </Col>
                        </Row>
                        {etudeForm.exercises.map(exercise => (
                            <Row key={`exercise-${exercise.id}`}>
                                <Col>
                                    <Exercise id={exercise.id} title={exercise.title} description={exercise.description} />
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
            </Content>
        </>
    )
}