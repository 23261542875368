import { EventEmitter } from "./event-emitter"

type PlayerSettingsEvents = {
    changeTempo: (tempo?: number) => void
    changeRepetition: (repetition?: number) => void
    changeNoteTracker: (noteTracker: boolean) => void
    changeFeedback: (feedback: boolean) => void
}

interface PlayerSettingsOptions {
    tempo: number
    repetition: number
    noteTracker: boolean
    feedback: boolean
}

export class PlayerSettings extends EventEmitter<PlayerSettingsEvents> {
    public tempo?: number
    public repetition?: number
    public noteTracker: boolean
    public feedback: boolean

    constructor (options: PlayerSettingsOptions) {
        super ()
        this.tempo = options.tempo
        this.repetition = options.repetition
        this.noteTracker = options.noteTracker
        this.feedback = options.feedback
    }

    setTempo (tempo?: number) {
        this.tempo = tempo
        this.trigger ('changeTempo', tempo)
    }

    setRepetition (repetition?: number) {
        this.repetition = repetition
        this.trigger ('changeRepetition', repetition)
    }

    setNoteTracker (noteTracker: boolean) {
        this.noteTracker = noteTracker
        this.trigger('changeNoteTracker', noteTracker)
    }

    setFeedback (feedback: boolean) {
        this.feedback = feedback
        this.trigger('changeFeedback', feedback)
    }
}
