import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { PropsWithChildren } from "react"

const Container = styled.div({
    borderRadius: '4px',
    padding: '16px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: '48px',
    display: 'flex',
    flexWrap: 'wrap'
})

const Label = styled.label({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    minHeight: '22px',
    padding: '0 4px',
    transform: 'translate(0, -50%) scale(0.9)',
    marginTop: '-16px'
})

const Content = styled.div({
    display: 'block',
    boxSizing: 'border-box',
    width: '100%'
})

export interface PanelStyle {
    backgroundColor?: string
    borderColor?: string
    labelColor?: string
}

interface PanelProps {
    label?: string
    height?: number | 'fill'
    style?: PanelStyle
    className?: string
}

export function Panel (props: PropsWithChildren<PanelProps>) {
    const {
        label,
        height,
        className,
        style,
        children
    } = props

    const classes = [
        className,
        css({
            height: height === 'fill' ? '100%' : (height && typeof height === 'number') ? `${height}px` : undefined,
            border: `1px solid ${style?.borderColor}`
        })
    ].filter(x => x)
    
    return (
        <Container className={classes.join(' ')}>
            {label && (
                <Label className={css({
                    color: style?.labelColor,
                    backgroundColor: style?.backgroundColor
                })}>
                    {label}
                </Label>
            )}
            <Content>
                {children}
            </Content>
        </Container>
    )
}