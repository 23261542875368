import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"

const Container = styled.div({
    borderRadius: '4px',
    padding: '8px 16px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap'
})

const Label = styled.label({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    height: '1.2rem',
    padding: '0 4px',
    marginLeft: '-4px',
    transition: 'margin 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1)'
})

const Input = styled.input({
    border: 0,
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    background: 'transparent',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    outline: 'none'
})

export interface TextboxStyle {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    labelColor?: string
}

interface TextboxProps {
    label: string
    value?: string
    style?: TextboxStyle
    className?: string
    focused?: boolean
    type?: React.HTMLInputTypeAttribute
    onChange?: (value: string) => void
    onKeyDown?: (e: React.KeyboardEvent) => void
    onBlur?: (e: React.FocusEvent) => void
    onFocus?: (e: React.FocusEvent) => void
}

export function Textbox (props: TextboxProps) {
    const {
        label,
        value,
        style,
        focused,
        className,
        type,
        onChange,
        onKeyDown,
        onBlur,
        onFocus
    } = props
    const inputRef = useRef <HTMLInputElement> (null)
    const [isFocused, setIsFocused] = useState (focused)
    const classes = [
        className,
        css({
            border: `1px solid ${style?.borderColor}`
        })
    ].filter(x => x)

    const labelToTop = Boolean(value) || isFocused

    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.currentTarget.value)
    }

    const blur = (e: React.FocusEvent) => {
        setIsFocused(false)
        onBlur && onBlur(e)
    }

    const focus = (e: React.FocusEvent) => {
        setIsFocused(true)
        onFocus && onFocus(e)
    }

    useEffect (() => {
        if (isFocused) {
            inputRef.current?.focus()
        }
    })
    
    return (
        <Container className={classes.join(' ')}>
            <Label
                onClick={() => inputRef.current?.focus()}
                className={css({
                    transform: labelToTop ? 'translate(0, -60%) scale(0.9)' : undefined,
                    marginTop: labelToTop ? '-8px' : undefined,
                    color: style?.labelColor,
                    backgroundColor: style?.backgroundColor
                })}
            >
                {label}
            </Label>
            <Input
                ref={inputRef}
                type={type ?? 'text'}
                value={value ?? ''}
                onChange={change}
                onKeyDown={onKeyDown}
                onFocus={focus}
                onBlur={blur}
            />
        </Container>
    )
}