import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { AutoHug } from "../../component/auto-layout"
import { Col, Row } from "../../component/grid-layout"
import { Icon } from "../../hoc/icon"
import { useTheme } from "../../context/theme"
import { Numeric } from "../../hoc/numeric"
import { LocalisedText } from "../../hoc/text"
import { ToggleButton } from "../../hoc/toggle-button"
import { mediaQuery } from "../../util/device-detection"
import { useFeedback, useNoteTracker, usePlayerSettingsControl, useRepetition, useTempo } from "../../context/player"

function checkIfParent (child: Element, parent: Element) {
    let node = child
    for (; node.parentElement && node !== parent; node = node.parentElement) {}
    return node === parent
}

const Container = styled.div({
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '100%',
    right: 0,
    marginBottom: '16px',
    padding: '16px 32px',
    paddingBottom: '32px',
    borderRadius: '12px',
    zIndex: 3,

    [mediaQuery.phone()]: {
        minWidth: 'auto',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0
    }
})

const Header = styled.div({
    display: 'none',

    [mediaQuery.phone()]: {
        display: 'flex',
        flex: '0 1 0',
        padding: '16px 32px'
    }
})

const Content = styled.div({
    [mediaQuery.phone()]: {
        flex: '1 0 0',
        padding: '16px 32px 0',
        overflow: 'auto'
    }
})

const HiddenSettings = styled.div({
    height: 0,
    overflow: 'hidden',

    [mediaQuery.phone()]: {
        height: 'auto'
    }
})

interface PlayerSettingsMenuProps extends PlayerSettingsProps {
    exerciseId: string
    onClose?: () => void
}

function PlayerSettingsMenu (props: PlayerSettingsMenuProps) {
    const {
        exerciseId,
        onClose
    } = props
    const { colors } = useTheme()
    const playerSettingsControl = usePlayerSettingsControl(exerciseId)
    const tempo = useTempo (exerciseId)
    const repetition = useRepetition (exerciseId)
    const noteTracker = useNoteTracker (exerciseId)
    const feedback = useFeedback (exerciseId)

    const changeTempo = (tempo?: number) => {
        playerSettingsControl.setTempo (tempo)
    }

    const changeRepetition = (repetition?: number) => {
        playerSettingsControl.setRepetition (repetition)
    }

    const changeNoteTracker = (noteTracker: boolean) => {
        playerSettingsControl.setNoteTracker (noteTracker)
    }

    const changeFeedback = (feedback: boolean) => {
        playerSettingsControl.setFeedback (feedback)
    }

    const preventMouseEvent = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }    

    return (
        <Container
            className={css({
                backgroundColor: colors.primaryColor.shade1,
                border: `1px solid ${colors.primaryColor.shade2}`,
                boxShadow: `0px 0px 20px ${colors.primaryColor.shade2}`
            })}
            onClick={preventMouseEvent}
        >
            <Header className={css({
                borderBottom: `1px solid ${colors.primaryColor.shade2}`
            })}>
                <LocalisedText fontSize={1.5} textKey="player-settings" className={css({
                    flex: '1 0 0'
                })} />
                <Icon icon="close" onClick={onClose} className={css({
                    flex: '0 1 24px'
                })}></Icon>
            </Header>
            <Content>
                <HiddenSettings>
                    <Row>
                        <Col all={6} sm={6}>
                            <LocalisedText textKey="repetition" />
                        </Col>
                        <Col all={6} sm={6}>
                            <Numeric value={repetition} onChange={changeRepetition} />
                        </Col>
                    </Row>
                    <Row>
                        <Col all={6} sm={6}>
                            <LocalisedText textKey="tempo" />
                        </Col>
                        <Col all={6} sm={6}>
                            <Numeric value={tempo} onChange={changeTempo} />
                        </Col>
                    </Row>
                </HiddenSettings>
                <Row className={css({
                    height: 'calc(18px + 1.2rem)'
                })}>
                    <Col all={6} sm={6}>
                        <LocalisedText textKey="note-tracker" />
                    </Col>
                    <Col all={6} sm={6}>
                        <ToggleButton value={noteTracker} onChange={changeNoteTracker} />
                    </Col>
                </Row>
                <Row className={css({
                    height: 'calc(18px + 1.2rem)'
                })}>
                    <Col all={6} sm={6}>
                        <LocalisedText textKey="feedback" />
                    </Col>
                    <Col all={6} sm={6}>
                        <ToggleButton value={feedback} onChange={changeFeedback} />
                    </Col>
                </Row>
            </Content>
        </Container>
    )
}

interface PlayerSettingsProps {
    exerciseId: string
}

export function PlayerSettings (props: PlayerSettingsProps) {
    const {
        exerciseId: id
    } = props
    const [playerSettings, setPlayerSettings] = useState(false)
    const iconRef = useRef <SVGSVGElement> (null)
    const closePlayerSettings = (e?: MouseEvent) => {
        if (!e?.target || !iconRef.current || !checkIfParent(e.target as Element, iconRef.current)) {
            setPlayerSettings(false)
        }
    }

    const togglePlayerSettings = (e: React.MouseEvent) => {
        setPlayerSettings(!playerSettings)
    }

    useEffect(() => {
        document.addEventListener('click', closePlayerSettings)
        return () => {
            document.removeEventListener('click', closePlayerSettings)
        }
    })

    return (
        <AutoHug className={css({
            position: 'relative'
        })}>
            <Icon ref={iconRef} icon="gear" onClick={togglePlayerSettings} />
            {playerSettings && (
                <PlayerSettingsMenu exerciseId={id} onClose={closePlayerSettings} />
            )}
        </AutoHug>
    )
}