export function mergeObjects <T1 extends { [key: string]: any }, T2 extends { [key: string]: any }> (obj1: T1, obj2: T2): (T1 & T2) {
    const result: any = {}

    for (const key1 in obj1) {
        if (typeof obj1[key1] === 'object' && typeof obj2[key1] === 'object') {
            result[key1] = mergeObjects(obj1[key1], obj2[key1])
        } else if (obj2[key1] !== undefined && obj2[key1] !== null) {
            result[key1] = obj2[key1]
        } else {
            result[key1] = obj1[key1]
        }
    }

    for (const key2 in obj2) {
        if (obj1[key2] === undefined) {
            result[key2] = obj2[key2]
        }
    }

    return result
}