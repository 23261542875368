import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { Icon } from "../../../component/icon"
import { useLocalisationContext } from "../../../context/localisation"
import { SelectableTheme, primaryColors, useTheme, useSelectedTheme } from "../../../context/theme"

interface ThemeItemProps {
    theme: SelectableTheme
}

export function ThemeItem (props: ThemeItemProps) {
    const {
        theme
    } = props
    const themeCxt = useTheme()
    const selectedTheme = useSelectedTheme()
    const { localisationStrings } = useLocalisationContext()
    const [systemDefault, setSystemDefault] = useState <'dark' | 'light'> (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light')
    const primaryColor = primaryColors[theme === 'system-default' ? systemDefault : theme]

    const changeSystemDefault = () => {
        setSystemDefault (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
    }

    useEffect (() => {
        if (theme === 'system-default') {
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', changeSystemDefault)
            return () => {
                window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', changeSystemDefault)
            }
        }
    })

    const onSelect = () => {
        themeCxt.selectTheme(theme)
    }

    const Container = styled.div ({
        width: '100%',
        padding: '16px 32px',
        fontSize: '1rem',
        lineHeight: '1.5rem',
        borderRadius: '4px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: 'pointer',
        background: primaryColor.shade1,
        border: `1px solid ${primaryColor.shade2}`,
        color: primaryColor.shade6
    })

    return (
        <Container onClick={onSelect}>
            {theme === selectedTheme.theme ? (
                <Icon icon="check" size={1.5} style={{ stroke: themeCxt.colors.secondaryColor.shade1 }} />
            ) : (
                <></>
            )}
            {localisationStrings[theme]}
        </Container>
    )
}