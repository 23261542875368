import { useNavigate } from "react-router";
import { ProfileMenu as GenericProfileMenu, ProfileMenuItemDetails, ProfileMenuStyle } from "../component/profile-menu";
import { useTheme } from "../context/theme";
import { useLocalisationContext } from "../context/localisation";
import { useAuth } from "../auth";
import { useEffect } from "react";

interface ProfileMenuProps {
    className?: string
    onClose?: () => void
}

export function ProfileMenu (props: ProfileMenuProps) {
    const {
        className,
        onClose
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()
    const auth = useAuth()
    const navigate = useNavigate()

    const items = [{
        id: 'settings',
        icon: 'gear' as const,
        text: localisationStrings['settings']
    }, {
        id: 'signout',
        icon: 'signout' as const,
        text: localisationStrings['signout']
    }]

    const actions: { [key: string]: Function } = {
        'settings': () => {
            navigate('/settings')
        },
        'signout': () => {
            auth.signout()
        }
    }

    const style: ProfileMenuStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        profileColor: theme.colors.secondaryColor.shade1,
        textColor: theme.colors.primaryColor.shade6
    }

    const onClick = (item: ProfileMenuItemDetails) => {
        const action = actions[item.id]
        if (action) {
            action()
            close()
        }
    }

    const close = () => {
        onClose && onClose()
    }

    useEffect(() => {
        document.addEventListener('click', close)

        return () => {
            document.removeEventListener('click', close)
        }
    })

    return (
        <>
            {auth.identity && (
                <GenericProfileMenu user={auth.identity} items={items} style={style} className={className} onClick={onClick} onClose={onClose}></GenericProfileMenu>
            )}
        </>
    )
}