import { css } from "@emotion/css";
import { ReactNode } from "react";
import { EtudeDetails as GenericEtudeDetails, EtudeDetailsStyle } from "../component/etude-details";
import { EtudeStats, EtudeStatsStyle } from "../component/etude-stats";
import { useTheme } from "../context/theme";
import { CollapsablePanel } from "./collapsable-panel";
import { List, ListItem } from "./list";

interface EtudeDetailsProps {
    title: string
    subtitle?: string
    description?: string
    previewImageSource?: string
    /**
     * React node that represents the preview image
     * @deprecated
     * @todo Remove the previewImageElement after preview images are stored as image files
     */
    previewImageElement?: ReactNode
    stats?: {
        played?: number
        liked?: number
        subscribed?: number
    }
    exercises?: {
        id: string
        title: string
    }[]
    onEdit?: () => void
    onDelete?: () => void
}

export function EtudeDetails (props: EtudeDetailsProps) {
    const {
        title,
        subtitle,
        description,
        previewImageSource,
        previewImageElement,
        stats,
        exercises,
        onEdit,
        onDelete
    } = props
    const { colors } = useTheme()
    
    const style: EtudeDetailsStyle = {
        primaryTextColor: colors.primaryColor.shade6,
        secondaryTextColor: colors.primaryColor.shade5
    }

    const statsStyle: EtudeStatsStyle = {
        textColor: colors.primaryColor.shade6
    }

    const exercisesTitle = !exercises || exercises.length <= 0 ? 'no-exercise' : exercises.length > 1 ? 'count-of-exercises' : 'count-of-exercise'
    return (
        <GenericEtudeDetails
            title={title}
            subtitle={subtitle}
            description={description}
            previewImageSource={previewImageSource}
            previewImageElement={previewImageElement}
            style={style}
            hideImageInPhone
            belowSubtitleDetails={
                <EtudeStats played={stats?.played} liked={stats?.liked} subscribed={stats?.subscribed} style={statsStyle} className={css({ width: '210px' })} />
            }
            belowDescriptionDetails={
                <CollapsablePanel labelKey={exercisesTitle} labelParams={{ count: exercises?.length || 0 }} collapsed>
                    {Boolean(exercises && exercises.length) && (
                        <List>
                            {exercises?.map(exercise => (
                                <ListItem key={`exercise-${exercise.id}`} icon="one-note">{exercise.title}</ListItem>
                            ))}
                        </List>
                    )}
                </CollapsablePanel>
            }
            onEdit={onEdit}
            onDelete={onDelete}
        />
    )
}