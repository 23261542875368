import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    width: '100%'
})

const InnerContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    width: '100%',
    gap: '16px',
    alignItems: 'center'
})

const RunwayContainer = styled.div({
    flex: '1 0 0',
    position: 'relative',
    boxSizing: 'border-box',
    height: '1.5rem'
})

const Runway = styled.div({
    position: 'absolute',
    boxSizing: 'border-box',
    opacity: '0.4',
    top: 0,
    left: 0,
    marginTop: '0.5rem',
    height: '0.5rem',
    borderRadius: '0.5rem',
    width: '100%'
})

const Bar = styled.div({
    position: 'absolute',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    marginTop: '0.5rem',
    height: '0.5rem',
    borderRadius: '0.5rem',
    transition: 'width 200ms cubic-bezier(0, 0, 0.2, 1)'
})

const SliderButton = styled.div({
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1.5rem',
    boxSizing: 'border-box',
    transform: 'translateX(-50%)',
    cursor: 'pointer',
    transition: 'left 200ms cubic-bezier(0, 0, 0.2, 1)'
})

const RightLabel = styled.label({
    userSelect: 'none',
    minWidth: '20px'
})

const Label = styled.label({
    userSelect: 'none'
})

export interface NumericSliderStyle {
    color?: string
}

interface NumericSliderProps {
    value: number
    min?: number
    max?: number
    labelPosition?: 'right' | 'none' | 'bottom'
    onChange?: (value: number) => void
    style?: NumericSliderStyle
}

export function NumericSlider (props: NumericSliderProps) {
    const {
        value,
        min,
        max,
        labelPosition,
        onChange,
        style
    } = props

    const [internalValue, setInternalValue] = useState (value)
    const labelPositionValue = labelPosition ?? 'right'
    const minValue = min ?? 0
    const maxValue = max ?? 100

    if (minValue >= maxValue) {
        throw new Error (`Min value must be smaller than max value. Min is ${minValue}, max is ${maxValue}.`)
    }

    let timeout: NodeJS.Timeout | undefined
    useEffect (() => {
        if (value !== internalValue) {
            timeout = setTimeout (() => {
                onChange && onChange (internalValue)
            }, 300)
        }
        return () => {
            clearTimeout (timeout)
        }
    }, [internalValue])
    
    const x = 100 * (internalValue - minValue) / (maxValue - minValue)
    const runway = useRef <HTMLDivElement> (null)
    const details = useRef({
        status: 'ended',
        value: internalValue,
        x: 0
    })

    const start = (e: React.MouseEvent | React.TouchEvent) => {
        const x = (e as React.MouseEvent).pageX || (e as React.TouchEvent).touches[0].pageX
        details.current.status = 'started'
        details.current.value = internalValue
        details.current.x = x
    }

    const move = (e: MouseEvent | TouchEvent) => {
        if (details.current.status === 'started' && runway.current) {
            const x = (e as MouseEvent).pageX || (e as TouchEvent).touches[0].pageX
            const gap = runway.current.offsetWidth / (maxValue - minValue)
            const diffX = x - details.current.x

            const indexDiff = Math.floor((diffX + gap / 2) / gap)
            let newValue = details.current.value + indexDiff
            if (newValue < minValue) {
                newValue = minValue
            }
            if (maxValue < newValue) {
                newValue = maxValue
            }
            
            setInternalValue (newValue)
        }
    }

    const end = (e: MouseEvent | TouchEvent) => {
        details.current.status = 'ended'
    }

    useEffect(() => {
        window.addEventListener('mousemove', move)
        window.addEventListener('touchmove', move)
        window.addEventListener('mouseup', end)
        window.addEventListener('touchend', end)
        return () => {
            window.removeEventListener('mousemove', move)
            window.removeEventListener('touchmove', move)
            window.removeEventListener('mouseup', end)
            window.removeEventListener('touchend', end)
        }
    })

    return (
        <Container>
            <InnerContainer>
                <RunwayContainer ref={runway}>
                    <Runway className={css({
                        backgroundColor: style?.color
                    })} />
                    <Bar className={css({
                        backgroundColor: style?.color,
                        width: `${x}%`
                    })} />
                    <SliderButton className={css({
                        backgroundColor: style?.color,
                        left: `${x}%`
                    })} onMouseDown={start} onTouchStart={start} />
                </RunwayContainer>
                {labelPositionValue === 'right' && (
                    <RightLabel>{internalValue}</RightLabel>
                )}
            </InnerContainer>
            {labelPositionValue === 'bottom' && (
                <Label>{internalValue}</Label>
            )}
        </Container>
    )
}