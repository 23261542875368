import { createContext, PropsWithChildren, useContext, useMemo } from "react"

export * from './instrument'

class Audio {
    private _audioContext: AudioContext
    get audioContext () {
        if (!this._audioContext) {
            this._audioContext = new AudioContext ()
        }
        return this._audioContext
    }
}

const audioContext = createContext <Audio | undefined> (undefined)

export const useAudio = () => {
    const context = useContext (audioContext)

    if (!context) {
        throw new Error (`Create audio provider to use the audio context.`)
    }

    return context
}

export function AudioProvider (props: PropsWithChildren<{}>) {
    const value = useMemo(() => new Audio(), [])
    return (
        <audioContext.Provider value={value}>
            {props.children}
        </audioContext.Provider>
    )
}