import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
    CognitoUserSession,
    AuthenticationDetails,
    ISignUpResult,
} from 'amazon-cognito-identity-js'
import { CognitoAuthResult, ConfirmationDetails, ResendConfirmationDetails, SigninDetails, SignupDetails } from '../types'

interface CognitoClientOptions {
    userPoolId: string
    clientId: string
}

export class CognitoClient {
    private userPool: CognitoUserPool
    private cache: {
        email?: string
        pass?: string
    }

    constructor (options: CognitoClientOptions) {
        this.cache = {}
        this.userPool = new CognitoUserPool({
            UserPoolId: options.userPoolId,
            ClientId: options.clientId
        })
    }
    
    async signup (details: SignupDetails): Promise<CognitoAuthResult> {
        const signupResult = await this._signup(details)
        if (signupResult.userConfirmed) {
            const authenticationResult = await this._authenticate(details)
            return {
                authStatus: 'complete',
                idToken: authenticationResult.getIdToken().getJwtToken()
            }
        } else {
            this.cache.email = details.email
            this.cache.pass = details.password
            return {
                authStatus: 'unverified_email'
            }
        }
    }

    async signin (details: SigninDetails): Promise<CognitoAuthResult> {
        return this._authenticate(details)
            .then<CognitoAuthResult>(session => {
                const idToken = session.getIdToken().getJwtToken()

                return {
                    authStatus: 'complete',
                    idToken
                }
            })
            .catch<CognitoAuthResult>(err => {
                if (err.name === 'UserNotConfirmedException') {
                    this.cache.email = details.email
                    this.cache.pass = details.password
                    return {
                        authStatus: 'unverified_email'
                    }
                } else {
                    throw err
                }
            })
    }

    async confirmCode (details: ConfirmationDetails): Promise<CognitoAuthResult> { 
        if (!this.cache.email) {
            return {
                authStatus: 'signin'
            }
        }

        const confirmation = await this._confirmRegistration({
            email: this.cache.email,
            code: details.code
        })

        if (confirmation !== 'SUCCESS') {
            throw new Error (`Email verification is failed.`)
        }
        
        const result = await this.signin({
            email: this.cache.email,
            password: this.cache.pass
        })

        if (result.authStatus === 'complete') {
            delete this.cache.email
            delete this.cache.pass
        }

        return result
    }

    async resendCode () {
        if (!this.cache.email) {
            return {
                authStatus: 'signin'
            }
        }

        const result = await this._resendConfirmationCode({
            email: this.cache.email
        })

        return result
    }

    async signout () {
        return new Promise<void>((resolve, reject) => {
            let user = this.userPool.getCurrentUser()
            if (!user) {
                throw new Error(``)
            }
    
            user.signOut(() => {
                resolve()
            })
        })
    }

    private _signup (details: SignupDetails): Promise<ISignUpResult> {
        return new Promise ((resolve, reject) => {
            const { email, password, name } = details
            const attributeList = [
                new CognitoUserAttribute({
                    Name: 'nickname',
                    Value: name
                })
            ]
    
            this.userPool.signUp(email, password, attributeList, [], function(err, result) {
                if (err) {
                    reject (err)
                } else if (result) {
                    resolve(result)
                } else {
                    reject('Error occured during sign up.')
                }
            })
        })
    }

    private _authenticate (details: SigninDetails): Promise<CognitoUserSession> {
        return new Promise((resolve, reject) => {
            const { email, password } = details
            const user = new CognitoUser({
                Username: email,
                Pool: this.userPool
            })

            user.authenticateUser(new AuthenticationDetails({
                Username: email,
                Password: password
            }), {
                onSuccess(session, userConfirmationNecessary?) {
                    resolve(session)
                },
                onFailure(err) {
                    reject(err)
                }
            })
        })
    }

    private _confirmRegistration (details: ConfirmationDetails & { email: string }) {
        return new Promise ((resolve, reject) => {
            const { code, email } = details
            const user = new CognitoUser({
                Username: email,
                Pool: this.userPool
            })

            user.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    reject (err)
                } else if (result) {
                    resolve (result)
                } else {
                    reject (`Error occured during confirmation.`)
                }
            })
        })
    }

    private _resendConfirmationCode (details: ResendConfirmationDetails) {
        return new Promise ((resolve, reject) => {
            const { email } = details
            const user = new CognitoUser({
                Username: email,
                Pool: this.userPool
            })

            user.resendConfirmationCode((err, result) => {
                if (err) {
                    reject (err)
                } else if (result) {
                    resolve (result)
                } else {
                    reject (`Error occured during resending confirmation code.`)
                }
            })
        })
    }
}