import { InputDeviceProvider } from "@mom/ui"
import { Instrument } from "../../audio"
import { EtudeExerciseDetails, Services } from "../../../types"
import { Player } from "./player"
import { AudioProvider } from "./types"

interface PlayerRepositoryOptions {
    audio: AudioProvider
    inputDevice: InputDeviceProvider
    instrument: Instrument
    services: Services
}

export class PlayerRepository {
    private players: {
        [id: string]: {
            player?: Player
            loader: Promise<[{ parts: any[] }, EtudeExerciseDetails]>
        }
    }

    constructor (private options: PlayerRepositoryOptions) {
        this.players = {}
    }

    async load (etudeId: string, exerciseId: string) {
        if (!this.players[exerciseId]) {
            const { audio, inputDevice, instrument, services } = this.options
            const { musicDataClient, practiceAndHistoryClient } = services
            const loader = Promise.all([
                musicDataClient.getMusicTimeline(exerciseId),
                practiceAndHistoryClient.getExercise(etudeId, exerciseId)
            ])
            this.players[exerciseId] = {
                loader
            }
            const [timeline, exerciseDetails] = await loader
            this.players[exerciseId].player = new Player ({
                audio,
                inputDevice,
                instrument,
                timeline: timeline?.parts?.[0],
                exerciseDetails
            })
        } else if (!this.players[exerciseId].player) {
            await this.players[exerciseId].loader
        }

        return this.players[exerciseId].player
    }

    get (exerciseId: string) {
        return this.players[exerciseId]?.player
    }

    dispose (exerciseId: string) {
        this.players[exerciseId].player?.dispose()
        delete this.players[exerciseId]
    }
}