import { css } from "@emotion/css";
import { PropsWithChildren } from "react";
import { Auto, AutoFill, AutoHug } from "./auto-layout";
import { Icon } from "./icon";
import { Text } from "./text";

function shortenNumber (n: number | undefined) {
    if (!n) return ``

    const multiplier = ['', 'K', 'M', 'B']
    let multiplierIndex = 0
    while (n >= 1000 && multiplierIndex < multiplier.length - 1) {
        n = n / 1000
        multiplierIndex ++
    }

    return `${n.toFixed(n < 10 ? 1 : 0)}${multiplier[multiplierIndex]}`
}

export interface EtudeStatsStyle {
    textColor?: string
}

interface EtudeStatsProps {
    played?: number
    liked?: number
    subscribed?: number
    style?: EtudeStatsStyle
    className?: string
}

export function EtudeStats (props: EtudeStatsProps) {
    const {
        played,
        liked,
        subscribed,
        style,
        className
    } = props

    const showStats = Boolean(played && liked)
    const showSubscriptionStat = Boolean(subscribed)
    const Container = (props: PropsWithChildren<{ className?: string }>) => props.className ? <div className={props.className}>{props.children}</div> : <>{props.children}</>

    return (
        <>
            {showStats && (
                <Container className={className}>
                    <Auto direction="horizontal">
                        <AutoFill direction="horizontal" spacing={6}>
                            <AutoHug tooltip="Played">
                                <Icon icon="play" className={css({ width: '18px', stroke: '#00BA50' })} />
                            </AutoHug>
                            <AutoFill verticalAlignment="center"  tooltip="Played">
                                <Text text={shortenNumber(played)} fontSize={0.8} color={style?.textColor}></Text>
                            </AutoFill>
                        </AutoFill>
                        <AutoFill direction="horizontal" spacing={6}>
                            <AutoHug tooltip="Liked">
                                <Icon icon="like" className={css({ width: '18px', stroke: '#F91880' })} />
                            </AutoHug>
                            <AutoFill verticalAlignment="center"  tooltip="Liked">
                                <Text text={shortenNumber(liked)} fontSize={0.8} color={style?.textColor}></Text>
                            </AutoFill>
                        </AutoFill>
                        <AutoFill direction="horizontal" spacing={6}>
                            {showSubscriptionStat && (
                                <>
                                    <AutoHug tooltip="Subscribed">
                                        <Icon icon="subscribe" className={css({ width: '18px', stroke: '#9747FF' })} />
                                    </AutoHug>
                                    <AutoFill verticalAlignment="center" tooltip="Subscribed">
                                        <Text text={shortenNumber(subscribed)} fontSize={0.8} color={style?.textColor}></Text>
                                    </AutoFill>
                                </>
                            )}
                        </AutoFill>
                    </Auto>
                </Container>
            )}
        </>
    )
}