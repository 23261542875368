import { useLocation, useNavigate } from "react-router";
import { LeftMenu as GenericLeftMenu, LeftMenuItemDetails } from "../component/left-menu";
import { useTheme } from "../context/theme";
import { useLocalisationContext } from "../context/localisation";

const routeMap: { [key: string]: string } = {
    "home": "/",
    "subscribed-etudes": "/subscribed-etudes",
    "etude-editor": "/etude-editor",
    "appearances": "/settings",
    "input-devices": "/settings/input-devices"
}

interface LeftMenuProps {
    className?: string
}

export function LeftMenu (props: LeftMenuProps) {
    const {
        className
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()

    const selected = Object.entries(routeMap).filter(x => x[1] === location.pathname).map(x => x[0])[0]

    const defaultMenuItems = [{
        id: 'home',
        icon: 'home' as const,
        text: localisationStrings['home']
    }, {
        id: 'subscribed-etudes',
        icon: 'one-note' as const,
        text: localisationStrings['subscribed-etudes']
    }, {
        id: 'etude-editor',
        icon: 'two-notes' as const,
        text: localisationStrings['etude-editor']
    }]

    const settingsMenuItems = [{
        id: 'home',
        icon: 'home' as const,
        text: localisationStrings['home']
    }, {
        id: 'appearances',
        text: localisationStrings['appearances']
    }, {
        id: 'input-devices',
        text: localisationStrings['input-devices']
    }]

    const menuItems = /^\/settings(\/.*)?$/gi.test(location.pathname) ? settingsMenuItems : defaultMenuItems

    const style = {
        backgroundColor: theme.colors.primaryColor.shade1,
        selectedBackground: theme.colors.primaryColor.shade2,
        textColor: theme.colors.primaryColor.shade6
    }

    const onChange = (item: LeftMenuItemDetails) => {
        const routePath = routeMap[item.id]
        if (routePath) {
            navigate(routePath)
        }
    }

    return (
        <GenericLeftMenu items={menuItems} style={style} onChange={onChange} selected={selected} className={className}></GenericLeftMenu>
    )
}