import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { PropsWithChildren } from "react";
import { ClassName } from "../types";
import { Overlay, OverlayStyle } from "./overlay";

const Content = styled.div({
    display: 'inline'
})

export interface ModalClassName extends ClassName<'content'> {

}

export interface ModalStyle {
    overlayColor?: string
    backgroundColor?: string
}

interface ModalProps {
    open?: boolean
    style?: ModalStyle
    className?: ModalClassName
    onClose?: () => void
}

export function Modal (props: PropsWithChildren<ModalProps>) {
    const {
        open,
        style,
        className,
        children,
        onClose
    } = props

    const overlayStyle: OverlayStyle = {
        color: style?.overlayColor
    }

    const contentClassNames = [
        className?.content,
        css({
            backgroundColor: style?.backgroundColor
        })
    ].filter (x => x)

    const preventEvent = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <Overlay open={open} style={overlayStyle} onClick={onClose}>
            <Content className={contentClassNames.join(' ')} onClick={preventEvent}>
                {children}
            </Content>
        </Overlay>
    )
}