import { Upload as GenericUpload, UploadStyle } from '../component/upload'
import { useTheme } from '../context/theme'
import { useLocalisationContext } from '../context/localisation'

interface UploadProps {
    files?: File[]
    multiple?: boolean
    labelKey: string
    className?: string
    onChange: (event: { files: File[], size: number }) => void
}

export function Upload (props: UploadProps) {
    const {
        files,
        multiple,
        labelKey,
        className,
        onChange
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()

    const style: UploadStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        textColor: theme.colors.primaryColor.shade6,
        labelColor: theme.colors.primaryColor.shade5
    }
    
    return <GenericUpload label={localisationStrings[labelKey]} multiple={multiple} files={files} className={className} style={style} onChange={onChange}></GenericUpload>
}