import { createContext, PropsWithChildren, useContext } from "react"
import { Services } from "../types"

const ServicesContext = createContext <Services> ({
    config: null as any,
    webSocketClient: null as any,
    practiceAndHistoryClient: null as any,
    musicDataClient: null as any,
    recommendationsClient: null as any,
})

export const useServices = () => useContext <Services> (ServicesContext)

interface ServicesProviderProps {
    services: Services
}

export function ServicesProvider (props: PropsWithChildren<ServicesProviderProps>) {
    const { services, children } = props

    return (
        <ServicesContext.Provider value={services}>
            {children}
        </ServicesContext.Provider>
    )
}