import { css } from "@emotion/css";
import { Auto } from "../auto-layout";
import { Icon } from "../icon";
import { Text } from "../text";
import { LeftMenuItem } from "./item";
import { LeftMenuItemDetails, LeftMenuStyle } from "./types";

export * from './types'

interface LeftMenuProps {
    items: LeftMenuItemDetails[]
    selected?: string
    style?: LeftMenuStyle
    className?: string
    onChange?: (item: LeftMenuItemDetails) => void
}

export function LeftMenu (props: LeftMenuProps) {
    const {
        style,
        items,
        selected,
        className,
        onChange
    } = props

    const onItemClick = (item: LeftMenuItemDetails) => {
        if (selected !== item.id) {
            onChange && onChange(item)
        }
    }

    const classes = [
        className,
        css({
            background: style?.backgroundColor
        })
    ].filter(x => x)

    return (
        <Auto width={240} padding={12} spacing={4} className={classes.join(' ')}>
            {items.map(item => (
                <LeftMenuItem key={`left-menu-item-${item.id}`} style={style} selected={item.id === selected} onClick={() => onItemClick(item)}>
                    {item.icon && (
                        <Icon icon={item.icon} style={{ stroke: style?.textColor }} />
                    )}
                    <Text fontSize={1.35} text={item.text} />
                </LeftMenuItem>
            ))}
        </Auto>
    )
}
