export interface Config {
    "api": {
        "baseUrl": string
    },
    "cdn": {
        "baseUrl": string
    },
    'ws': {
        "endpoint": string
    }
    "cognito": {
        "clientId": string
        "userPoolId": string
    }
}

class DefaultConfigManager {
    config: Config
    async load () : Promise<Config> {
        const response = await fetch('/config.json')
        this.config = await response.json()
        return this.config
    }
    get () : Config {
        return this.config
    }
}

export default new DefaultConfigManager()