import { MusicDataTypes } from "@mom/types"
import { constants } from "../constants"
import { AccidentalAlters, SortedUserNotes, UserNoteToEvaluate } from "../types"

interface ScoringContext {
    noting: number
    timing: number
    ending: number
    correctHit: boolean
    totalHits: number
}

type StaveNotesScoringContext = (ScoringContext | undefined)[]
type TimelineScoringContext = StaveNotesScoringContext[][]

export const defaults = {
    scoring: constants.exercise.scoringThresholds
}

export function evaluateUserNotes (timeline: MusicDataTypes.SheetMusicTimeline, userNotes: UserNoteToEvaluate[], repetition: number) {
    const sortedUserNotes = userNotes.sort((a, b) => a.startDuration < b.startDuration ? -1 : 1)
    const scoreContexts = Array.from(Array(repetition).keys()).flatMap(rep => timeline.measures.map(measure => measure.noteGroups.map(noteGroup => initiateScoreContexts(noteGroup))))
    const lastMeasure = timeline.measures[timeline.measures.length - 1]
    const lastNoteGroup = lastMeasure.noteGroups[lastMeasure.noteGroups.length - 1]
    const timelineDuration = lastNoteGroup.endDuration

    let userNoteIndex = 0
    let currentNoteGroup
    let currentNoteGroupIndex = 0
    let currentMeasureIndex = 0
    // Calculate score for every note in the sheet music
    // Each user note is evaluated and contributes to the score of nearest note in the sheet music
    for (const rep of Array.from(Array(repetition).keys())) {
        for (const [measureIndex, measure] of timeline.measures.entries()) {
            if (!measure) continue
            for (const [noteGroupIndex, noteGroup] of measure.noteGroups.entries()) {
                if (!noteGroup) continue

                currentNoteGroup = {
                    startDuration: noteGroup.startDuration + rep * timelineDuration,
                    endDuration: noteGroup.endDuration + rep * timelineDuration,
                    staveNotes: noteGroup.staveNotes.map(staveNote => (staveNote && {
                        startDuration: staveNote.startDuration + rep * timelineDuration,
                        endDuration: staveNote.endDuration + rep * timelineDuration,
                        note: staveNote.note
                    })),
                }

                currentMeasureIndex = measureIndex + rep * timeline.measures.length
                currentNoteGroupIndex = noteGroupIndex

                userNoteIndex = iterateScoreContextOfUserNotes(currentNoteGroup, currentMeasureIndex, currentNoteGroupIndex, sortedUserNotes, userNoteIndex, scoreContexts)
            }
        }
    }
    
    if (currentNoteGroup) {
        iterateScoreContextOfUserNotes(currentNoteGroup, currentMeasureIndex, currentNoteGroupIndex, sortedUserNotes, userNoteIndex, scoreContexts, true)
    }

    console.log(scoreContexts)

    let numberOfNotes = 0
    let totalNotingScore = 0
    let totalTimingScore = 0
    let totalEndingScore = 0
    const flatContexts = scoreContexts.flatMap(x => x).flatMap(x => x)
    for (let context of flatContexts) {
        if (context) {
            totalNotingScore += context.noting
            totalTimingScore += context.timing
            totalEndingScore += context.ending
            numberOfNotes ++
        }
    }

    const noting = Math.round(totalNotingScore / numberOfNotes)
    const timing = Math.round(totalTimingScore / numberOfNotes)
    const ending = Math.round(totalEndingScore / numberOfNotes)
    // const overall = Math.round((2 * (noting + timing) + ending) / 5)
    const overall = Math.round((noting + timing) / 2)

    return {
        overall,
        noting,
        timing,
        ending
    }
}

function initiateScoreContexts (noteGroup: MusicDataTypes.SheetMusicTimelineNoteGroup): StaveNotesScoringContext {
    return noteGroup.staveNotes.map(x => (x && {
        timing: 0,
        noting: 0,
        ending: 0,
        totalHits: 0,
        correctHit: false
    }))
}

function iterateScoreContextOfUserNotes (noteGroup: MusicDataTypes.SheetMusicTimelineNoteGroup, measureIndex: number, noteGroupIndex: number, userNotes: UserNoteToEvaluate[], userNoteIndex: number, contexts: TimelineScoringContext, iterateAllUserNotes?: boolean) {
    for (; userNoteIndex < userNotes.length; userNoteIndex ++) {
        const userNote = userNotes[userNoteIndex]
        if (!iterateAllUserNotes && (noteGroup.startDuration + noteGroup.endDuration) / 2 < userNote.startDuration) {
            break
        }

        contexts[measureIndex].splice(noteGroupIndex, 1, calculateScoreContext(noteGroup, userNote, contexts[measureIndex][noteGroupIndex]))
    }
    return userNoteIndex
}

function calculateScoreContext (noteGroup: MusicDataTypes.SheetMusicTimelineNoteGroup, userNote: UserNoteToEvaluate, scoreContexts: StaveNotesScoringContext): StaveNotesScoringContext {
    const userNoteIndex = getUserNoteMIDIIndex(userNote)
    const matchedNoteIndex = noteGroup.staveNotes.findIndex(x => x && userNoteIndex === getStaveNoteMIDIIndex(x))

    if (userNoteIndex !== undefined && matchedNoteIndex >= 0) {
        const matchedNote = noteGroup.staveNotes[matchedNoteIndex]
        const context = scoreContexts[matchedNoteIndex]
        
        if (matchedNote && context) {
    
            if (Math.abs(noteGroup.startDuration - userNote.startDuration) < defaults.scoring.limit ? true : false) {
                context.correctHit = true
                context.totalHits ++
                context.noting = calculateNotingScore(context)
                context.timing = calculateTimingScore(context, noteGroup.startDuration, userNote.startDuration)
                context.ending = calculateEndingScore(context, matchedNote.endDuration, userNote.endDuration, matchedNote.endDuration - matchedNote.startDuration)
            }
        }
    } else {
        scoreContexts.forEach(context => {
            if (context) {
                context.totalHits ++
                context.noting = calculateNotingScore(context)
            }
        })
    }

    return scoreContexts
}

function calculateNotingScore (context: ScoringContext): number {
    if (!context.correctHit) return 0

    const score = 100 - 100 * (context.totalHits - 1) / defaults.scoring.notingErrorLimit
    return score < 0 ? 0 : score
}

function calculateTimingScore (context: ScoringContext, noteGroupStartTime: number, userNoteStartTime: number): number {
    if (!context.correctHit) return 0

    const timing = Math.abs(noteGroupStartTime - userNoteStartTime) - defaults.scoring.lateEarly
    const score = 100 - 100 * (timing < 0 ? 0 : timing) / (defaults.scoring.limit - defaults.scoring.lateEarly)
    return score < 0 ? 0 : score
}

function calculateEndingScore (context: ScoringContext, noteEndDuration: number, userNoteEndDuration: number | undefined, noteDuration: number): number {
    if (!context.correctHit || !userNoteEndDuration) return 0
    const endingTolerance = noteDuration / 4 < defaults.scoring.endingLimit ? noteDuration / 4 : defaults.scoring.endingLimit
    const ending = Math.abs(noteEndDuration - userNoteEndDuration || 0) - endingTolerance
    const score = 100 - 100 * (ending < 0 ? 0 : ending) / (noteDuration / 2 - endingTolerance)
    return score < 0 ? 0 : score
}

function getUserNoteMIDIIndex (note: UserNoteToEvaluate) {
    const noteIndex = SortedUserNotes.findIndex(x => x === note.note)

    if (noteIndex >= 0 && note.octave !== undefined) {
        return (note.octave + 1) * 12 + noteIndex
    }
}

function getStaveNoteMIDIIndex (staveNote?: MusicDataTypes.SheetMusicTimelineStaveNote) {
    const note = staveNote?.note
    if (note?.type === 'note') {
        const noteIndex = SortedUserNotes.findIndex(x => x === note.note)

        if (noteIndex >= 0 && note.octave !== undefined) {
            return (note.octave + 1) * 12 + noteIndex + AccidentalAlters[note.accidental || 'natural']
        }
    }
}

