import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

interface SoundSettingsControl {
    setMetronome (volume: number): void
}

export const useSoundSettingsControl = (id: string): SoundSettingsControl => {
    const { soundSettings } = usePlayer (id)
    return soundSettings
}

export const useMetronomeVolume = (id: string) => {
    const { soundSettings } = usePlayer (id)
    const [volume, setVolume] = useState (soundSettings.settings.metronome) 

    useEffect (() => {
        soundSettings.on('changeMetronome', setVolume)
        return () => {
            soundSettings.off('changeMetronome', setVolume)
        }
    })

    return volume
}
