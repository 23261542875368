import React from "react"
import { Icon as GenericIcon, IconStyle, IconType } from "../component/icon"
import { useTheme } from "../context/theme"

interface IconProps {
    icon: IconType
    className?: string
    /**
     * Size of the icon in rem units
     */
    size?: number
    onClick?: (e: React.MouseEvent) => void
}

export const Icon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    const {
        icon,
        className,
        size,
        onClick
    } = props
    const { colors } = useTheme()

    const style: IconStyle = {
        stroke: colors.primaryColor.shade6
    }

    return <GenericIcon ref={ref} icon={icon} className={className} size={size} onClick={onClick} style={style}></GenericIcon>
})

export const PersonalisedIcon = React.forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    const {
        icon,
        className,
        size,
        onClick
    } = props
    const { colors } = useTheme()

    const style: IconStyle = {
        stroke: colors.secondaryColor.shade1
    }

    return <GenericIcon ref={ref} icon={icon} className={className} size={size} onClick={onClick} style={style}></GenericIcon>
})