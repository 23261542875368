import styled from "@emotion/styled"
import { Icon } from "../../../component/icon"
import { useLocalisationContext } from "../../../context/localisation"
import { secondaryColors, useTheme, useSelectedTheme, SecondaryColorType } from "../../../context/theme"

interface ThemeItemProps {
    secondaryColor: SecondaryColorType
}

export function ColorItem (props: ThemeItemProps) {
    const {
        secondaryColor
    } = props
    const themeCxt = useTheme()
    const selectedTheme = useSelectedTheme()
    const { localisationStrings } = useLocalisationContext()

    const onSelect = () => {
        themeCxt.selectSecondaryColor(secondaryColor)
    }

    const Container = styled.div ({
        width: '54px',
        height: '54px',
        borderRadius: '54px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
        background: secondaryColors[secondaryColor].shade1,
    })

    return (
        <Container onClick={onSelect}>
            {secondaryColor === selectedTheme.secondaryColor ? (
                <Icon icon="check" size={1.5} style={{ stroke: themeCxt.colors.primaryColor.shade6 }} />
            ) : (
                <></>
            )}
        </Container>
    )
}