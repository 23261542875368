import React from 'react'
import { Button as GenericButton, ButtonStyle, ButtonVariant } from '../component/button'
import { IconType } from '../component/icon'
import { useLocalisationContext } from '../context/localisation'
import { useTheme } from '../context/theme'
import { Icon } from './icon'

interface ButtonProps {
    variant?: ButtonVariant
    className?: string
    textKey: string
    icon?: IconType
    fullWidth?: boolean
    onClick?: (e: React.MouseEvent) => void
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        variant,
        className,
        textKey,
        icon,
        fullWidth,
        onClick
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()

    const style: ButtonStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        textColor: theme.colors.primaryColor.shade6,
        highlightColor: theme.colors.primaryColor.shade2
    }
    
    return (
        <GenericButton ref={ref} variant={variant} className={className} style={style} fullWidth={fullWidth} onClick={onClick}>
            {icon && (
                <Icon icon={icon} />
            )}
            {localisationStrings[textKey]}
        </GenericButton>
    )
})
