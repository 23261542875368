import { Dispatch, Middleware } from "@reduxjs/toolkit"

interface AsyncMiddlewareEnhancers <TState, TDispatch extends Dispatch> {
    (state: TState, dispatch: TDispatch, next: () => Promise<any>): Promise<any>
}

interface AsyncMiddlewareConfiguration <TState, TDispatch extends Dispatch, TService> {
    enhancers?: AsyncMiddlewareEnhancers<TState, TDispatch>[]
    services: TService
}

export const asyncMiddleware = <TState, TDispatch extends Dispatch, TService>(options: AsyncMiddlewareConfiguration<TState, TDispatch, TService>) => <Middleware>((store) => (next) => (action) => {
    let handlerMix = () => {
        if (typeof action === 'function') {
            return action(store.getState(), store.dispatch.bind(store), options.services)
        } else {
            return next(action)
        }
    }
    if (typeof action === 'function' && options.enhancers) {
        for(let i = options.enhancers.length - 1; i >= 0; i--) {
            const enhancer = options.enhancers[i]

            let next = handlerMix
            handlerMix = () => {
                return enhancer(store.getState(), store.dispatch.bind(store) as TDispatch, next)
            }
        }
    }
    return handlerMix()
})