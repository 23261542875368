import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import { piano } from "./piano"

export interface Instrument {
    setAudioContext (audioContext: AudioContext): void
    playNote(noteIndex: number, velocity?: number): void
}

class DefaultInstrument implements Instrument {
    private audioContext: AudioContext
    
    constructor () {

    }

    setAudioContext (audioContext: AudioContext) {
        this.audioContext = audioContext
    }

    playNote(noteIndex: number, velocity?: number): void {
        if (!this.audioContext) {
            return
        }

        const ctx = this.audioContext
        const osc = ctx.createOscillator()
        const gain = ctx.createGain()
        const currentTime = ctx.currentTime
        const frequency = 440 * Math.pow(2, ((noteIndex - 69) * 1/12))
        console.log (`Playing frequency`, frequency, 'Node index', noteIndex)
        const volume = (velocity || 127) / 127

        osc.frequency.value = frequency
        osc.setPeriodicWave(this.audioContext.createPeriodicWave(piano.real, piano.imag))


        gain.gain.value = volume
        gain.gain.exponentialRampToValueAtTime(volume, currentTime + 0.1)
        gain.gain.exponentialRampToValueAtTime(0.001, currentTime + 3)

        osc.connect(gain).connect(this.audioContext.destination)
        osc.start(currentTime)
        osc.stop(currentTime + 3)
    }
}

const InstrumentContext = createContext <Instrument | undefined> (undefined)

export const useInstrument = () => {
    const context = useContext (InstrumentContext)
    
    if (!context) {
        throw new Error (`Create instrument provider to use the instrument context.`)
    }

    return context
}

export function InstrumentContextProvider (props: PropsWithChildren<{}>) {
    const {
        children
    } = props
    const value = useMemo(() => new DefaultInstrument(), [])

    return (
        <InstrumentContext.Provider value={value}>
            {children}
        </InstrumentContext.Provider>
    )
}