import { SheetMusicTypes } from "@mom/types";
import { sheetMusic } from "@mom/ui";
import { device } from "../../../util/device-detection";
import { EventEmitter } from "./event-emitter";

type RenderingDetailsEvents = {
    changeRenderingDetails: (renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails) => void
}

interface RenderingDetailsOptions {
    timeline: any
}

export class RenderingDetails extends EventEmitter <RenderingDetailsEvents>  {
    renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails

    private timeline: any
    private width: number

    constructor (options: RenderingDetailsOptions) {
        super ()

        this.timeline = options.timeline
    }

    setWidth (width: number) {
        this.width = width

        this.calculateRenderingDetails ()
    }

    private calculateRenderingDetails () {
        if (this.timeline && (this.width || device.phone)) {
            this.renderingDetails = sheetMusic.getSheetMusicRenderingDetails(this.timeline, { width: this.width / 5, singleLine: device.phone, coefWidth: 7, documentMargin: 10 })
            this.trigger('changeRenderingDetails', this.renderingDetails)
        }
    }
}
