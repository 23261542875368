import styled from "@emotion/styled";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { useAuth } from "../auth";
import { useLocalisationContext } from "../context/localisation";
import { useTheme } from "../context/theme";
import { Button } from "./button";

interface AuthenticatedActionProps {
    messageKey: string
    className?: string
}

export function AuthenticatedAction (props: PropsWithChildren<AuthenticatedActionProps>) {
    const { children, messageKey, className } = props
    const auth = useAuth ()
    const { colors } = useTheme ()
    const { localisationStrings } = useLocalisationContext()
    const [signupPanelVisibility, setSignupPanelVisibility] = useState(false)
    const messageContainer = useRef <HTMLDivElement> (null)

    useEffect(() => {
        if (messageContainer.current && signupPanelVisibility) {
            const rect = messageContainer.current.getBoundingClientRect()
            if (rect.x) {

            }
        }
    })

    useEffect(() => {
        document.addEventListener('click', hideSignupPanel)
        return () => {
            document.removeEventListener('click', hideSignupPanel)
        }
    })

    const click = (event: React.MouseEvent) => {
        setSignupPanelVisibility(true)
        event.stopPropagation()
        event.preventDefault()
    }

    const hideSignupPanel = () => {
        setSignupPanelVisibility(false)
    }

    const signin = () => {
        auth.navigateTo('signin')
    }

    const Container = styled.div({
        position: 'relative',
        display: 'inline'
    })

    const Overlay = styled.div({
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        cursor: 'pointer'
    })

    const MessageContainer = styled.div({
        position: 'absolute',
        left: '0',
        top: '100%',
        marginTop: '10px',
        padding: '16px 32px',
        borderRadius: '12px',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `0px 0px 20px ${colors.primaryColor.shade2}`,
        border: `1px solid ${colors.primaryColor.shade2}`,
        backgroundColor: colors.primaryColor.shade1,
        color: colors.primaryColor.shade6
    })

    return (
        <Container className={className}>
            {signupPanelVisibility && (
                <MessageContainer ref={messageContainer}>
                    <p>{localisationStrings[messageKey]}</p>
                    <Button textKey="signin" variant="inverted" onClick={signin} />
                </MessageContainer>
            )}
            {children}
            {!auth.isAuthenticated && (
                <Overlay onClick={click} />
            )}
        </Container>
    )
}