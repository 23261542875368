import { css } from "@emotion/css"
import { PropsWithChildren } from "react"
import { ProfileMenuStyle } from "./types"
import { Auto } from "../auto-layout"

interface LeftMenuItemProps {
    selected?: boolean
    onClick?: () => void
}

export function ProfileMenuItem (props: PropsWithChildren<LeftMenuItemProps>) {
    const {
        selected,
        onClick,
        children
    } = props

    return (
        <Auto
            direction='horizontal'
            verticalAlignment='center'
            spacing={20}
            padding="10px 16px"
            className={css({
                cursor: !selected ? 'pointer' : undefined
            })}
            onClick={onClick}
        >
            {children}
        </Auto>
    )
}