import { RecommendationsTypes } from "@mom/types";
import HttpClient, { HttpClientConfiguration } from "./client";

interface RecommendationsClientOptions extends HttpClientConfiguration {
    
}

export class RecommendationsClient {
    private httpClient: HttpClient
    constructor (options: RecommendationsClientOptions) {
        this.httpClient = new HttpClient(options)
    }

    getRecommendations (lastKey?: string) {
        return this.httpClient.get<RecommendationsTypes.GetRecommendationsResponse>(lastKey ? `?lastKey=${lastKey}` : '')
    }
}