export default {
    'home': 'Home',
    'subscribed-etudes': 'My Etudes',
    'etude-editor': 'Etude Editor',
    'search-label': 'Search',
    'signin': 'Sign in',
    'musicxml-files': 'Musicxml Files',
    'title': 'Title',
    'description': 'Description',
    'count-of-exercises': '{count} Exercises',
    'count-of-exercise': '{count} Exercise',
    'no-exercise': 'No Exercise',
    'thumbnail': 'Thumbnail',
    'toggle-on': 'On',
    'toggle-off': 'Off',
    'input-devices': 'Input Devices',
    'delete-etude-confirmation-message': `Do you want to delete the etude '{title}'?`,
    'delete-exercise-confirmation-message': `Do you want to delete the exercise named '{title}'?`,
    'confirm': 'Confirm',
    'cancel': 'Cancel',
    'unsubscribe': 'Unsubscribe',
    'subscribe': 'Subscribe',
    'exercises': 'Exercises',
    'sign-in-to-subscribe': 'Please sign in to subscribe to the etude.',
    'appearances': 'Display',
    'theme': 'Theme',
    'system-default': 'Default',
    'light': 'Light',
    'dimmed': 'Dimmed',
    'dark': 'Dark',
    'secondary-color': 'Color',
    'no-input-device-message': 'There is no device connected. Please ensure that your MIDI keyboard or piano is connected to your computer.',
    'available-input-devices': 'Available Input Devices',
    'selected-input-device': 'Selected device: {device}',
    'no-selected-input-device-message': 'There is no device selected. Please select your input device from the list below.',
    'settings': 'Settings',
    'message-to-switch-to-landscape': 'Readability of score gets better in landscape mode of small mobile devices. Please turn your device to the side to switch to landscape mode.',
    'cancel-switch-to-landscape': 'Use in portrait mode',
    'repetition': 'Repetition',
    'tempo': 'Tempo',
    'note-tracker': 'Note Tracker',
    'feedback': 'Feedback',
    'player-settings': 'Player Settings',
    'sound-settings': 'Sound Settings',
    'metronome-volume': 'Metronome Volume',
    'exercise-files': 'Exercise Files',
    'create': 'Create',
    'update': 'Update',
    'upload': 'Upload',
    'new-etude': 'New Etude',
    'musicxml-processing': 'Processing ...',
    'musicxml-parsing-error': 'Error occurred while parsing !',
    'musicxml-validation-error': 'Error occurred while validating music xml !',
    'musicxml-process-error': 'Error occurred while processing music xml !',
    'musicxml-error': 'Error occurred while processing music xml !',
    'musicxml-download': 'Downloading sheet music ...',
    'edit-thumbnail': 'Edit Thumbnail',
    'thumbnail-editor': 'Thumbnail Editor',
    'my-images': 'My Images',
    'generate': 'Generate',
    'background-color': 'Background Color',
    'thumbnail-exercise-properties': 'Properties',
    'email-verification-message': 'Please verify your email address by using verification code sent to your email address.',
    'verification-code': 'Verification Code',
    'verify-code': 'Verify',
    'resend-code': 'Resend',
    'navigate-to-signin-message': 'If you have an account, Sign in',
    'navigate-to-signup-message': `If you don't have an account, you can sign up by clicking here.`,
    'name': 'Name',
    'email': 'Email',
    'password': 'Password',
    'signup': 'Sign up',
    'signout': 'Logout',
    'signin-error': 'Error occured while signing in. Please check your email and password.',
    'signup-error': 'Error occured while signing up. Please check your details.',
    'email-verification-error': 'Error occured while verifying your email. Please check your details.',
    'resend-verification-error': 'Error occured while sending your verification code. Please try again.',
    'Incorrect username or password.': 'Email or password is incorrect. Please check your details.',
    'Missing required parameter USERNAME': 'Please enter your email.',
    'An account with the given email already exists.': 'There is an account with the given email. You can sign in by clicking the below link.',
    'Password did not conform with policy: Password not long enough': 'Password must be at least 8 characters and combination of numbers, uppercase and lowercase letters.',
    'Password did not conform with policy: Password must have uppercase characters': 'Password must be at least 8 characters and combination of numbers, uppercase and lowercase letters.',
    'Password did not conform with policy: Password must have lowercase characters': 'Password must be at least 8 characters and combination of numbers, uppercase and lowercase letters.',
    'Password did not conform with policy: Password must have numeric characters': 'Password must be at least 8 characters and combination of numbers, uppercase and lowercase letters.',
    'Invalid verification code provided, please try again.': 'Verification code is incorrect. Please check your email for the correct verification code.',
    'overall-score': 'OVERALL',
    'noting-score': 'Noting',
    'timing-score': 'Timing',
    'congragulations-message-above-90': '"Wow, fantastic job ! Keep shining with your superior performance and reaching for the stars.',
    'congragulations-message-above-80': 'You did an amazing job !!!',
    'congragulations-message-above-60': 'Well done ! Keep striving for excellence.',
    'congragulations-message-below-60': `Good effort on your recent performance ! Remember, practice makes perfect!`,
    'congragulations-message': 'Good job !',
    'next': 'Next',
    'replay': 'Replay',
    'or-lowercase': 'or',
    'create-account': `Create an account`,
    'message-to-signin': 'Sign in to create, share and practice etudes.',
    'no-subscribed-etude': 'There is no subscribed etude yet.',
    'no-created-etude': 'There is no etude created yet.',
    'created-first-etude': 'Create your first etude',
}