import { ReactNode } from "react";
import { EditableEtude as GenericEditableEtude, EditableEtudeStyle } from "../component/editable-etude";
import { useLocalisationContext } from "../context/localisation";
import { useTheme } from "../context/theme";

interface EditableExerciseProps {
    id: string
    title: string
    description: string
    /**
     * React node that represents the preview image
     * @deprecated
     * @todo Remove the previewImageElement after preview images are stored as image files
     */
    previewImageElement?: ReactNode
    previewImageSource?: string
    onChange?: (value: { id: string, title: string, description: string }) => void
    onDelete?: (id: string) => void
}

export function EditableExercise (props: EditableExerciseProps) {
    const {
        id,
        title,
        description,
        previewImageSource,
        previewImageElement,
        onChange,
        onDelete
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const style: EditableEtudeStyle = {
        backgroundColor: colors.primaryColor.shade1,
        borderColor: colors.primaryColor.shade2,
        labelColor: colors.primaryColor.shade5,
        textColor: colors.primaryColor.shade6
    }

    const changeExercise = (value: { title: string, description: string }) => {
        onChange && onChange({
            id,
            ...value
        })
    }

    const deleteExercise = () => {
        onDelete && onDelete(id)
    }

    return (
        <GenericEditableEtude
            title={title}
            description={description}
            titleLabel={localisationStrings['title']}
            descriptionLabel={localisationStrings['description']}
            previewImageSource={previewImageSource}
            previewImageElement={previewImageElement}
            style={style}
            onChange={changeExercise}
            onDelete={deleteExercise}
        />
    )
}