import { createContext, PropsWithChildren, useContext, useState } from "react"

export type LocalisationInterpolationParamValue = string | number
export type LocalisationInterpolationParamKey = string

export type LocalisationInterpolationParam = {
    [key: LocalisationInterpolationParamKey]: LocalisationInterpolationParamValue
}

type Dictionary = {
    [key: string]: string
}

interface LocalisationContext <TLanguage extends string, TDictionary extends Dictionary> {
    language: TLanguage
    localisationStrings: TDictionary
    selectLanguage (lang: TLanguage): void
    interpolate (key: keyof TDictionary | undefined, params: LocalisationInterpolationParam | undefined): string
}

const localisationContext = createContext<LocalisationContext<string, Dictionary>> ({
    language: '',
    localisationStrings: {},
    selectLanguage: () => undefined,
    interpolate: () => ''
})

export const useLocalisationContext = <TLanguage extends string, TDictionary extends Dictionary> () => useContext(localisationContext) as LocalisationContext<TLanguage, TDictionary>

export const useLocalisationDictionary = <TDictionary extends Dictionary> () => {
    const context = useLocalisationContext()
    return context.localisationStrings as TDictionary
}

export const useLocalisationString = <TDictionary extends Dictionary> (key: keyof TDictionary) => {
    const dictionary = useLocalisationDictionary <TDictionary> ()
    return dictionary[key]
}

interface LocalisationProviderProps <TLanguage extends string, TDictionary extends Dictionary> {
    defaultLanguage: TLanguage
    strings: {
        [lang in TLanguage]: TDictionary
    }
}

export function LocalisationProvider <TLanguage extends string, TDictionary extends Dictionary> (props: PropsWithChildren<LocalisationProviderProps<TLanguage, TDictionary>>) {
    const { defaultLanguage, strings, children } = props
    const [language, selectLanguage] = useState (defaultLanguage)
    const localisationStrings = strings[language]

    const contextValue: LocalisationContext <TLanguage, TDictionary> = {
        language,
        localisationStrings,
        selectLanguage,
        interpolate: function (key: keyof TDictionary | undefined, params: LocalisationInterpolationParam | undefined ): string {
            if (!key) return ''
            let str: string = strings[language][key]
            if (!params) return str || ''            
            for (const param in params) {
                str = str.replaceAll(`{${param}}`, `${params[param]}`)
            }
            return str
        }
    }

    return (
        <localisationContext.Provider value={contextValue}>
            {children}
        </localisationContext.Provider>
    )
}