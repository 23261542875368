import { useEffect, useState } from "react"
import { Auto, AutoGap } from "../../component/auto-layout"
import { LocalisedText, NonlocalisedText } from "../../hoc/text"
import { useServices } from "../../context/services"
import { Etude } from "../../types"
import ExerciseDetails from "./exercise-details"
import { SubscribeButton } from "../../hoc/subscribe-button"
import { useNavigate } from "react-router"
import { Hidden } from "../../component/grid-layout"
import { useAuth } from "../../auth"
import { AuthenticatedAction } from "../../hoc/authenticated-action"
import { css } from "@emotion/css"
import { mediaQuery } from "../../util/device-detection"
import { PreviewSheetMusic } from "../../hoc/sheet-music"
import { useAppDispatch } from "../../store"

interface EtudeDetailsProps {
    id: string
}

export function EtudeDetails (props: EtudeDetailsProps) {
    const { id } = props
    const auth = useAuth()
    const dispatch = useAppDispatch ()
    const [subscribed, setSubscribed] = useState <boolean | undefined> (auth.isAuthenticated ? undefined : false)
    const [etude, setEtude] = useState <Etude> ()
    const { config, practiceAndHistoryClient } = useServices()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(async () => {
            const result = await practiceAndHistoryClient.getEtude(id)
            setEtude(result)
        })
    }, [id])

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(async () => {
                const result = await practiceAndHistoryClient.getSubscriptionStatus(id)
                setSubscribed(result.subscribed)
            })
        }
    }, [id, auth.isAuthenticated])
    
    if (!etude) {
        return <></>
    }

    const subscribe = async () => {
        dispatch(async () => {
            await practiceAndHistoryClient.subscribe(id)
            setSubscribed(true)
        })
    }

    const unsubscribe = async () => {
        dispatch(async () => {
            await practiceAndHistoryClient.unsubscribe(id)
            setSubscribed(false)
        })
    }

    const play = (exerciseId: string) => {
        navigate (`/play/${id}/${exerciseId}`)
    }

    return (
        <Auto>
            <Hidden sm andSmaller>
                <Auto width={300} className={css({
                    marginBottom: '8px',

                    [mediaQuery.phone()]: {
                        display: 'none'
                    }
                })}>
                    <PreviewSheetMusic source={`${config.cdn.baseUrl}/images/preview-image/${id}`} />
                </Auto>
            </Hidden>
            <NonlocalisedText text={etude.title} variant="h1" fontWeight={600}></NonlocalisedText>
            {subscribed !== undefined && (
                <AuthenticatedAction
                    messageKey="sign-in-to-subscribe"
                >
                    <AutoGap size={8} />
                    <SubscribeButton subscribed={subscribed} onSubscribe={subscribe} onUnsubscribe={unsubscribe}></SubscribeButton>
                </AuthenticatedAction>
            )}
            <AutoGap size={16} />
            <NonlocalisedText text={etude.description}></NonlocalisedText>
            <AutoGap size={24} />

            {Boolean(etude.exercises && etude.exercises.length) && (
                <>
                    <LocalisedText textKey='exercises' variant="h3" fontWeight={600}></LocalisedText>    
                    {etude.exercises.map(exercise => (
                        <ExerciseDetails key={`exercise-${exercise.id}`} id={exercise.id} title={exercise.title} description={exercise.description} onPlayClick={play} />
                    ))}
                </>
            )}
        </Auto>
    )
}