import { PropsWithChildren, useEffect, useState } from "react";
import { AutoFill, AutoHug } from "../component/auto-layout";
import { device, DeviceScreenOrientation } from "../util/device-detection";
import deviceOptions from "../util/device-options";
import { Button } from "./button";
import { Icon } from "./icon";
import { Overlay, OverlayStyle } from "../component/overlay";
import { LocalisedText } from "./text";
import { useTheme } from "../context/theme";

interface OrientationEnforcerProps {
    orientation: DeviceScreenOrientation
    phone?: true
    showCancelOption?: boolean
}

export function OrientationEnforcer (props: PropsWithChildren<OrientationEnforcerProps>) {
    const {
        orientation,
        phone,
        showCancelOption,
        children
    } = props
    const { colors } = useTheme()
    const [cancelEnforcement, setCancelEnforcement] = useState (deviceOptions.get('cancelOrientationEnforcement'))
    const [deviceOrientation, setDeviceOrientation] = useState (device.orientation)

    const changeDeviceOrientation = () => {
        setDeviceOrientation(device.orientation)
    }

    const usePortrait = () => {
        deviceOptions.set('cancelOrientationEnforcement', true)
        setCancelEnforcement (true)
    }

    const style: OverlayStyle = {
        color: colors.primaryColor.shade1
    }

    useEffect(() => {
        window.matchMedia('(orientation: portrait)').addEventListener('change', changeDeviceOrientation)

        return () => {
            window.matchMedia('(orientation: portrait)').removeEventListener('change', changeDeviceOrientation)
        }
    })

    return (
        <>
            {cancelEnforcement || deviceOrientation === undefined || deviceOrientation === orientation || (phone && !device.phone) ? (
                <>
                    {children}
                </>
            ) : (
                <Overlay open zIndex={1} style={style}>
                    <AutoFill paddingRight={32} paddingLeft={32} spacing={16} verticalAlignment='center' horizontalAlignment="center">
                        <Icon icon="landscape-orientation" size={5}></Icon>
                        <LocalisedText textKey="message-to-switch-to-landscape" fontSize={1.2} />
                        <AutoHug paddingTop={32}>
                            {showCancelOption && (
                                <Button variant="inverted" textKey="cancel-switch-to-landscape" onClick={usePortrait} />
                            )}
                        </AutoHug>
                    </AutoFill>
                </Overlay>
            )}
        </>
    )
}