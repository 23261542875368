import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function Paragraph (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    return (
        <p className={className} onClick={onClick} title={title}>{children}</p>
    )
}