import { EventEmitter } from "./event-emitter"

export type ClockState = 'idle' | 'running' | 'paused'

type ClockEvents = {
    change: () => void
    changeState: (state: ClockState) => void
    end: () => void
}

interface ClockOptions {
    bpm: number
    repetition: number
    beatsPerRepetition: number
    waitTimeInMS?: number
    numberOfPreBeats?: number
}

export class Clock extends EventEmitter<ClockEvents> {
    private bpm: number
    private bpmInMs: number
    private bpmTimestamp: number

    private repetition: number
    private beatsPerRepetition: number
    private waitTimeInMS: number
    private numberOfPreBeats: number

    private previousBpms: {
        bpm: number
        bpmInMs: number
        bpmTimestamp: number
    }[]

    private cacheOfCurrent: { beat: number }
    private cacheOfGetBeat: { beat: number, index: number, timestamp: number }
    private state: ClockState
    private pauseTime: number

    private endTime: number
    private endTimeTimeout?: NodeJS.Timeout

    constructor (options: ClockOptions) {
        super ()

        this.bpm = options.bpm
        this.bpmInMs = 60000 / options.bpm
        this.repetition = options.repetition
        this.beatsPerRepetition = options.beatsPerRepetition
        this.waitTimeInMS = options.waitTimeInMS ?? 1000
        this.numberOfPreBeats = options.numberOfPreBeats ?? 4
        this.previousBpms = []
        this.state = 'idle'
    }

    start (originTimestamp?: number) {
        if (this.state === 'idle') {
            this.state = 'running'
            this.bpmTimestamp = (originTimestamp ?? performance.now()) + this.waitTimeInMS
            this.previousBpms = []
            this.cacheOfCurrent = {
                beat: 0
            }
            this.cacheOfGetBeat = {
                beat: -this.numberOfPreBeats,
                index: 0,
                timestamp: this.bpmTimestamp
            }
            this.trigger('changeState', this.state)
        } else if (this.state === 'paused') {
            this.state = 'running'
            this.bpmTimestamp += performance.now() - this.pauseTime
            this.trigger('changeState', this.state)
        }

        this.setEndTime()
    }

    pause () {
        if (this.state === 'running') {
            this.state = 'paused'
            this.pauseTime = performance.now ()
            this.trigger('changeState', this.state)
        }

        this.setEndTime()
    }

    stop () {
        if (this.state !== 'idle') {
            this.state = 'idle'
            this.trigger('changeState', this.state)
        }

        this.setEndTime()
    }

    setBpm (bpm: number) {
        const previous = {
            bpm: this.bpm,
            bpmInMs: this.bpmInMs,
            bpmTimestamp: this.bpmTimestamp
        }
        this.bpm = bpm
        this.bpmInMs = 60000 / bpm
        this.previousBpms.push(previous)

        if (this.state !== 'idle') {
            const now = this.state === 'paused' ? this.pauseTime : performance.now()

            this.bpmTimestamp = now <= this.bpmTimestamp ? this.bpmTimestamp : now
            this.cacheOfCurrent.beat += (this.bpmTimestamp - previous.bpmTimestamp) / previous.bpmInMs
            this.trigger ('change', null)
        }

        this.setEndTime ()
    }

    setRepetition (repetition: number) {
        this.repetition = repetition

        if (this.state !== 'idle') {
            this.trigger ('change', null)
        }

        this.setEndTime()
    }

    getBeat (timestamp: number): number | undefined {
        if (this.state !== 'running') return undefined

        const cache = this.cacheOfGetBeat

        if (timestamp < cache.timestamp) {
            cache.beat = -this.numberOfPreBeats,
            cache.index = 0
            cache.timestamp = this.previousBpms[0].bpmTimestamp
        }

        for (; cache.index < this.previousBpms.length; cache.index ++) {
            const currentBpm = this.previousBpms[cache.index]
            const nextBpmTimestamp = this.previousBpms[cache.index + 1]?.bpmTimestamp ?? this.bpmTimestamp

            if (timestamp < nextBpmTimestamp) {
                return cache.beat + (timestamp - currentBpm.bpmTimestamp) / currentBpm.bpmInMs
            }

            cache.beat += (nextBpmTimestamp - currentBpm.bpmTimestamp) / currentBpm.bpmInMs
            cache.timestamp = nextBpmTimestamp
        }

        return (cache.beat + (timestamp - this.bpmTimestamp) / this.bpmInMs)
    }

    get currentState () {
        return this.state
    }

    get current (): { beat: number, totalBeat: number, timestamp: number } | undefined {
        if (this.state === 'idle') return undefined
        const now = this.state === 'paused' ? this.pauseTime : performance.now()
        if (now < this.bpmTimestamp) return {
            beat: Number.NaN,
            totalBeat: Number.NaN,
            timestamp: now
        }

        const totalBeats = this.beatsPerRepetition * this.repetition
        const currentBeat = this.cacheOfCurrent.beat + ((now - this.bpmTimestamp) / this.bpmInMs) - this.numberOfPreBeats
        return currentBeat < totalBeats ? {
            beat: currentBeat % this.beatsPerRepetition,
            totalBeat: currentBeat,
            timestamp: now
        } : undefined
    }

    get beats () {
        if (this.state === 'idle') return undefined
        if (this.state === 'paused') return []

        const result: number[] = []
        const totalBeats = this.beatsPerRepetition * this.repetition + this.numberOfPreBeats
        const currentBeat = this.cacheOfCurrent.beat + ((performance.now () - this.bpmTimestamp) / this.bpmInMs)
        const nextBeat = currentBeat < 0 ? 0 : Math.floor (currentBeat) + 1

        let nextBeatTimestamp = this.bpmTimestamp + (nextBeat - this.cacheOfCurrent.beat) * this.bpmInMs
        for (let i = nextBeat; i < totalBeats; i++) {
            result.push(nextBeatTimestamp)
            nextBeatTimestamp += this.bpmInMs
        }

        return result
    }

    private end () {
        this.stop()
        this.trigger('end', undefined)
    }

    private setEndTime () {
        clearTimeout (this.endTimeTimeout)
        if (this.state === 'running') {
            this.endTime = this.bpmTimestamp + (this.beatsPerRepetition * this.repetition + this.numberOfPreBeats - this.cacheOfCurrent.beat) * this.bpmInMs
            const now = performance.now ()
            if (this.endTime < now) {
                this.end()
            } else {
                this.endTimeTimeout = setTimeout (this.end.bind(this), this.endTime - performance.now())
            }
        } else {
            this.endTimeTimeout = undefined
        }
    }
}
