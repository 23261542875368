export const height = {
    header: 56,
    playerHeader: 100,
    bottomMenu: 56,
    playerControlPanel: 100
}

export const width = {
    leftMenu: 240,
    playerExerciseList: 340,
    pageMaxWidth: 1440
}
