interface MetronomeOptions {
    volume: number
}

export class Metronome {
    private audioContext: AudioContext
    private oscillatorNode?: OscillatorNode
    private gainNode?: GainNode
    private volumneNode?: GainNode
    private volume: number

    constructor (options: MetronomeOptions) {
        this.volume = options.volume
    }

    setVolume (volume: number) {
        this.volume = volume

        if (this.volumneNode) {
            this.volumneNode.gain.setValueAtTime(volume, 0)
        }
    }

    setAudioContext (audioContext: AudioContext) {
        this.audioContext = audioContext
    }

    schedule (timestamp: number) {
        if (!this.oscillatorNode) {
            this.oscillatorNode = this.audioContext.createOscillator()
            this.oscillatorNode.frequency.value = 800
        }
        if (!this.volumneNode) {
            this.volumneNode = this.audioContext.createGain()
            this.volumneNode.gain.value = this.volume
        }
        if (!this.gainNode) {
            this.gainNode = this.audioContext.createGain()
            this.gainNode.gain.value = 0

            this.oscillatorNode.connect(this.volumneNode).connect(this.gainNode).connect(this.audioContext.destination)
            this.oscillatorNode.start()
        }

        const currentTime = this.audioContext.currentTime
        const timeDiff = (timestamp - performance.now()) / 1000

        if (timeDiff >= 0.1) {
            const time = currentTime + timeDiff

            this.oscillatorNode.frequency.setValueAtTime(800, time)

            this.gainNode.gain.setValueAtTime(0, time)
            this.gainNode.gain.exponentialRampToValueAtTime(1, time + 0.001)
            this.gainNode.gain.exponentialRampToValueAtTime(0.0001, time + 0.019)
            this.gainNode.gain.setValueAtTime(0, time + 0.02)
        }
    }

    clear () {
        if (this.gainNode) {
            this.gainNode.gain.cancelScheduledValues(this.audioContext.currentTime)
        }
    }

    stop () {
        this.clear ()
        
        if (this.oscillatorNode) {
            this.oscillatorNode.stop ()
            this.oscillatorNode.disconnect ()
        }
        if (this.gainNode) {
            this.gainNode.disconnect ()
        }

        delete this.oscillatorNode
        delete this.gainNode
    }
}
