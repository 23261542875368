import React from 'react'
import { createRoot } from 'react-dom/client'
import { InputDeviceProvider } from '@mom/ui'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import config from './config'
import createStore from './store';
import { Provider } from 'react-redux'
import { Services } from './types'
import { AuthProvider, LocalStorageTokenAcquirer } from './auth'
import { BrowserRouter } from 'react-router-dom'
import { PracticeAndHistoryClient } from './http/practice-and-history-client'
import { ServicesProvider } from './context/services'
import { AuthClient, MusicDataClient, RecommendationsClient } from './http'
import { NativeWSClient } from './websocket'
import LoadingFeedback from './hoc/loading-feedback'
import ErrorFeedback from './hoc/error-feedback'
import { LocalisationProvider } from './localisation'
import { MasterPage } from './hoc/master-page'
import { ThemeProvider } from './context/theme'
import { AudioProvider, InstrumentContextProvider } from './context/audio'
import { PlayerProvider } from './context/player'

config.load().then(async (cfg) => {
  const tokenAcquirer = new LocalStorageTokenAcquirer({ authServiceTokenUrl: `${cfg.api.baseUrl}/auth/token` })
  const authClient = new AuthClient({ baseUrl: `${cfg.api.baseUrl}/auth`, tokenAcquirer })
  const webSocketClient = new NativeWSClient({ endpoint: cfg.ws.endpoint, tokenExchangeHandler: async () => {
    const result = await authClient.getWebsocketToken()
    return result.token
  }})


  const connectWebsocket = async () => {
    await webSocketClient.connect()
  }

  const services: Services = {
    config: cfg, 
    webSocketClient,
    practiceAndHistoryClient: new PracticeAndHistoryClient({ baseUrl: `${cfg.api.baseUrl}/practice-and-history`, tokenAcquirer }),
    musicDataClient: new MusicDataClient({ baseUrl: `${cfg.api.baseUrl}/music-data`, tokenAcquirer }),
    recommendationsClient: new RecommendationsClient({ baseUrl: `${cfg.api.baseUrl}/recommendations`, tokenAcquirer })
  }

  const store = await createStore ({ services })

  const root = document.getElementById('root')
  if (root) {
    const reactRoot = createRoot(root)

    reactRoot.render(
      <React.StrictMode>
        <Provider store={store}>
          <LocalisationProvider>
            <ThemeProvider>
              <AuthProvider cognito={cfg.cognito} authServiceBaseUrl={cfg.api.baseUrl} onLogin={connectWebsocket}>
                  <ServicesProvider services={services}>
                    <AudioProvider>
                      <InstrumentContextProvider>
                        <InputDeviceProvider>
                          <PlayerProvider>
                            <BrowserRouter>
                              <MasterPage>
                                <App />
                              </MasterPage>
                            </BrowserRouter>
                          </PlayerProvider>
                        </InputDeviceProvider>
                      </InstrumentContextProvider>
                    </AudioProvider>
                  </ServicesProvider>
              </AuthProvider>
              <LoadingFeedback />
              <ErrorFeedback />
            </ThemeProvider>
          </LocalisationProvider>
        </Provider>
      </React.StrictMode>
    )
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
