import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

interface PlayerSettingsControl {
    setTempo (tempo?: number): void
    setRepetition (repetition?: number): void
    setNoteTracker (noteTracker: boolean): void
    setFeedback (feedback: boolean): void
}

export const usePlayerSettingsControl = (id: string): PlayerSettingsControl => {
    const { playerSettings } = usePlayer (id)
    return playerSettings
}

export const useTempo = (id: string) => {
    const { playerSettings } = usePlayer (id)
    const [tempo, setTempo] = useState (playerSettings.tempo) 

    useEffect (() => {
        playerSettings.on('changeTempo', setTempo)
        return () => {
            playerSettings.off('changeTempo', setTempo)
        }
    })

    return tempo
}

export const useRepetition = (id: string) => {
    const { playerSettings } = usePlayer (id)
    const [repetition, setRepetition] = useState (playerSettings.repetition) 

    useEffect (() => {
        playerSettings.on('changeRepetition', setRepetition)
        return () => {
            playerSettings.off('changeRepetition', setRepetition)
        }
    })

    return repetition
}

export const useNoteTracker = (id: string) => {
    const { playerSettings } = usePlayer (id)
    const [noteTracker, setNoteTracker] = useState (playerSettings.noteTracker) 

    useEffect (() => {
        playerSettings.on('changeNoteTracker', setNoteTracker)
        return () => {
            playerSettings.off('changeNoteTracker', setNoteTracker)
        }
    })

    return noteTracker
}

export const useFeedback = (id: string) => {
    const { playerSettings } = usePlayer (id) || {}
    const [feedback, setFeedback] = useState (playerSettings.feedback) 

    useEffect (() => {
        playerSettings.on('changeFeedback', setFeedback)
        return () => {
            playerSettings.off('changeFeedback', setFeedback)
        }
    })

    return feedback
}