import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react"
import { Icon } from "./icon"

const Container = styled.div({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '4px'
})

const InnerContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 16px',
    gap: '12px',
    alignItems: 'flex-start'
})

const Background = styled.div({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: '0.1'
})

const Content = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    alignSelf: 'stretch',
    justifyContent: 'center'
})

const Title = styled.div({
    fontWeight: 'bold',
    marginBottom: '4px'
})

const CloseButtonContainer = styled.div({
    flex: '0 1 0',
    cursor: 'pointer'
})

const iconMapping = {
    'success': 'success' as const,
    'info': 'info' as const,
    'warning': 'warning' as const,
    'error': 'alert' as const
}

const colorMapping = {
    'success': '#3BB139',
    'info': '#1F7ED7',
    'warning': '#EDA222',
    'error': '#D66060'
}

const defaultType = 'success'
const animationDuration = 200

type CloseAnimationRef = {
    state: 'idle' | 'started' | 'ended'
    startTime: number
    timeout?: NodeJS.Timeout
    closeEventStart?: number
    closeEventTimeout?: NodeJS.Timeout
}

const useCloseAnimation = (timeout?: number, onClose?: Function) => {
    const animationRef = useRef <CloseAnimationRef> ({ state: 'idle', startTime: Date.now() })
    const [animate, setAnimate] = useState(false)

    const startClosing = () => {
        if (animationRef.current.state === 'idle') {
            clearTimeout(animationRef.current.timeout)

            animationRef.current.state = 'started'
            animationRef.current.closeEventStart = Date.now()
            animationRef.current.closeEventTimeout = setTimeout (() => {
                close ()
            }, animationDuration)

            setAnimate(true)
        }
    }

    const close = () => {
        animationRef.current.state === 'ended'
        onClose && onClose()
    }

    useEffect (() => {
        if (typeof timeout === 'number' && animationRef.current.state === 'idle') {
            clearTimeout(animationRef.current.timeout)
            const normalizedTimeout = timeout < 0 ? 0 : timeout
            const remainingTimeout = normalizedTimeout + animationRef.current.startTime - Date.now()
            
            if (remainingTimeout <= 0) {
                startClosing ()
            } else {
                animationRef.current.timeout = setTimeout(() => {
                    startClosing()
                }, remainingTimeout)
            }
        }

        return () => {
            clearTimeout(animationRef.current.timeout)
        }
    })

    useEffect(() => {
        if (animationRef.current.state === 'started') {
            clearTimeout(animationRef.current.closeEventTimeout)
            const remainingTimeout = animationRef.current.closeEventStart ? animationDuration + animationRef.current.closeEventStart - Date.now() : 0
            
            if (remainingTimeout <= 0) {
                close ()
            } else {
                animationRef.current.timeout = setTimeout(() => {
                    close ()
                }, remainingTimeout)
            }
        }
        return () => {
            clearTimeout(animationRef.current.closeEventTimeout)
        }
    })

    return {
        animate,
        close: startClosing
    }
}

export interface MessageStyle {
    backgroundColor?: string
}

interface MessageProps {
    type?: 'success' | 'info' | 'warning' | 'error'
    hideIcon?: boolean
    title?: string
    text?: string
    timeout?: number
    style?: MessageStyle
    onClose?: () => void
}

export function Message (props: PropsWithChildren<MessageProps>) {
    const {
        type,
        hideIcon,
        title,
        text,
        timeout,
        style,
        onClose,
        children
    } = props
    const typeColor = colorMapping[type ?? defaultType]
    const { animate, close } = useCloseAnimation (timeout, onClose)

    const iconStyle = {
        stroke: typeColor
    }

    const onCloseClick = () => {
        close()
    }

    return (
        <Container className={css({
            color: typeColor,
            backgroundColor: style?.backgroundColor,
            border: `1px solid ${typeColor}`,
            transition: `opacity ${animationDuration}ms cubic-bezier(0, 0, 0.2, 1)`,
            opacity: animate ? 0 : 1
        })}>
            <InnerContainer>
                <Background className={css({
                    backgroundColor: typeColor
                })} />
                {!hideIcon && (
                    <Icon icon={iconMapping[type ?? defaultType]} style={iconStyle} />
                )}
                <Content>
                    {title && (
                        <Title>{title}</Title>
                    )}
                    {text ?? children}
                </Content>
                <CloseButtonContainer onClick={onCloseClick}>
                    <Icon icon='close' style={iconStyle} />
                </CloseButtonContainer>
            </InnerContainer>
        </Container>
    )
}