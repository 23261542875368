import { Colors, SecondaryColorType, PrimaryColorShade, PrimaryColor, SecondaryColorShade } from "./types"

export function convertToKebabCase (text: string) {
    return text.replaceAll(/[A-Z]/g, (match) => `-${match.toLowerCase()}`)
}

export function getVariableName (colorKey: keyof Colors, variantKey: PrimaryColorShade | SecondaryColorShade) {
    return `--${convertToKebabCase(colorKey)}--${convertToKebabCase(variantKey)}`
}

export function extractContextColorVariables (): Colors {
    const variables: any = {}
    const colors: { [colorKey in keyof Colors]: {[key in keyof Colors[colorKey]]: true} } = {
        primaryColor: {
            shade1: true,
            shade2: true,
            shade3: true,
            shade4: true,
            shade5: true,
            shade6: true
        },
        secondaryColor: {
            shade1: true
        }
    }
    for (const [colorKey, color] of Object.entries(colors)) {
        variables[colorKey] = {}
        for (const [variantKey, _] of Object.entries<true>(color)) {
            variables[colorKey][variantKey] = `var(${getVariableName(<any>colorKey, <any>variantKey)})`
        }
    }
    return variables
}

export function extractCSSVariables <T extends keyof Colors> (type: T, colors: Colors[T]) {
    const variables: { [key: string]: string } = {}
    for (const [shadeKey, color] of Object.entries(colors)) {
        variables[getVariableName(type, <any>shadeKey)] = color
    }
    return variables
}

export function getSystemDefaultTheme () {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}