import axios, { AxiosInstance } from "axios"

export interface TokenAcquirer {
    getAccessToken () : Promise<string | undefined>
    acquireAccessToken () : Promise<string | undefined>
}

interface LocalStorageTokenAcquirerOptions {
    authServiceTokenUrl: string
}

export class LocalStorageTokenAcquirer implements TokenAcquirer {
    private client: AxiosInstance

    constructor (options: LocalStorageTokenAcquirerOptions) {
        this.client = axios.create({
            baseURL: options.authServiceTokenUrl
        })
    }

    async getAccessToken(): Promise<string | undefined> {
        return localStorage.getItem('mom-auth-access-token') ?? undefined
    }

    async acquireAccessToken(): Promise<string | undefined> {
        const refreshToken = localStorage.getItem('mom-auth-refresh-token')

        if (!refreshToken) {
            return
        }

        const response = await this.client.post('', { refreshToken })

        if (response.status === 200) {
            const responseObject = response.data
            localStorage.setItem('mom-auth-access-token', responseObject.accessToken)
            localStorage.setItem('mom-auth-refresh-token', responseObject.refreshToken)

            return responseObject.accessToken
        }
    }
}