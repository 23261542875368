import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function H1 (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    return (
        <h1 className={className} onClick={onClick} title={title}>{children}</h1>
    )
}