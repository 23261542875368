import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { Auto, AutoHug } from "../auto-layout"
import { Text } from "../text"
import { mediaQuery } from "../../util/device-detection"
import { ProfileMenuItemDetails, ProfileMenuStyle } from "./types"
import { ProfileMenuItem } from "./item"
import { Icon } from "../icon"

export type { ProfileMenuStyle, ProfileMenuItemDetails } from "./types"

function checkIfParent (child: Element, parent: Element) {
    let node = child
    for (; node.parentElement && node !== parent; node = node.parentElement) {}
    return node === parent
}

interface ProfileMenuProps {
    user: {
        name: string
        email: string
    }
    items: ProfileMenuItemDetails[]
    style?: ProfileMenuStyle
    className?: string
    onClick?: (item: ProfileMenuItemDetails) => void
    onClose?: () => void
}

export function ProfileMenu (props: ProfileMenuProps) {
    const {
        user,
        items,
        style,
        className,
        onClick,
        onClose
    } = props

    const preventMouseEvent = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const onItemClick = (item: ProfileMenuItemDetails) => {
        onClick && onClick(item)
    }

    const classNames = [
        className,
        css({
            width: '300px',
            borderRadius: '12px',
            border: `1px solid ${style?.borderColor}`,
            boxShadow: `0px 0px 20px ${style?.borderColor}`,
            backgroundColor: style?.backgroundColor,

            [mediaQuery.phone()]: {
                position: 'fixed',
                width: '100%',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0
            }
        })
    ].filter(x => x)

    return (
        <AutoHug className={classNames.join(' ')} onClick={preventMouseEvent}>
            <Auto direction="horizontal" className={css({
                padding: '16px 16px',
                display: 'none',

                [mediaQuery.phone()]: {
                    display: 'flex'
                }
            })}>
                <Auto></Auto>
                <Icon icon="close" onClick={onClose} style={{ stroke: style?.textColor }} className={css({
                    flex: '0 1 24px'
                })}></Icon>
            </Auto>
            <Auto padding='16px 0' horizontalAlignment="center" spacing={8} className={css({
                borderBottom: `1px solid ${style?.borderColor ?? ''}`
            })}>
                <svg width="64" height="64" viewBox="0 0 32 32" fill="none">
                    <circle cx="16" cy="16" r="16" fill={style?.profileColor} stroke="none"/>
                    <text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" stroke="#fdfdfd" fill="#fdfdfd">{user.name[0].toUpperCase()}</text>
                </svg>
                <Text text={user.name} className={css({
                    paddingTop: '8px'
                })} />
                <Text text={user.email} />
            </Auto>
            <Auto padding={16}>
                {items.map(item => (
                    <ProfileMenuItem key={`profile-menu-item-${item.id}`}onClick={() => onItemClick(item)}>
                        {item.icon && (
                            <Icon icon={item.icon} style={{ stroke: style?.textColor }} />
                        )}
                        <Text fontSize={1} text={item.text} />
                    </ProfileMenuItem>
                ))}
            </Auto>
        </AutoHug>
    )
}
