import { ProgressBar as GenericProgressBar, ProgressBarStyle } from '../component/progress-bar'
import { useTheme } from '../context/theme'

interface ProgressBarProps {
    value: number
}

export function ProgressBar (props: ProgressBarProps) {
    const {
        value
    } = props
    const { colors } = useTheme()

    const style: ProgressBarStyle = {
        color: colors.secondaryColor.shade1
    }

    return (
        <GenericProgressBar value={value} style={style} />
    )
}