import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Feedback {
    status: 'idle' | 'loading'
    messages: { message: string, severity: 'error' | 'success' }[]
}
  
const initialState: Feedback = {
    status: 'idle',
    messages: []
}

export default createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        loading(state) {
            state.status = 'loading'
        },
        idle(state) {
            state.status = 'idle'
        },
        logError(state, action: PayloadAction<string>) {
            const message = action.payload
            state.messages.push({ message, severity: 'error' })
        },
        logSuccess(state, action: PayloadAction<string>) {
            const message = action.payload
            state.messages.push({ message, severity: 'success' })
        },
        removeMessage(state, action: PayloadAction<number>) {
            const index = action.payload
            state.messages.splice(index, 1)
        }
    }
})
