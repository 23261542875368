import { NumericSlider as GenericNumericSlider, NumericSliderStyle } from "../component/numeric-slider"
import { useTheme } from "../context/theme"

interface NumericSliderProps {
    value: number
    min?: number
    max?: number
    labelPosition?: 'right' | 'none' | 'bottom'
    onChange?: (value: number) => void
}

export function NumericSlider (props: NumericSliderProps) {
    const {
        value,
        min,
        max,
        labelPosition,
        onChange
    } = props
    const { colors } = useTheme()

    const style: NumericSliderStyle = {
        color: colors.secondaryColor.shade1
    }

    return (
        <GenericNumericSlider value={value} min={min} max={max} labelPosition={labelPosition} onChange={onChange} style={style} />
    )
}