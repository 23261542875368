import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { useRef } from "react"
import { Chip } from "./chip"
import { Icon } from "./icon"

function appendFiles (current: File[] | undefined, selected: FileList | null) {
    const currentFileList = current ?? []
    if (selected) {
        return [
            ...currentFileList,
            ...Array.from(selected).filter (selectedFile => !currentFileList.find(currentFile => selectedFile.name === currentFile.name && selectedFile.size === currentFile.size))
        ]
    }
    return currentFileList
    
}

const Container = styled.div({
    borderRadius: '4px',
    padding: '8px 40px 8px 16px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    minHeight: 'calc(18px + 1.2rem)',
    display: 'flex',
    flexWrap: 'wrap'
})

const IconContainer = styled.div({
    position: 'absolute',
    right: 0,
    top: 0,
    height: 'calc(16px + 1.2rem)',
    padding: '0 8px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
})

const FileInput = styled.input({
    display: 'none'
})

const Label = styled.label({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    padding: '0 4px',
    marginLeft: '-4px',
    transition: 'margin 200ms cubic-bezier(0, 0, 0.2, 1), transform 200ms cubic-bezier(0, 0, 0.2, 1)'
})

export interface UploadStyle {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    labelColor?: string
}

interface UploadProps {
    files?: File[]
    multiple?: boolean
    label: string
    style?: UploadStyle
    className?: string
    onChange: (event: { files: File[], size: number }) => void
}

export function Upload (props: UploadProps) {
    const {
        files,
        multiple,
        label,
        className,
        style,
        onChange
    } = props
    const fileUpload = useRef<HTMLInputElement>(null)
    const hasFiles = !!(files && files.length)

    const classes = [
        className,
        css({
            border: `1px solid ${style?.borderColor}`
        })
    ].filter(x => x)

    const iconStyle = {
        stroke: style?.textColor
    }

    const change = () => {
        if (fileUpload.current) {
            const newFiles = appendFiles(files, fileUpload.current.files)
            fileUpload.current.value = null as any

            onChange({
                files: newFiles,
                size: newFiles.reduce((prev, curr) => prev + curr.size, 0)
            })
        }
    }

    const openFileSelect = () => {
        fileUpload.current?.click()
    }

    const deleteFile = (index: number) => {
        const filesCopy = files ? [...files] : []
        filesCopy.splice(index, 1)

        onChange({
            files: filesCopy,
            size: filesCopy.reduce((prev, curr) => prev + curr.size, 0)
        })
    }
    
    return (
        <Container className={classes.join(' ')}>
            {hasFiles && files.map((file, index) => (
                <Chip
                    key={`file-${index}`}
                    removable
                    onRemove={() => deleteFile(index)}
                    style={style}
                    className={css({
                        margin: '4px'
                    })}
                >
                    {file.name}
                </Chip>
            ))}
            <Label className={css({
                transform: hasFiles ? 'translate(0, -60%) scale(0.9)' : undefined,
                marginTop: hasFiles ? '-8px' : undefined,
                color: style?.labelColor,
                backgroundColor: hasFiles ? style?.backgroundColor : undefined
            })}>
                {label}
            </Label>
            <IconContainer onClick={openFileSelect}>
                <Icon icon="upload" style={iconStyle} />
            </IconContainer>
            <FileInput id="my-upload" ref={fileUpload} type="file" multiple={multiple} onChange={change} />
        </Container>
    )
}