import { Panel as GenericPanel, PanelStyle } from '../component/panel'
import { useTheme } from '../context/theme'
import { useLocalisationContext } from '../context/localisation'
import { PropsWithChildren } from 'react'

interface PanelProps {
    labelKey?: string
    height?: number | 'fill'
    className?: string
}

export function Panel (props: PropsWithChildren<PanelProps>) {
    const {
        labelKey,
        height,
        className,
        children
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()

    const style: PanelStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        labelColor: theme.colors.primaryColor.shade5
    }
    
    return (
        <GenericPanel label={labelKey && localisationStrings[labelKey]} className={className} style={style} height={height}>
            {children}
        </GenericPanel>
    )
}