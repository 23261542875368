import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { PropsWithChildren, useEffect } from "react"
import { useNavigate } from "react-router"
import { Col, Row } from "../../component/grid-layout"
import { constants } from "../../constants"
import { useServices } from "../../context/services"
import { useTheme } from "../../context/theme"
import { ExerciseCard as HOCExerciseCard } from "../../hoc/exercise-card"
import { Icon } from "../../hoc/icon"
import { LocalisedText } from "../../hoc/text"
import { asyncActions, useAppDispatch, useAppSelector } from "../../store"
import { device, mediaQuery } from "../../util/device-detection"

const ReferenceContainer = styled.div({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: `${constants.ui.width.pageMaxWidth}px`,
    height: 0,
    margin: '0 auto',
    zIndex: 3
})

const Container = styled.div({
    position: 'absolute',
    width: `${constants.ui.width.playerExerciseList}px`,
    height: `calc(100vh - ${constants.ui.height.header + constants.ui.height.playerHeader + 32}px)`,
    top: `${constants.ui.height.header + constants.ui.height.playerHeader + 16}px`,
    right: 0,
    display: 'flex',
    flexDirection:  'column',
    borderRadius: '12px',
    overflowY: 'auto',
    marginRight: '24px',

    [mediaQuery.phone()]: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: 'auto',
        height: 'auto',
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0
    }
})

const Header = styled.div({
    display: 'none',

    [mediaQuery.phone()]: {
        display: 'flex',
        flex: '0 1 0',
        padding: '16px 32px'
    }
})

const Content = styled.div({
    [mediaQuery.phone()]: {
        flex: '1 0 0',
        padding: '16px 32px 0',
        overflow: 'auto'
    }
})

interface ExerciseListProps {
    open: boolean
    closeExerciseList: () => void
}

export function ExerciseList (props: ExerciseListProps) {
    const {
        open,
        closeExerciseList
    } = props
    const navigate = useNavigate ()
    const dispatch = useAppDispatch ()
    const { colors } = useTheme ()
    const exercises = useAppSelector (x => x.exercise.subscribedExerciseDetails)

    const ExerciseRow = (props: PropsWithChildren<{}>) => device.phone ? <Row>{props.children}</Row> : <>{props.children}</>

    const ExerciseCol = (props: PropsWithChildren<{}>) => device.phone ? <Col all={12} sm={6} md={4}>{props.children}</Col> : <>{props.children}</>

    const play = (etudeId: string, exerciseId: string) => {
        navigate (`/play/${etudeId}/${exerciseId}`)
    }

    const preventEvent = (e: React.MouseEvent) => {
        //e.stopPropagation()
    }

    useEffect(() => {
        dispatch (asyncActions.exercise.loadExerciseList())
    }, [])

    useEffect(() => {
        document.addEventListener('click', closeExerciseList)

        return () => {
            document.removeEventListener('click', closeExerciseList)
        }
    })

    return (
        <>
            {open && (
                <ReferenceContainer onClick={preventEvent}>
                    <Container className={css({
                        backgroundColor: colors.primaryColor.shade1,
                        border: `1px solid ${colors.primaryColor.shade2}`
                    })}>
                        <Header className={css({
                            borderBottom: `1px solid ${colors.primaryColor.shade2}`
                        })}>
                            <LocalisedText fontSize={1.5} textKey="exercises" className={css({
                                flex: '1 0 0'
                            })} />
                            <Icon icon="close" onClick={closeExerciseList} className={css({
                                flex: '0 1 24px'
                            })}></Icon>
                        </Header>
                        <Content>
                            <ExerciseRow>
                                {exercises.map(exercise => (
                                    <ExerciseCol key={`exercise-${exercise.id}`}>
                                        <ExerciseCard exerciseId={exercise.id} title={exercise.etudeTitle} onPlay={() => play(exercise.etudeId, exercise.id)} />
                                    </ExerciseCol>
                                ))}
                            </ExerciseRow>
                        </Content>
                    </Container>
                </ReferenceContainer>
            )}
        </>
    )
}

interface ExerciseCardProps {
    exerciseId: string
    title: string
    onPlay: (id: string) => void
}

function ExerciseCard (props: ExerciseCardProps) {
    const {
        exerciseId,
        title,
        onPlay
    } = props
    const { config } = useServices()

    const play = () => {
        onPlay && onPlay(exerciseId)
    }

    return (
        <>
            <HOCExerciseCard
                title={title}
                hideTitleImage
                previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${exerciseId}`}
                onPlayClick={play}
            />
        </>
    )
}