import HttpClient, { HttpClientConfiguration } from "./client";

interface MusicDataClientOptions extends HttpClientConfiguration {
    
}

export class MusicDataClient {
    private httpClient: HttpClient
    constructor (options: MusicDataClientOptions) {
        this.httpClient = new HttpClient(options)
    }

    getUploadLinks (count: number) {
        return this.httpClient.get<string[]>(`/upload-links/${count}`)
    }

    async upload (files: File[], options?: { onUploadProgress?: (event: { percentage: number }) => void }) : Promise<{ id: string, file: File, success: boolean }[]> {
        const progress: number[] = []
        if (options?.onUploadProgress) {
            options.onUploadProgress({ percentage: 0 })
        }
        const uploadLinks = await this.httpClient.get<{ id: string, url: string, fields: { [key: string]: string } }[]>(`/upload-links/${files.length}`)
        if (options?.onUploadProgress) {
            options.onUploadProgress({ percentage: 1 / (files.length + 1) })
        }
        const result = await Promise.allSettled(
            uploadLinks.map((uploadLink, index) => {
                var form = new FormData()
                for (const key in uploadLink.fields) {
                    form.append(key, uploadLink.fields[key])
                }
                form.append('file', files[index])

                return this.httpClient.post(uploadLink.url, form, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    disableAuth: true,
                    onUploadProgress: (event) => {
                        if (options?.onUploadProgress) {
                            progress[index] = event.percentage
                            options.onUploadProgress ({
                                percentage: progress.reduce((prev, curr) => prev + (curr || 0), 1) / (files.length + 1)
                            })
                        }
                    }
                })
            })
        )
        return uploadLinks.map((link, index) => ({
            id: link.id,
            file: files[index],
            success: result[index].status === 'fulfilled'
        }))
    }

    async getMusicTimeline (fileId: string) {
        return this.httpClient.get <{ parts: any[] }> (`/music-timeline?id=${fileId}`)
    }
}