import React, { PropsWithChildren, ReactElement } from "react";
import gridStyles from './style/grid.module.scss'
import hiddenStyles from './style/hidden.module.scss'

interface RowProps {
    className?: string
}

export function Row (props: PropsWithChildren<RowProps>) {
    const {
        children,
        className
    } = props

    const classes = [
        gridStyles['row'],
        className
    ].filter(x => x)

    return (
        <div className={classes.join(' ')}>
            {children}
        </div>
    )
}

interface ColProps {
    all?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
    xxl?: number
    className?: string
}

export function Col (props: PropsWithChildren<ColProps>) {
    const {
        all,
        sm,
        md,
        lg,
        xl,
        xxl,
        children, 
        className
    } = props

    const classes = [
        gridStyles['col'],
        all && gridStyles[`col-${all}`],
        sm && gridStyles[`col-sm-${sm}`],
        md && gridStyles[`col-md-${md}`],
        lg && gridStyles[`col-lg-${lg}`],
        xl && gridStyles[`col-xl-${xl}`],
        xxl && gridStyles[`col-xxl-${xxl}`],
        className
    ].filter(x => x)
    
    return (
        <div className={classes.join(' ')}>
            {children}
        </div>
    )
}

interface HiddenProps {
    xs?: boolean
    sm?: boolean
    md?: boolean
    lg?: boolean
    xl?: boolean
    xxl?: boolean
    andSmaller?: boolean
    andBigger?: boolean
}

export function Hidden (props: PropsWithChildren<HiddenProps>) {
    const {
        xs,
        sm,
        md,
        lg,
        xl,
        xxl,
        andSmaller,
        andBigger,
        children
    } = props

    const classes = [
        hiddenStyles[`hidden`],
        xs && hiddenStyles[`hidden-xs`],
        sm && hiddenStyles[`hidden-sm`],
        md && hiddenStyles[`hidden-md`],
        lg && hiddenStyles[`hidden-lg`],
        xl && hiddenStyles[`hidden-xl`],
        xxl && hiddenStyles[`hidden-xxl`],
        andSmaller && hiddenStyles[`smaller`],
        andBigger && hiddenStyles[`bigger`],
    ]

    return (
        <>{React.Children.map(children as ReactElement[], (child: ReactElement) => React.cloneElement(child, { className: [child.props.className, ...classes].filter(x => x).join(' ') }))}</>
    )
}