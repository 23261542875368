import { PropsWithChildren } from 'react'
import { LocalisationProvider as LP, useLocalisationContext as uLC, useLocalisationDictionary as uLD, useLocalisationString as uLS } from '../context/localisation'
import en from './strings/en'

type Language = 'en'
type LocalisationStrings = typeof en

export const localisationStrings: { [lang in Language]: LocalisationStrings } = {
    en
}

export const useLocalisationContext = () => uLC<Language, LocalisationStrings>()

export const useLocalisationDictionary = () => uLD<LocalisationStrings>()

export const useLocalisationString = (key: keyof LocalisationStrings) => uLS<LocalisationStrings>(key)

export const LocalisationProvider = (props: PropsWithChildren<{}>) => <LP defaultLanguage='en' strings={localisationStrings}>{props.children}</LP>