import exercise from './exercise'
import feedback from './feedback'
import etudeEditor from './etude-editor'
import recommendation from './recommendation'

export const slices = {
    exercise,
    feedback,
    etudeEditor,
    recommendation
}

export type SlicesType = keyof typeof slices

export type ActionsType = { [key in SlicesType]: (typeof slices[key])['actions'] }

export type ReducersType = { [key in SlicesType]: (typeof slices[key])['reducer'] }

export type RootStateType = { [key in SlicesType]: ReturnType<(typeof slices[key])['getInitialState']> }

export const actions = Object.entries(slices).reduce<ActionsType>((prev, slice) => ({ ...prev, [slice[0]]: slice[1].actions }), {} as ActionsType)

export const reducers = Object.entries(slices).reduce<ReducersType>((prev, slice) => ({ ...prev, [slice[0]]: slice[1].reducer }), {} as ReducersType)