import { UserNoteToEvaluate } from "../../../types"
import { evaluateUserNotes } from "../../../util/exercise-result"
import { EventEmitter } from "./event-emitter"

type ResultEvents = {
    'change': (result: ExerciseResult | undefined) => void
}

interface ExerciseResult {
    enabled: boolean
    overall: number
    noting: number
    timing: number
}

interface ResultOptions {
    enabled: boolean
    timeline: any
    repetition: number
}

export class Result extends EventEmitter<ResultEvents> {
    result?: ExerciseResult
    notes: UserNoteToEvaluate[]
    enabled: boolean

    private timeline: any
    private repetition: number

    constructor (options: ResultOptions) {
        super ()

        this.timeline = options.timeline
        this.enabled = options.enabled
        this.repetition = options.repetition
        this.notes = []
    }

    setEnabled (enabled: boolean) {
        this.enabled = enabled
    }

    setRepetition (repetition: number) {
        this.repetition = repetition
    }

    addNote(note: UserNoteToEvaluate) {
        this.notes.push(note)
    }

    calculate () {
        const result = evaluateUserNotes(this.timeline, this.notes, this.repetition)
        this.result = {
            enabled: this.enabled,
            overall: result.overall,
            noting: result.noting,
            timing: result.timing
        }
        this.trigger('change', this.result)
    }

    reset () {
        this.notes = []
        this.result = undefined
        this.trigger('change', this.result)
    }
}