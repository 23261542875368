import React, { useEffect, useState, useId } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

const Label = styled.label({
  position: "absolute",
  left: "12px",
  top: "9px",
  padding: "0px 4px",
  transition: "transform 0.2s, margin 0.2s",
  fontSize: "1rem",
  lineHeight: "1.2rem",
});

const TextareaDiv = styled.div({
  position: "relative",
  width: "100%",
});

const StyledTextarea = styled.textarea({
  lineHeight: "1.2rem",
  fontSize: "1rem",
  padding: "8px 16px",
  minHeight: "calc(18px + 1.2rem)",
  borderRadius: "4px",
  boxSizing: "border-box",
  resize: "none",
  outline: "none",
  overflow: "hidden",
  fontFamily: "inherit",
  background: "transparent",
});

const InvisibleDivWrapper = styled.div({
  overflow: "hidden",
  width: "100%",
  height: "0",
});

const InvisibleDiv = styled.div({
  visibility: "hidden",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  padding: "8px 16px",
  border: "1px solid",
  lineHeight: "1.2rem",
  fontSize: "1rem",
});

export interface TextareaStyle {
  backgroundColor?: string;
  borderColor?: string;
  textColor?: string;
  labelColor?: string;
}

interface TextareaProps {
  className?: string;
  label?: string;
  text?: string;
  minRows?: number;
  style?: TextareaStyle;
  focused?: boolean;
  onChange?: (text: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

export function Textarea({
  className,
  label,
  text,
  minRows = 2,
  style,
  focused,
  onChange = () => {},
  onKeyDown
}: TextareaProps) {
  const [isFocused, setIsFocused] = useState(focused ?? false);

  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const divRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    autoResize();
  }, [text]);

  useEffect (() => {
    if (isFocused) {
      textareaRef.current?.focus()
    }
  })

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasContent = () => {
    return Boolean(text);
  };

  const handleChange = (val: string) => {
    onChange(val);
  };

  const autoResize = () => {
    if (textareaRef.current && divRef.current) {
      textareaRef.current.style.height = `${
        divRef.current.getBoundingClientRect().height
      }px`;
    }
  };

  const id = useId();

  const classes = [
    css({ backgroundColor: style?.backgroundColor ?? "inherit" }),
    className,
  ].filter((x) => x);

  return (
    <TextareaDiv className={classes.join(" ")}>
      {label && (
        <Label
          className={`${css({
            color: style?.labelColor ?? "inherit",
            backgroundColor: `${style?.backgroundColor ?? "inherit"}`,
            transform:
              isFocused || hasContent()
                ? "translate(0, -60%) scale(0.9)"
                : undefined,
            marginTop: isFocused || hasContent() ? "-8px" : undefined,
          })}`}
          htmlFor={`text-area-${id}`}
        >
          {label}
        </Label>
      )}
      <StyledTextarea
        id={`text-area-${id}`}
        className={css({
          border: `1px solid ${style?.borderColor ?? "inherit"}`,
          color: style?.textColor ?? "inherit",
          width: "100%",
        })}
        ref={textareaRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={onKeyDown}
        value={text}
      />
      <InvisibleDivWrapper>
        <InvisibleDiv
          ref={divRef}
          className={css({ minHeight: `calc(18px + ${1.2 * minRows}rem)` })}
        >
          {text
            ?.split("\n")
            .map((x) => x || " ")
            .join("\n")}
        </InvisibleDiv>
      </InvisibleDivWrapper>
    </TextareaDiv>
  );
}
