import { css } from "@emotion/css";
import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function Link (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    const classes = [
        className,
        css({
            color: '#1a73e8'
        })
    ].filter(x => x)

    return (
        <a className={classes.join(' ')} onClick={onClick} title={title}>{children}</a>
    )
}