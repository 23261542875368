import { InputDeviceProvider, InputDeviceNoteEvent, InputDeviceConnectionEvent } from "@mom/ui"
import { Instrument } from "../../audio"
import deviceOptions from "../../../util/device-options"
import { Clock } from "./clock"
import { Feedback } from "./feedback"
import { Metronome } from "./metronome"
import { NoteTracker } from "./note-tracker"
import { PlayerSettings } from "./player-settings"
import { RenderingDetails } from "./rendering-details"
import { SoundSettings } from "./sound-settings"
import { AudioProvider } from "./types"
import { EtudeExerciseDetails } from "../../../types"
import { Result } from "./result"

interface PlayerOptions {
    timeline: any
    audio: AudioProvider
    inputDevice: InputDeviceProvider
    instrument: Instrument
    exerciseDetails: EtudeExerciseDetails
}

export class Player {
    public clock: Clock
    public metronome: Metronome
    public playerSettings: PlayerSettings
    public soundSettings: SoundSettings
    public noteTracker: NoteTracker
    public renderingDetails: RenderingDetails
    public feedback: Feedback
    public exerciseDetails: EtudeExerciseDetails
    public result: Result

    private audio: AudioProvider
    private inputDevice: InputDeviceProvider
    private instrument: Instrument
    
    private noteStart?: (event: InputDeviceNoteEvent) => void
    private noteEnd?: (event: InputDeviceNoteEvent) => void
    private deviceConnected?: (event: InputDeviceConnectionEvent) => void

    constructor (options: PlayerOptions) {
        const {
            timeline,
            audio,
            inputDevice,
            instrument,
            exerciseDetails
        } = options

        this.audio = audio
        this.inputDevice = inputDevice
        this.instrument = instrument
        this.exerciseDetails = exerciseDetails

        const lastMeasure = options.timeline?.measures[options.timeline.measures.length - 1]
        const lastNoteGroup = lastMeasure?.noteGroups[lastMeasure.noteGroups.length - 1]

        this.playerSettings = new PlayerSettings({
            tempo: deviceOptions.get ('tempo'),
            repetition: deviceOptions.get ('repetition'),
            noteTracker: deviceOptions.get ('noteTracker'),
            feedback: deviceOptions.get ('feedback')
        })

        this.soundSettings = new SoundSettings({
            settings: deviceOptions.get('soundSettings')
        })

        this.metronome = new Metronome({
            volume: deviceOptions.get('soundSettings').metronome / 100
        })

        this.clock = new Clock({
            bpm: deviceOptions.get ('tempo'),
            repetition: deviceOptions.get ('repetition'),
            beatsPerRepetition: (lastNoteGroup?.endDuration ?? 0) * 4
        })
        
        this.renderingDetails = new RenderingDetails({
            timeline
        })

        this.noteTracker = new NoteTracker({
            clock: this.clock,
            timeline
        })

        this.feedback = new Feedback({
            enabled: deviceOptions.get('feedback'),
            timeline
        })

        this.result = new Result({
            enabled: this.inputDevice.connectedDevice()?.id !== 'no-input',
            timeline,
            repetition: deviceOptions.get ('repetition')
        })

        this.inputDevice.on('connected', this.deviceConnected = (event) => {
            this.result.setEnabled(event.device.id !== 'no-input')
        })

        this.inputDevice.on('noteStart', this.noteStart = (event: InputDeviceNoteEvent) => {
            this.instrument.playNote (event.noteIndex, event.velocity)
            const startDuration = this.clock.getBeat (event.timestamp)
            if (startDuration) {
                const note = {
                    note: event.note,
                    octave: event.octave,
                    noteIndex: event.noteIndex,
                    startDuration: startDuration / 4,
                    velocity: event.velocity
                }
                this.feedback.evaluateNote(note)
                this.result.addNote (note)
            }
        })
        this.inputDevice.on('noteEnd', this.noteEnd = (event: InputDeviceNoteEvent) => {

        })

        this.clock.on('end', () => {
            this.result.calculate()
        })

        this.soundSettings.on('changeMetronome', (volume) => {
            deviceOptions.set('soundSettings', {
                ...deviceOptions.get('soundSettings'),
                metronome: volume
            })
            this.metronome.setVolume (volume / 100)
        })

        this.renderingDetails.on('changeRenderingDetails', (renderingDetails) => {
            this.noteTracker.setRenderingDetails(renderingDetails)
            this.feedback.setRenderingDetails(renderingDetails)
        })

        this.playerSettings.on('changeNoteTracker', (noteTracker) => {
            deviceOptions.set('noteTracker', noteTracker)
        })

        this.playerSettings.on('changeFeedback', (feedback) => {
            deviceOptions.set('feedback', feedback)
            this.feedback.setEnabled(feedback)
        })

        this.playerSettings.on('changeTempo', (tempo) => {
            if (tempo) {
                deviceOptions.set ('tempo', tempo)
                this.clock.setBpm (tempo)
                this.metronome.clear ()
                this.clock.beats?.forEach(beat => {
                    this.metronome.schedule(beat)
                })
            }
        })
        this.playerSettings.on('changeRepetition', (repetition) => {
            if (repetition) {
                deviceOptions.set ('repetition', repetition)
                this.result.setRepetition(repetition)
                this.clock.setRepetition (repetition)
                this.metronome.clear ()
                this.clock.beats?.forEach(beat => {
                    this.metronome.schedule(beat)
                })
            }
        })
    }

    dispose () {
        this.stop ()

        if (this.noteStart) {
            this.inputDevice.off('noteStart', this.noteStart)
        }
        if (this.noteEnd) {
            this.inputDevice.off('noteEnd', this.noteEnd)
        }
        if (this.deviceConnected) {
            this.inputDevice.off('connected', this.deviceConnected)
        }
        delete this.noteStart
        delete this.noteEnd
        delete this.deviceConnected
    }

    toggle () {
        if (this.clock.currentState === 'running') {
            this.clock.pause()
            this.metronome.clear()
            this.noteTracker.pause()
        } else if (this.clock.currentState === 'paused') {
            this.clock.start()
            this.clock.beats?.forEach(beat => {
                this.metronome.schedule(beat)
            })
            this.noteTracker.start()
        } else {
            this.metronome.setAudioContext(this.audio.audioContext)
            this.instrument.setAudioContext(this.audio.audioContext)
            this.feedback.reset ()
            this.result.reset()
            const originTime = performance.now()
            this.inputDevice.setOriginTime(originTime)
            this.clock.start(originTime)
            this.clock.beats?.forEach(beat => {
                this.metronome.schedule(beat)
            })
            this.noteTracker.start()
        }
    }

    stop () {
        this.clock.stop()
        this.metronome.stop()
        this.noteTracker.stop()
        this.result.reset()
        this.feedback.reset ()
    }
}
