import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { SheetMusicTypes } from '@mom/types'
import { sheetMusic, svg } from "@mom/ui"
import React, { PropsWithChildren } from 'react'

const Image = styled.img({
    width: `100%`, 
    height: '100%',
    backgroundColor: '#fafafa'
})

const ImageContainer = styled.div({
    width: '100%',
    height: 'auto',
    aspectRatio: '16 / 9',
    borderRadius: '12px',
    overflow: 'hidden',

    '@supports not (aspect-ratio: 16 / 9)': {
        '&::before': {
            float: 'left',
            paddingTop: '56.25%',
            content: '""'
        },

        '&::after': {
            display: 'block',
            content: '""',
            clear: 'both'
        }
    }
})

interface SheetMusicProps {
    renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails
}

export function SheetMusic (props: PropsWithChildren<SheetMusicProps>) {
    const {
        renderingDetails,
        children
    } = props

    const sm = sheetMusic.SheetMusic({ renderingDetails })
    const root = svg.toSVGRootAttributes(sm)

    return (
        <svg viewBox={root.viewBox} className={css({
            width: '100%'
        })}>
            <svg dangerouslySetInnerHTML={{ __html: svg.toSVGChildrenString(sm) }} />
            {children}
        </svg>
    )
}

interface PreviewSheetMusicProps {
    timeline?: any
    source?: string
    crossOrigin?: 'anonymous'
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void
}

export function PreviewSheetMusic (props: PreviewSheetMusicProps) {
    const {
        timeline,
        source,
        crossOrigin,
        onClick
    } = props

    let src = source
    if (!source && timeline) {
        const renderingDetails = sheetMusic.getSheetMusicRenderingDetails(timeline, { width: 144, height: 81, documentMargin: 20 })
        src = svg.toSVGImageSourceString(sheetMusic.SheetMusic({ renderingDetails }))
    }
    
    return (
        <ImageContainer>
            <Image src={src} crossOrigin={crossOrigin} onClick={onClick} className={css({
                cursor: onClick ? 'pointer' : undefined
            })} />
        </ImageContainer>
    )
}