import { css } from "@emotion/css"
import { actions, useAppDispatch, useAppSelector } from "../store"
import { NonlocalisedMessage } from "../hoc/message"

export default function ErrorFeedback () {
    const messages = useAppSelector(state => state.feedback.messages)
    const dispatch = useAppDispatch()
    return (
        <div
            className={css({
                position: 'fixed',
                top: '0',
                left: '50%',
                width: '800px',
                maxWidth: '90%',
                transform: 'translateX(-50%)',
                zIndex: 100,
                "& > *": {
                    marginTop: '5px'
                }
            })}>
            {messages.map((message, index) => (
                <NonlocalisedMessage text={message.message} type="error" onClose={() => dispatch(actions.feedback.removeMessage(index))} />
            ))}
        </div>
    )
}