import { Action, configureStore } from '@reduxjs/toolkit'
import { actions, reducers, RootStateType } from './slices'
import { useDispatch, useSelector } from 'react-redux'
import { asyncMiddleware } from './middlewares/async'

export { actions } from './slices'
export { asyncActions } from './async'

export type AppState = RootStateType
export type AppDispatch = <TState, TDispatch extends typeof useDispatch, TServices>(action: Action<any> | ((state: TState, dispatch: TDispatch, services: TServices) => Promise<void>)) => Promise<void>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: <TSelected>(selector: (state: AppState) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean) => TSelected = useSelector

interface StoreOptions <TServices> {
    services: TServices
}

export default async function createStore <TServices> (options: StoreOptions<TServices>) {
    const { services } = options
    const reduxStore = configureStore({
        reducer: reducers,
        preloadedState: undefined,
        middleware: (_) => [
            asyncMiddleware({
                services,
                enhancers: [
                    async (state, dispatch, next) => {
                        await dispatch(actions.feedback.loading())
                        const result = await next().catch(error => {
                            const httpStatus = error.response?.status
                            if (httpStatus === 400) {
                                dispatch(actions.feedback.logError(error.response?.data?.message || error.message))
                            } else if (httpStatus > 400) {
                                dispatch(actions.feedback.logError('Server error occured'))
                            } else {
                                dispatch(actions.feedback.logError('Client error occured'))
                                console.error(error)
                            }
                        })
                        await dispatch(actions.feedback.idle())
                        return result
                    }
                ]
            })
        ]
    })

    return reduxStore
}