import styled from "@emotion/styled"
import React from "react"
import { PropsWithChildren } from "react"
import { ListItem } from "./item"

const UL = styled.ul({
    listStyle: 'none',
    padding: 0,
    margin: 0
})

interface ListProps {
    
}

export function List (props: PropsWithChildren<ListProps>) {
    const {
        children
    } = props

    return (
        <UL>
            {children}
        </UL>
    )
}
