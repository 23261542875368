import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { useState } from "react"
import { Icon } from "../icon"
import { HeaderStyle } from "./types"

const Container = styled.div({
    width: '100%',
    maxWidth: '600px',
    height: '40px',
    borderRadius: '40px',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex'
})

const SearchButtonContainer = styled.div ({
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
})

const Input = styled.input({
    border: '0',
    width: '100%',
    height: '100%',
    background: 'transparent',
    fontSize: '1rem',
    padding: '0 16px'
})

interface SearchProps {
    style?: HeaderStyle
    label: string
    text?: string
    className?: string
    onSearch?: (text: string) => void
}

export function Search (props: SearchProps) {
    const {
        style,
        label,
        text,
        className,
        onSearch
    } = props

    const [searchText, setSearchText] = useState (text ?? '')

    const search = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            onSearch && onSearch(event.currentTarget.value)
        }
    }

    const clickSearch = () => {
        onSearch && onSearch(searchText)
    }

    const classes = [
        className,
        css({
            position: 'relative',
            border: `1px solid ${style?.searchBorderColor}`,
            overflow: 'hidden'
        })
    ].filter(x => x)

    return (
        <Container className={classes.join(' ')}>
            <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={label} onKeyDown={search} />
            <SearchButtonContainer onClick={clickSearch}>
                <Icon icon="search" />
            </SearchButtonContainer>
        </Container>
    )
}