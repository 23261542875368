import { useState } from 'react'
import { Auto } from '../../component/auto-layout'
import { Button } from '../../hoc/button'
import { LocalisedText } from '../../hoc/text'
import { Textbox } from '../../hoc/textbox'
import { ConfirmationDetails } from '../types'

interface EmailVerificationProps {
    onVerify: (details: ConfirmationDetails) => void
    onResend: () => void
    onNavigate: (authState: 'signin' | 'signup') => void
}

export default function EmailVerification (props: EmailVerificationProps) {
    const [code, setCode] = useState('')

    const verify = async () => {
        props.onVerify({ code })
    }

    const resend = async () => {
        props.onResend()
    }

    const navigateToSignin = async () => {
        props.onNavigate ('signin')
    }

    return (
        <Auto direction='vertical' spacing={12}>
            <Auto>
                <LocalisedText textKey='email-verification-message' />
            </Auto>
            <Auto paddingBottom={12}>
                <Textbox labelKey='verification-code' value={code} onChange={setCode}></Textbox>
            </Auto>
            <Auto direction='horizontal' horizontalAlignment='center' spacing={12} paddingBottom={12}>
                <Button textKey='verify-code' onClick={verify} />
                <Button textKey='resend-code' onClick={resend} />
            </Auto>
            <Auto>
                <LocalisedText textKey='navigate-to-signin-message' onClick={navigateToSignin} />
            </Auto>
        </Auto>
    )
}