import { actions, AppDispatch, AppState } from ".."
import { Services } from "../../types"

export default {
    loadRecommendations: () => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { recommendationsClient } = services

        const recommendations = await recommendationsClient.getRecommendations()
        
        dispatch (actions.recommendation.setRecommendations(recommendations))
    }
}