import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function H2 (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    return (
        <h2 className={className} onClick={onClick} title={title}>{children}</h2>
    )
}