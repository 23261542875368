import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function Span (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    return (
        <span className={className} onClick={onClick} title={title}>{children}</span>
    )
}