import { css } from "@emotion/css"
import styled from "@emotion/styled"

const Container = styled.div({
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    width: '100%',
    height: '0.5rem',
    borderRadius: '0.5rem',
    overflow: 'hidden'
})

const Runway = styled.div({
    position: 'absolute',
    boxSizing: 'border-box',
    opacity: '0.4',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%'
})

const Bar = styled.div({
    position: 'absolute',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    height: '100%',
    transition: 'width 200ms cubic-bezier(0, 0, 0.2, 1)'
})

export interface ProgressBarStyle {
    color?: string
}

interface ProgressBarProps {
    value: number
    style?: ProgressBarStyle
}

export function ProgressBar (props: ProgressBarProps) {
    const {
        value,
        style
    } = props

    if (value < 0 || 1 < value) {
        console.error ('Value of progress bar must be between 0 and 1.')

        return <></>
    }

    return (
        <Container>
            <Runway className={css({
                backgroundColor: style?.color
            })}/>
            <Bar className={css({
                backgroundColor: style?.color,
                width: `${value * 100}%`
            })}/>
        </Container>
    )
}