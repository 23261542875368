import { ToggleButton as GenericToggleButton, ToggleButtonStyle } from "../component/toggle-button"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface ToggleButtonProps {
    labelOnKey?: string
    labelOffKey?: string
    value?: boolean
    onChange?: (value: boolean) => void
}

export function ToggleButton (props: ToggleButtonProps) {
    const {
        labelOnKey,
        labelOffKey,
        value,
        onChange
    } = props
    const { localisationStrings } = useLocalisationContext()
    const { colors } = useTheme()

    const style: ToggleButtonStyle = {
        backgroundColor: colors.primaryColor.shade1,
        textColor: colors.primaryColor.shade6,
        toggleOffColor: colors.primaryColor.shade5,
        toggleOnColor: colors.secondaryColor.shade1
    }

    return (
        <GenericToggleButton
            value={value}
            labelOn={labelOnKey && localisationStrings[labelOnKey]}
            labelOff={labelOffKey && localisationStrings[labelOffKey]}
            style={style}
            onChange={onChange}
        />
    )
}