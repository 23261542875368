import { ReactNode } from "react";
import { EtudeCard as GenericEtudeCard, EtudeCardStyle } from "../component/etude-card";
import { EtudeStats as GenericEtudeStats, EtudeStatsStyle } from "../component/etude-stats";
import { useTheme } from "../context/theme";

interface EtudeCardProps {
    title: string
    username: string
    previewImageSource?: string
    /**
     * React node that represents the preview image
     * @deprecated
     * @todo Remove the previewImageElement after preview images are stored as image files
     */
    previewImageElement?: ReactNode
    userImageSource?: string
    stats?: {
        played?: number
        liked?: number
        subscribed?: number
    }
    onClick?: () => void
}

export function EtudeCard (props: EtudeCardProps) {
    const {
        title,
        username,
        previewImageElement,
        previewImageSource,
        userImageSource,
        stats,
        onClick
    } = props
    const { colors } = useTheme()

    const cardStyle: EtudeCardStyle = {
        primaryTextColor: colors.primaryColor.shade6,
        secondaryTextColor: colors.primaryColor.shade5
    }

    const statsStyle: EtudeStatsStyle = {
        textColor: colors.primaryColor.shade6
    }

    return <GenericEtudeCard title={title} subtitle={username} previewImageSource={previewImageSource} previewImageElement={previewImageElement} titleImageElement={userImageSource} style={cardStyle} onClick={onClick} additionalDetails={
        <GenericEtudeStats played={stats?.played} liked={stats?.liked} subscribed={stats?.subscribed} style={statsStyle} />
    } />
}