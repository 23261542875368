import { useNavigate } from "react-router"
import { useAuth } from "../auth"
import { Header as GenericHeader } from "../component/header"
import { HeaderStyle } from "../component/header"
import { useTheme } from "../context/theme"
import { useLocalisationContext } from "../context/localisation"

interface HeaderProps {
    onMobileSettingsClick: () => void
    onProfileMenuClick: () => void
}

export function Header (props: HeaderProps) {
    const {
        onMobileSettingsClick,
        onProfileMenuClick
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()
    const navigate = useNavigate()
    const auth = useAuth()

    const style: HeaderStyle = {
        searchBorderColor: theme.colors.primaryColor.shade5,
        signinBorderColor: theme.colors.primaryColor.shade5,
        backgroundColor: theme.colors.primaryColor.shade1
    }

    const goToHome = () => {
        navigate('/')
    }

    const search = (text: string) => {
        console.log(text)
    }

    const goToAddEtudePage = () => {
        navigate('/etude-editor/new')
    }

    const goToSettings = () => {
        navigate('/settings')
    }

    const goToSignin = () => {
        auth.navigateTo('signin')
    }

    const openProfileMenu = () => {
        onProfileMenuClick && onProfileMenuClick()
    }

    return (
        <GenericHeader
            style={style}
            searchLabel={localisationStrings['search-label']}
            signinText={localisationStrings['signin']}
            onBrandClick={goToHome}
            onAddEtudeClick={goToAddEtudePage}
            onSearch={search}
            onProfileClick={openProfileMenu}
            onSigninClick={goToSignin}
            onSettingsClick={goToSettings}
            onMobileSettingsClick={onMobileSettingsClick}
            profileAuthenticated={auth.isAuthenticated}
            profileElement={
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill="#00CF53" stroke="none"/>
                    <text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle" stroke="#fdfdfd" fill="#fdfdfd">{auth.identity?.name?.[0].toUpperCase()}</text>
                </svg>
            }
        ></GenericHeader>
    )
}