import { ReactNode } from "react";
import { EtudeDetails as GenericEtudeDetails, EtudeDetailsStyle } from "../component/etude-details";
import { Icon, IconStyle } from "../component/icon";
import { useTheme } from "../context/theme";

interface ExerciseDetailsProps {
    title: string
    description?: string
    /**
     * React node that represents the preview image
     * @deprecated
     * @todo Remove the previewImageElement after preview images are stored as image files
     */
    previewImageElement?: ReactNode
    previewImageSource?: string
    onPlayClick?: () => void
}

export function ExerciseDetails (props: ExerciseDetailsProps) {
    const {
        title,
        description,
        previewImageSource,
        previewImageElement,
        onPlayClick
    } = props
    const { colors } = useTheme()

    const style: EtudeDetailsStyle = {
        primaryTextColor: colors.primaryColor.shade6,
        secondaryTextColor: colors.primaryColor.shade5
    }

    const iconStyle: IconStyle = {
        stroke: colors.secondaryColor.shade1
    }

    return <GenericEtudeDetails title={title} description={description} previewImageSource={previewImageSource} previewImageElement={previewImageElement} hideActionButtons style={style} titleImageElement={
        <Icon icon="circled-play" style={iconStyle} size={2.5} onClick={onPlayClick} />
    } />
}