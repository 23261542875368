import { Textbox as GenericTextbox, TextboxStyle } from "../component/textbox"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface TextboxProps {
    labelKey: string
    value?: string
    className?: string
    type?: React.HTMLInputTypeAttribute
    onChange?: (text: string) => void
}

export function Textbox (props: TextboxProps) {
    const {
        labelKey,
        value,
        className,
        type,
        onChange
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const style: TextboxStyle = {
        backgroundColor: colors.primaryColor.shade1,
        borderColor: colors.primaryColor.shade2,
        labelColor: colors.primaryColor.shade5,
        textColor: colors.primaryColor.shade6
    }
    
    return (
        <GenericTextbox type={type} label={localisationStrings[labelKey]} value={value} className={className} onChange={onChange} style={style} />
    )
}