import { PropsWithChildren } from "react";
import { Overlay as GenericOverlay, OverlayStyle } from "../component/overlay";
import { useTheme } from "../context/theme";

interface OverlayProps {
    open?: boolean
    className?: string
    zIndex?: number
    onClick?: () => void
}

export function Overlay (props: PropsWithChildren<OverlayProps>) {
    const {
        open,
        className,
        zIndex,
        children,
        onClick
    } = props
    const { colors } = useTheme()

    const style: OverlayStyle = {
        color: colors.primaryColor.shade6
    }

    return open ? (
        <GenericOverlay open={open} className={className} onClick={onClick} zIndex={zIndex} style={style}>
            {children}
        </GenericOverlay>
    ) : <></>
}