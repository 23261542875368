import { css } from "@emotion/css";
import { Auto, AutoFill } from "../auto-layout";
import { Icon } from "../icon";
import { Text } from "../text";
import { MobileMenuItem } from "./item";
import { MobileMenuItemDetails, MobileMenuStyle } from "./types";

export * from './types'

interface MobileMenuProps {
    items: MobileMenuItemDetails[]
    title: string
    style?: MobileMenuStyle
    className?: string
    onClick?: (item: MobileMenuItemDetails) => void
    onClose?: () => void
}

export function MobileMenu (props: MobileMenuProps) {
    const {
        items,
        title,
        style,
        className,
        onClick,
        onClose
    } = props

    const onItemClick = (item: MobileMenuItemDetails) => {
        onClick && onClick(item)
    }

    const classes = [
        className,
        css({
            background: style?.backgroundColor
        })
    ].filter(x => x)

    return (
        <AutoFill className={classes.join(' ')}>
            <Auto
                direction="horizontal"
                verticalAlignment="center"
                paddingLeft={32}
                paddingRight={32}
                paddingTop={32}
                paddingBottom={32}
                className={css({
                    borderBottom: `1px solid ${style?.borderColor}`
                })}
            >
                <Auto>
                    <Text fontSize={1.5} text={title} />
                </Auto>
                <Auto horizontalAlignment="right">
                    <Icon icon="close" onClick={onClose} />
                </Auto>
            </Auto>
            {items.map(item => (
                <MobileMenuItem key={`left-menu-item-${item.id}`} style={style} onClick={() => onItemClick(item)}>
                    {item.icon && (
                        <Icon icon={item.icon} size={1.35} style={{ stroke: style?.textColor }} />
                    )}
                    <Text fontSize={1.35} text={item.text} />
                </MobileMenuItem>
            ))}
        </AutoFill>
    )
}
