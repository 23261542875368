import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import { Icon } from "./icon";

const Container = styled.div({
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '16px',
    position: 'relative',
    minHeight: '40px',
    borderRadius: '32px',
    boxSizing: 'border-box',
    display: 'inline-flex',
    alignItems: 'center'
})

const IconContainer = styled.div({
    position: 'absolute',
    right: '7px',
    top: '7px',
    cursor: 'pointer',
    display: 'inline'
})

export interface ChipStyle {
    borderColor?: string
    textColor?: string
}

interface ChipProps {
    removable?: boolean
    style?: ChipStyle
    className?: string
    onRemove?: () => void
}

export function Chip (props: PropsWithChildren<ChipProps>) {
    const {
        removable,
        style,
        className,
        onRemove,
        children
    } = props

    const iconStyle = {
        stroke: style?.textColor
    }

    const classes = [
        className,
        css({
            border: `1px solid ${style?.borderColor}`,
            paddingRight: removable ? '36px' : '16px'
        })
    ].filter(x => x)

    return (
        <Container className={classes.join(' ')}>
            {children}
            {removable && (
                <IconContainer onClick={onRemove}>
                    <Icon icon="circled-x" style={iconStyle} />
                </IconContainer>
            )}
        </Container>
    )
}