import { useServices } from "../../context/services"
import { EtudeCard as HOCEtudeCard } from "../../hoc/etude-card"

interface Props {
    id: string
    title: string
    username: string
    previewExerciseId: string
    onClick: (id: string) => void
}

export function EtudeCard (props: Props) {
    const { id, title, username, onClick } = props
    const { config } = useServices ()

    return (
        <HOCEtudeCard
            title={title}
            username={username}
            previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${id}`}
            onClick={() => onClick(id)}
        />
    )
}