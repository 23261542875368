import HttpClient, { HttpClientConfiguration } from "./client";

interface AuthClientOptions extends HttpClientConfiguration {
    
}

export class AuthClient {
    private httpClient: HttpClient
    constructor (options: AuthClientOptions) {
        this.httpClient = new HttpClient(options)
    }

    getWebsocketToken (): Promise<{ token: string }> {
        return this.httpClient.post(`/token/websocket`, {})
    }
}