import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { HeaderStyle } from "./types"

interface SigninProps {
    style?: HeaderStyle
    text: string
    onClick?: () => void
}

const Button = styled.button({
    padding: '0 16px',
    height: '36px',
    fontSize: '1rem',
    borderRadius: '18px',
    cursor: 'pointer',
    outline: 'none'
})

export function Signin (props: SigninProps) {
    const {
        text,
        style,
        onClick
    } = props

    const click = () => {
        onClick && onClick()
    }

    return (
        <Button onClick={click} className={css({
            border: `1px solid ${style?.signinBorderColor}`,
            backgroundColor: 'transparent'
        })}>
            {text}
        </Button>
    )
}