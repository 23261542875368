import { actions, AppDispatch, AppState } from ".."
import { EtudeDetails, Services } from "../../types"

export default {
    getEtude: (id: string) => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { config, practiceAndHistoryClient } = services
        const etude = await practiceAndHistoryClient.getEtude(id)
        // const [etude, ] = await Promise.all([
        //     practiceAndHistoryClient.getEtude(id),
        //     new Promise((resolve, reject) => {
        //         const image = new Image()
        //         image.src = `${config.cdn.baseUrl}/images/preview-image/${id}`
        //         image.addEventListener('load', () => {
                    
        //         })
        //         image.addEventListener('error', () => {
        //             reject (new Error (`Error occured while fetching preview image.`))
        //         })
        //     })
        // ])
        dispatch (actions.etudeEditor.setEtude(etude))
        dispatch (actions.etudeEditor.setPreviewImageSource(`${config.cdn.baseUrl}/images/preview-image/${id}`))
    },
    submitEtude: () => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { practiceAndHistoryClient } = services
        const etude = state.etudeEditor
        const {
            id,
            title,
            description,
            exercises,
            previewImage
        } = etude.form

        const details: EtudeDetails = {
            title,
            description,
            exercises: exercises.map(x => ({
                id: x.id,
                title: x.title,
                description: x.description
            }))
        }
        let etudeId = id
        if (id) {
            await practiceAndHistoryClient.updateEtude(id, details)
        } else {
            const result = await practiceAndHistoryClient.createEtude(details)
            etudeId = result.id
            dispatch(actions.etudeEditor.setEtudeId(result.id))
        }
        if (etudeId && previewImage?.dataUrl) {
            await practiceAndHistoryClient.uploadPreviewImage(etudeId, previewImage.dataUrl)
        }
    },
    upload: (files: File[]) => async (state: AppState, dispatch: AppDispatch, services: Services) => {
        const { musicDataClient } = services
        const result = await musicDataClient.upload(files, {
            onUploadProgress: (event) => {
                dispatch(actions.etudeEditor.setUploadPercentage(event.percentage))
            }
        })
        dispatch(actions.etudeEditor.addEtudeExercise(result.map(x => ({ id: x.id.replaceAll('/', '_'), title: x.file.name }))))
        dispatch(actions.etudeEditor.resetUpload())
    }
}