import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { AuthClient } from "../client/auth-client"
import { AuthResult, ConfirmationDetails, Identity, SigninDetails, SignupDetails } from "../types"
import EmailVerification from "./email-verification"
import Signin from "./signin"
import Signup from "./signup"
import { css } from '@emotion/css'
import React from "react"
import { Auto, AutoFill, AutoHug } from "../../component/auto-layout"
import { Icon } from "../../hoc/icon"
import { mediaQuery } from "../../util/device-detection"
import { useLocalisationContext } from "../../context/localisation"
import { actions, useAppDispatch } from "../../store"

interface AuthContext {
    get identity (): Identity | undefined
    get isAuthenticated (): boolean
    navigateTo (to: 'signin'): void
    signout (): void
}

const authContext = createContext<AuthContext>({
    identity: undefined,
    isAuthenticated: false,
    navigateTo: () => {},
    signout: () => {}
})

export const useAuth = () => useContext(authContext)

interface AuthProviderProps {
    cognito: {
        userPoolId: string
        clientId: string
    }
    authServiceBaseUrl: string
    onLogin?: () => void
}

export function AuthProvider (props: React.PropsWithChildren<AuthProviderProps>) {
    const { cognito, authServiceBaseUrl } = props
    const { localisationStrings } = useLocalisationContext()
    const dispatch = useAppDispatch ()
    const authClient = useMemo(() => {
        return new AuthClient({
            cognito,
            authServiceBaseUrl
        })
    }, [cognito.clientId, cognito.userPoolId, authServiceBaseUrl])

    const [authResult, setAuthResult] = useState<AuthResult | { authStatus: 'signup' } | undefined> (authClient.authResult)

    useEffect(() => {
        if (authResult?.authStatus === 'complete') {
            props.onLogin && props.onLogin()
        }
    }, [authResult])

    const contextValue: AuthContext = {
        get identity() {
            return authResult?.authStatus === 'complete' ? authResult.identity : undefined
        },
        get isAuthenticated() {
            return authResult?.authStatus === 'complete'
        },
        signout () {
            authClient.signout().then(() => {
                setAuthResult(undefined)
            })
        },
        navigateTo (to: 'signin') {
            setAuthResult ({ authStatus: to })
        }
    }

    const navigate = (authState: 'signin' | 'signup') => {
        setAuthResult({
            authStatus: authState
        })
    }

    const signin = async (details: SigninDetails) => {
        dispatch(async () => {
            try {
                const result = await authClient.signin (details)
                setAuthResult(result)
            } catch (e: any) {
                const errorMessage = (e?.message && localisationStrings[e.message]) ?? localisationStrings['signin-error']
                dispatch(actions.feedback.logError(errorMessage))
            }
        })
    }

    const signup = async (details: SignupDetails) => {
        dispatch(async () => {
            try {
                const result = await authClient.signup (details)
                setAuthResult(result)
            } catch (e: any) {
                const errorMessage = (e?.message && localisationStrings[e.message]) ?? localisationStrings['signup-error']
                dispatch(actions.feedback.logError(errorMessage))
            }
        })
    }

    const verify = async (details: ConfirmationDetails) => {
        dispatch(async () => {
            try {
                const result = await authClient.confirmCode(details)
                setAuthResult(result)
            } catch (e: any) {
                const errorMessage = (e?.message && localisationStrings[e.message]) ?? localisationStrings['email-verification-error']
                dispatch(actions.feedback.logError(errorMessage))
            }
        })
    }

    const resend = async () => {
        dispatch(async () => {
            try {
            await authClient.resendCode()
            } catch (e: any) {
                const errorMessage = (e?.message && localisationStrings[e.message]) ?? localisationStrings['resend-verification-error']
                dispatch(actions.feedback.logError(errorMessage))
            }
        })
    }

    const cancel = async () => {
        setAuthResult(undefined)
    }

    return (
        <authContext.Provider value={contextValue}>
            { !authResult?.authStatus || authResult?.authStatus === 'complete' ? (
                props.children
            ) : (
                <Auto
                    direction="vertical"
                    spacing={24}
                    padding={24}
                    className={css({
                        margin: "auto",
                        maxWidth: '600px',
                        width: '100%',
                        marginTop: '20vh',

                        [mediaQuery.phone()]: {
                            marginTop: 0,
                            transform: 'translateY(0)'
                        }
                    })}
                >
                    <Auto direction="horizontal" className={css({
                        [mediaQuery.phone()]: {
                            paddingBottom: '5vh'
                        }
                    })}>
                        <AutoFill></AutoFill>
                        <AutoHug>
                            <Icon icon="close" size={2.4} onClick={cancel} />
                        </AutoHug>
                    </Auto>
                    {authResult?.authStatus === 'signup' ? (
                        <Signup
                            onNavigate={navigate}
                            onSignup={signup}
                        />
                    ) : authResult?.authStatus === 'unverified_email' ? (
                        <EmailVerification
                            onNavigate={navigate}
                            onVerify={verify}
                            onResend={resend}
                        />
                    ) : (
                        <Signin
                            onNavigate={navigate}
                            onSignin={signin}
                        />
                    )}
                </Auto>
            )}
        </authContext.Provider>
    )
}