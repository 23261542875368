import styled from "@emotion/styled"
import { constants } from "../../constants"
import { useExerciseDetails } from "../../context/player"
import { Button } from "../../hoc/button"
import { NonlocalisedText } from "../../hoc/text"
import { mediaQuery } from "../../util/device-detection"

const Container = styled.div({
    position: 'fixed',
    top: `${constants.ui.height.header}px`,
    left: 0,
    right: 0,
    height: `${constants.ui.height.playerHeader}px`,
    maxWidth: `${constants.ui.width.pageMaxWidth}px`,
    margin: '0 auto',
    display: 'flex',
    padding: '60px 24px 0',

    [mediaQuery.phone()]: {
        paddingTop: 0,
        height: 'auto'
    }
})

const Left = styled.div ({
    width: '150px'
})

const Right = styled.div ({
    width: '150px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
})

const Title = styled.div ({
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const HiddenExercises = styled.div({
    display: 'inline',
    [mediaQuery.phone()]: {
        display: 'none'
    }
})

interface HeaderProps {
    exerciseId: string
    hideExerciseList?: boolean
    onOpenExerciseList: () => void
}

export function Header (props: HeaderProps) {
    const {
        exerciseId,
        hideExerciseList,
        onOpenExerciseList
    } = props
    const exercise = useExerciseDetails (exerciseId)

    const openExerciseList = (e: React.MouseEvent) => {
        onOpenExerciseList ()
        e.preventDefault ()
        e.stopPropagation ()
    }
    
    return (
        <Container>
            <Left />
            <Title>
                <NonlocalisedText variant="h1" text={exercise.title} />
            </Title>
            <Right>
                {!hideExerciseList && (
                    <HiddenExercises>
                        <Button icon="playlist" textKey="exercises" onClick={openExerciseList} />
                    </HiddenExercises>
                )}
            </Right>
        </Container>
    )
}