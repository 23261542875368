import axios, { AxiosInstance, AxiosProgressEvent } from "axios"

export interface TokenAcquirer {
    getAccessToken () : Promise<string | undefined>
    acquireAccessToken () : Promise<string | undefined>
}

export interface HttpClientConfiguration {
    baseUrl: string
    tokenAcquirer: TokenAcquirer
}

interface HttpClientRequestConfig {
    headers?: { [name: string]: string }
    disableAuth?: boolean
    onUploadProgress?: (event: { percentage: number }) => void
}

function catchAxiosError (err: any) {
    if (err.response && err.response.status) { 
        return err.response
    } else {
        throw err 
    }
}

const OKStatuses = [
    200,
    204
]

export default class HttpClient {
    private client: AxiosInstance
    private config: HttpClientConfiguration
    constructor (config: HttpClientConfiguration) {
        this.config = config
        this.client = axios.create({
            baseURL: config.baseUrl
        })
    }

    async get<Response> (url: string) : Promise<Response> {
        let token = await this.config.tokenAcquirer.getAccessToken()
        let response = await this.client.get(url, { headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)
        
        if (response.status === 401) {
            token = await this.config.tokenAcquirer.acquireAccessToken()
            response = await this.client.get(url, { headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)
        }

        if (OKStatuses.includes(response.status)) {
            return response.data
        }

        throw new Error (`Http error occured, status: ${response.status}, method: GET, url: ${url}`)
    }

    async post<Request, Response> (url: string, data: Request, config?: HttpClientRequestConfig) : Promise<Response> {
        const headers = { ...(config?.headers || {}) }
        const onUploadProgress = config?.onUploadProgress ? (progressEvent: AxiosProgressEvent) => {
            if (config?.onUploadProgress) {
                config.onUploadProgress({ percentage: progressEvent.progress ?? 0 })
            }
        } : undefined

        if (config?.disableAuth !== true) {
            const token = await this.config.tokenAcquirer.getAccessToken()
            headers['Authorization'] = `Bearer ${token}`
        }
        
        let response = await this.client.post(url, data, { headers, onUploadProgress}).catch(catchAxiosError)

        if (config?.disableAuth !== true && response.status === 401) {
            const token = await this.config.tokenAcquirer.acquireAccessToken()
            headers['Authorization'] = `Bearer ${token}`
            response = await this.client.post(url, data, { headers, onUploadProgress }).catch(catchAxiosError)
        }

        if (OKStatuses.includes(response.status)) {
            return response.data
        }
        
        throw new Error (`Http error occured, status: ${response.status}, method: POST, url: ${url}`)
    }

    async put<Request, Response> (url: string, data: Request) : Promise<Response> {
        let token = await this.config.tokenAcquirer.getAccessToken()
        let response = await this.client.put(url, data, { headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)

        if (response.status === 401) {
            token = await this.config.tokenAcquirer.acquireAccessToken()
            response = await this.client.put(url, data, { headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)
        }

        if (OKStatuses.includes(response.status)) {
            return response.data
        }

        throw new Error (`Http error occured, status: ${response.status}, method: PUT, url: ${url}`)
    }

    async delete<Request, Response> (url: string, data?: Request) : Promise<Response> {
        let token = await this.config.tokenAcquirer.getAccessToken()
        let response = await this.client.delete(url, { data, headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)
        
        if (response.status === 401) {
            token = await this.config.tokenAcquirer.acquireAccessToken()
            response = await this.client.delete(url, { data, headers: { 'Authorization': `Bearer ${token}` } }).catch(catchAxiosError)
        }

        if (OKStatuses.includes(response.status)) {
            return response.data
        }

        throw new Error (`Http error occured, status: ${response.status}, method: DELETE, url: ${url}`)
    }
}