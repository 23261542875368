import { ColorSelector as GenericColorSelector, ColorSelectorStyle } from "../component/color-selector";
import { useLocalisationContext } from "../context/localisation";
import { useTheme } from "../context/theme";

interface ColorSelectorProps {
    color?: string
    onChange?: (color: string) => void
}

export function ColorSelector (props: ColorSelectorProps) {
    const {
        color,
        onChange
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()

    const style: ColorSelectorStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        textColor: theme.colors.primaryColor.shade6,
        highlightColor: theme.colors.primaryColor.shade2
    }

    return <GenericColorSelector color={color} generateLabel={localisationStrings['generate']} style={style} onChange={onChange} />
}