import {
  Textarea as GenericTextarea,
  TextareaStyle,
} from "../component/textarea";
import { useLocalisationContext } from "../context/localisation";
import { useTheme } from "../context/theme";

interface TextareaProps {
  className?: string;
  labelKey: string;
  text?: string;
  minRows?: number;
  onChange?: (text: string) => void;
}

export function Textarea(props: TextareaProps) {
  const { labelKey, text, className, onChange } = props;
  const { colors } = useTheme();
  const { localisationStrings } = useLocalisationContext();

  const style: TextareaStyle = {
    backgroundColor: colors.primaryColor.shade1,
    borderColor: colors.primaryColor.shade2,
    labelColor: colors.primaryColor.shade5,
    textColor: colors.primaryColor.shade6,
  };

  return (
    <GenericTextarea
      label={localisationStrings[labelKey]}
      text={text}
      className={className}
      onChange={onChange}
      style={style}
    />
  );
}
