interface DeviceOptions {
    cancelOrientationEnforcement: boolean
    tempo: number
    repetition: number
    noteTracker: boolean
    feedback: boolean
    soundSettings: {
        metronome: number
    }
}

const defaultDeviceOptions: DeviceOptions = {
    cancelOrientationEnforcement: false,
    tempo: 60,
    repetition: 1,
    noteTracker: true,
    feedback: true,
    soundSettings: {
        metronome: 100
    }
}

class LocalStorageDeviceOptions {
    private _options: DeviceOptions
    constructor () {
        this.load()
    }

    private load () {
        const options = localStorage.getItem('MOM_DEVICE_USER_OPTIONS')
        if (options) {
            this._options = JSON.parse(atob(options))
        } else {
            this._options = defaultDeviceOptions
        }
    }

    private save () {
        localStorage.setItem('MOM_DEVICE_USER_OPTIONS', btoa(JSON.stringify(this._options ?? defaultDeviceOptions)))
    }

    get <T extends keyof DeviceOptions> (option: T): DeviceOptions[T] {
        return this._options[option] ?? defaultDeviceOptions[option]
    }

    set <T extends keyof DeviceOptions> (option: T, value: DeviceOptions[T]) {
        this._options[option] = value
        this.save()
    }
}

export default new LocalStorageDeviceOptions()