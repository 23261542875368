import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { PropsWithChildren, useState } from "react"
import { Icon } from "./icon"

const Container = styled.div({
    borderRadius: '4px',
    padding: '8px 16px',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'wrap',
    userSelect: 'none'
})

const IconContainer = styled.div({
    display: 'flex',
    alignItems: 'center'
})

const Label = styled.label({
    padding: '0 4px',
    cursor: 'inherit'
})

const Content = styled.div({
    display: 'block',
    paddingTop: '16px',
    boxSizing: 'border-box',
    width: '100%'
})

export interface CollapsablePanelStyle {
    borderColor?: string
    textColor?: string
}

interface CollapsablePanelProps {
    label?: string
    collapsed?: boolean
    disable?: boolean
    style?: CollapsablePanelStyle
    className?: string
}

export function CollapsablePanel (props: PropsWithChildren<CollapsablePanelProps>) {
    const {
        label,
        collapsed,
        disable,
        className,
        style,
        children
    } = props
    const [isCollapsed, setIsCollapsed] = useState(collapsed ?? false)

    const classes = [
        className,
        css({
            border: `1px solid ${style?.borderColor}`
        })
    ].filter(x => x)

    const iconStyle = {
        stroke: style?.textColor
    }

    const toggle = () => {
        if (disable !== true) {
            setIsCollapsed(!isCollapsed)
        }
    }
    
    return (
        <Container className={classes.join(' ')}>
            <IconContainer onClick={toggle} className={css({
                cursor: disable !== true ? 'pointer' : undefined,
            })}>
                {disable !== true && (
                    <>
                        {isCollapsed ? (
                            <Icon icon="down" style={iconStyle} size={1.2} />
                        ) : (
                            <Icon icon="up" style={iconStyle} size={1.2} />
                        )}
                    </>
                )}
                {label && (
                    <Label className={css({
                        color: style?.textColor,
                        fontWeight: 700
                    })}>
                        {label}
                    </Label>
                )}
            </IconContainer>
            {!isCollapsed && (
                <Content>
                    {children}
                </Content>
            )}
        </Container>
    )
}