
export const piano = {
    'real': [
    0.000000,
    -0.000000,
    -0.203569,
    0.500000,
    -0.401676,
    0.137128,
    -0.104117,
    0.115965,
    -0.004413,
    0.067884,
    -0.008880,
    0.079300,
    -0.038756,
    0.011882,
    -0.030883,
    0.027608,
    -0.013429,
    0.003930,
    -0.014029,
    0.009720,
    -0.007653,
    0.007866,
    -0.032029,
    0.046127,
    -0.024155,
    0.023095,
    -0.005522,
    0.004511,
    -0.003593,
    0.011248,
    -0.004919,
    0.008505,
    -0.002920,
    0.001520,
    -0.005641,
    0.002615,
    -0.001866,
    0.001316,
    -0.000320,
    0.000800,
    -0.000957,
    0.001989,
    -0.001172,
    0.001682,
    -0.002620,
    0.000544,
    -0.000734,
    0.000186,
    -0.000363,
    0.000243,
    -0.000142,
    0.000437,
    -0.000860,
    0.000117,
    -0.000350,
    0.000110,
    -0.000253,
    0.000218,
    -0.000061,
    0.000015,
    -0.000038,
    0.000017,
    -0.000025,
    0.000007,
    -0.000081,
    0.000017,
    -0.000064,
    0.000166,
    -0.000009,
    0.000013,
    -0.000024,
    0.000001,
    -0.000032,
    0.000013,
    -0.000018,
    0.000007,
    -0.000013,
    0.000010,
    -0.000023,
    0.000008,
    -0.000025,
    0.000046,
    -0.000035,
    0.000006,
    -0.000012,
    0.000012,
    -0.000024,
    0.000023,
    -0.000024,
    0.000027,
    -0.000010,
    0.000022,
    -0.000011,
    0.000021,
    -0.000007,
    0.000011,
    -0.000006,
    0.000021,
    -0.000014,
    0.000026,
    -0.000013,
    0.000003,
    -0.000032,
    0.000033,
    -0.000036,
    0.000025,
    -0.000020,
    0.000026,
    -0.000050,
    0.000028,
    -0.000013,
    0.000008,
    -0.000018,
    0.000020,
    -0.000086,
    0.000120,
    -0.000005,
    0.000012,
    -0.000016,
    0.000028,
    -0.000012,
    0.000006,
    -0.000015,
    0.000012,
    -0.000022,
    0.000012,
    -0.000023,
    0.000024,
    -0.000011,
    0.000022,
    -0.000009,
    0.000018,
    -0.000019,
    0.000013,
    -0.000042,
    0.000015,
    -0.000019,
    0.000014,
    -0.000019,
    0.000007,
    -0.000008,
    0.000030,
    -0.000011,
    0.000011,
    -0.000012,
    0.000022,
    -0.000007,
    0.000018,
    -0.000028,
    0.000025,
    -0.000020,
    0.000008,
    -0.000032,
    0.000022,
    -0.000010,
    0.000013,
    -0.000026,
    0.000013,
    -0.000024,
    0.000009,
    -0.000107,
    0.000109,
    -0.000007,
    0.000014,
    -0.000015,
    0.000007,
    -0.000029,
    0.000045,
    -0.000023,
    0.000039,
    -0.000010,
    0.000029,
    -0.000008,
    0.000036,
    -0.000018,
    0.000007,
    -0.000007,
    0.000007,
    -0.000025,
    0.000010,
    -0.000006,
    0.000022,
    -0.000021,
    0.000007,
    -0.000018,
    0.000011,
    -0.000011,
    0.000010,
    -0.000015,
    0.000020,
    -0.000012,
    0.000004,
    -0.000005,
    0.000007,
    -0.000007,
    0.000003,
    -0.000001,
    0.000006,
    -0.000007,
    0.000018,
    -0.000002,
    0.000005,
    -0.000008,
    0.000006,
    -0.000010,
    0.000016,
    -0.000010,
    0.000021,
    -0.000011,
    0.000013,
    -0.000011,
    0.000005,
    -0.000006,
    0.000016,
    -0.000014,
    0.000014,
    -0.000009,
    0.000009,
    -0.000004,
    0.000013,
    -0.000015,
    0.000004,
    -0.000007,
    0.000007,
    -0.000004,
    0.000004,
    -0.000009,
    0.000010,
    -0.000008,
    0.000013,
    -0.000012,
    0.000001,
    -0.000003,
    0.000012,
    -0.000004,
    0.000004,
    -0.000007,
    0.000008,
    -0.000010,
    0.000013,
    -0.000015,
    0.000013,
    -0.000010,
    0.000012,
    -0.000008,
    0.000011,
    -0.000024,
    0.000008,
    -0.000013,
    0.000013,
    -0.000018,
    0.000005,
    -0.000022,
    0.000037,
    -0.000019,
    0.000027,
    -0.000022,
    0.000026,
    -0.000029,
    0.000029,
    -0.000029,
    0.000031,
    -0.000034,
    0.000032,
    -0.000031,
    0.000037,
    -0.000033,
    0.000038,
    -0.000038,
    0.000039,
    -0.000036,
    0.000035,
    -0.000038,
    0.000035,
    -0.000034,
    0.000033,
    -0.000030,
    0.000029,
    -0.000028,
    0.000025,
    -0.000023,
    0.000022,
    -0.000020,
    0.000018,
    -0.000017,
    0.000015,
    -0.000014,
    0.000013,
    -0.000012,
    0.000011,
    -0.000011,
    0.000010,
    -0.000009,
    0.000009,
    -0.000009,
    0.000008,
    -0.000008,
    0.000008,
    -0.000008,
    0.000007,
    -0.000007,
    0.000007,
    -0.000007,
    0.000006,
    -0.000006,
    0.000006,
    -0.000006,
    0.000006,
    -0.000005,
    0.000006,
    -0.000006,
    0.000005,
    -0.000005,
    0.000005,
    -0.000005,
    0.000005,
    -0.000005,
    0.000005,
    -0.000005,
    0.000004,
    -0.000004,
    0.000004,
    -0.000005,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000003,
    0.000004,
    -0.000004,
    0.000003,
    -0.000003,
    0.000003,
    -0.000004,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000002,
    0.000003,
    -0.000003,
    0.000003,
    -0.000002,
    0.000003,
    -0.000003,
    0.000002,
    -0.000002,
    0.000002,
    -0.000003,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000001,
    0.000002,
    -0.000002,
    0.000002,
    -0.000001,
    0.000002,
    -0.000002,
    0.000002,
    -0.000001,
    0.000002,
    -0.000002,
    0.000001,
    -0.000001,
    0.000002,
    -0.000002,
    0.000001,
    -0.000001,
    0.000001,
    -0.000002,
    0.000001,
    -0.000001,
    0.000001,
    -0.000002,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    ],
    'imag': [
    0.000000,
    0.147621,
    -0.000001,
    0.000007,
    -0.000010,
    0.000005,
    -0.000006,
    0.000009,
    -0.000000,
    0.000008,
    -0.000001,
    0.000014,
    -0.000008,
    0.000003,
    -0.000009,
    0.000009,
    -0.000005,
    0.000002,
    -0.000007,
    0.000005,
    -0.000005,
    0.000005,
    -0.000023,
    0.000037,
    -0.000021,
    0.000022,
    -0.000006,
    0.000005,
    -0.000004,
    0.000014,
    -0.000007,
    0.000012,
    -0.000004,
    0.000002,
    -0.000010,
    0.000005,
    -0.000004,
    0.000003,
    -0.000001,
    0.000002,
    -0.000002,
    0.000005,
    -0.000003,
    0.000005,
    -0.000008,
    0.000002,
    -0.000002,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000002,
    -0.000003,
    0.000000,
    -0.000002,
    0.000000,
    -0.000001,
    0.000001,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000002,
    0.000002,
    -0.000000,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000001,
    0.000000,
    -0.000001,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000000,
    -0.000001,
    0.000001,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000001,
    0.000000,
    -0.000004,
    0.000004,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000001,
    0.000002,
    -0.000001,
    0.000002,
    -0.000000,
    0.000001,
    -0.000000,
    0.000002,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000000,
    0.000000,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000002,
    0.000001,
    -0.000001,
    0.000001,
    -0.000002,
    0.000000,
    -0.000002,
    0.000004,
    -0.000002,
    0.000003,
    -0.000002,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000004,
    0.000004,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000003,
    0.000003,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000002,
    0.000002,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000001,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000001,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000001,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    0.000000,
    -0.000000,
    ],
}