import { Route, Routes } from "react-router";
import { Appearances } from "./appearances";
import { InputDevices } from "./input-devices";

export function Settings() {
    return (
        <Routes>
            <Route path="input-devices" element={
                <InputDevices />
            } />
            <Route path="*" element={
                <Appearances />
            } />
        </Routes>
    )
}