import { css } from "@emotion/css"
import { Property } from 'csstype'
import { Text as GenericText, TextVariant } from '../component/text'
import { LocalisationInterpolationParam, useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface TextProps {
    /**
     * Rem size of text font. Rem is relative to font size of html tag.
     */
    fontSize?: number

    fontWeight?: Property.FontWeight

    fontStyle?: Property.FontStyle

    className?: string

    variant?: TextVariant

    onClick?: () => void
}

export function PersonalisedLocalisedText (props: TextProps & { textKey: string, textParam?: LocalisationInterpolationParam }) {
    const {
        fontSize,
        fontWeight,
        fontStyle,
        textKey,
        textParam,
        variant,
        className,
        onClick
    } = props
    const localisation = useLocalisationContext()
    const theme = useTheme()

    const classes = [
        className,
        css({
            color: theme.colors.secondaryColor.shade1
        })
    ].filter(x => x)

    return <GenericText variant={variant} fontSize={fontSize} fontWeight={fontWeight} fontStyle={fontStyle} className={classes.join(' ')} text={localisation.interpolate(textKey, textParam)} onClick={onClick} />
}

export function LocalisedText (props: TextProps & { textKey: string, textParam?: LocalisationInterpolationParam }) {
    const {
        fontSize,
        fontWeight,
        fontStyle,
        textKey,
        textParam,
        variant,
        className,
        onClick
    } = props
    const localisation = useLocalisationContext()
    const theme = useTheme()

    const classes = [
        className,
        css({
            color: theme.colors.primaryColor.shade6
        })
    ].filter(x => x)

    return <GenericText variant={variant} fontSize={fontSize} fontWeight={fontWeight} fontStyle={fontStyle} className={classes.join(' ')} text={localisation.interpolate(textKey, textParam)} onClick={onClick} />
}

export function NonlocalisedText (props: TextProps & { text: string }) {
    const {
        fontSize,
        fontWeight,
        fontStyle,
        text,
        variant,
        className,
        onClick
    } = props
    const theme = useTheme()

    const classes = [
        className,
        css({
            color: theme.colors.primaryColor.shade6
        })
    ].filter(x => x)

    return <GenericText variant={variant} fontSize={fontSize} fontWeight={fontWeight} fontStyle={fontStyle} className={classes.join(' ')} text={text}/>
}