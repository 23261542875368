import { css } from "@emotion/css"
import { PropsWithChildren } from "react"
import { Auto } from "../auto-layout"
import { MobileMenuStyle } from "./types"

interface LeftMenuItemProps {
    style?: MobileMenuStyle
    onClick?: () => void
}

export function MobileMenuItem (props: PropsWithChildren<LeftMenuItemProps>) {
    const {
        style,
        onClick,
        children
    } = props

    return (
        <Auto
            direction='horizontal'
            verticalAlignment='center'
            spacing={24}
            paddingLeft={32}
            paddingRight={32}
            paddingTop={16}
            paddingBottom={16}
            className={css({
                cursor: 'pointer',
                background: style?.backgroundColor,
                borderBottom: `1px solid ${style?.borderColor}`,
                color: style?.textColor
            })}
            onClick={onClick}
        >
            {children}
        </Auto>
    )
}