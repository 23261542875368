export type DeviceScreenOrientation = 'landscape' | 'portrait'

/**
 * Device breakpoints in pixel unit.
 * @remark If any value is going to be changed, do the same change in variable.scss file of grid layout component also
 */
type DeviceType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
const breakpoints: { device: DeviceType, minWidth: number }[] = [{
    device: 'xs' as const,
    minWidth: 0
}, {
    device: 'sm' as const,
    minWidth: 576
}, {
    device: 'md' as const,
    minWidth: 768
}, {
    device: 'lg' as const,
    minWidth: 992
}, {
    device: 'xl' as const,
    minWidth: 1200
}, {
    device: 'xxl' as const,
    minWidth: 1400
}]

const getDeviceBreakpoint = (device: DeviceType): number => {
    return breakpoints.find(x => x.device === device)?.minWidth ?? 0
}

const userAgent = {
    get mobile (): boolean {
        return /\b(Android|Windows Phone|iPad|iPod|BlackBerry|webOS|iPhone|IEMobile)\b/i.test(window?.navigator?.userAgent)
    }
}

const screen = {
    get width (): number {
        return window.innerWidth > 0 ? window.innerWidth : window.screen.width
    },
    get height (): number {
        return window.innerHeight > 0 ? window.innerHeight : window.screen.height
    },
    get pixelRatio (): number {
        return window?.devicePixelRatio ?? 1
    },
    get touchPoints (): number {
        return window?.navigator?.maxTouchPoints ?? (window?.navigator as any)?.msMaxTouchPoints ?? 0
    },
    get orientation (): DeviceScreenOrientation | undefined {
        const landscape = window?.matchMedia && window.matchMedia("(orientation: landscape)").matches
        const portrait = window?.matchMedia && window.matchMedia("(orientation: portrait)").matches
        return landscape ? 'landscape' : portrait ? 'portrait' : undefined
    },
    get coarsePointer (): boolean | undefined {
        return window?.matchMedia && window.matchMedia("(pointer:coarse)").matches
    },
    get finePointer (): boolean | undefined {
        return window?.matchMedia && window.matchMedia("(pointer:fine)").matches
    }
}

const phoneMediaQuery = () => `(max-width: ${getDeviceBreakpoint('sm') - 1}px), (max-height: ${getDeviceBreakpoint('sm') - 1}px)`

export const device = {
    device (device: DeviceType) {
        const breakpoint = breakpoints.find(x => x.device === device)
        return breakpoint ? window.matchMedia && window.matchMedia(`(min-width: ${breakpoint.minWidth}px)`).matches : false
    },
    get phone (): boolean {
        return window.matchMedia && window.matchMedia(phoneMediaQuery()).matches
    },
    get width () {
        return screen.width
    },
    get height () {
        return screen.height
    },
    get orientation (): DeviceScreenOrientation | undefined {
        return screen.orientation
    }
}

export const mediaQuery = {
    device (device: DeviceType) {
        const breakpoint = breakpoints.find(x => x.device === device)
        return breakpoint ? `@media (min-width: ${breakpoint.minWidth}px)` : ''
    },
    phone () {
        return `@media ${phoneMediaQuery()}`
    }
}