export function TwoNotesIcon() {
    return (
        <>
            <circle cx="6" cy="18" r="3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 18V5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 3L9 5" strokeWidth="2" strokeLinecap="round" />
            <path d="M21 7L9 9" strokeWidth="2" strokeLinecap="round" />
            <circle cx="18" cy="16" r="3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21 16V3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </>
    )
}