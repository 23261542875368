import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { AutoHug } from "../../component/auto-layout"
import { Col, Row } from "../../component/grid-layout"
import { Icon } from "../../hoc/icon"
import { useTheme } from "../../context/theme"
import { LocalisedText } from "../../hoc/text"
import { mediaQuery } from "../../util/device-detection"
import { NumericSlider } from "../../hoc/numeric-slider"
import { useMetronomeVolume, useSoundSettingsControl } from "../../context/player"

function checkIfParent (child: Element, parent: Element) {
    let node = child
    for (; node.parentElement && node !== parent; node = node.parentElement) {}
    return node === parent
}

const Container = styled.div({
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: '100%',
    right: 0,
    marginBottom: '16px',
    padding: '16px 32px',
    paddingBottom: '32px',
    borderRadius: '12px',
    zIndex: 3,

    [mediaQuery.phone()]: {
        minWidth: 'auto',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0
    }
})

const Header = styled.div({
    display: 'none',

    [mediaQuery.phone()]: {
        display: 'flex',
        flex: '0 1 0',
        padding: '16px 32px'
    }
})

const Content = styled.div({
    [mediaQuery.phone()]: {
        flex: '1 0 0',
        padding: '16px 32px 0',
        overflow: 'auto'
    }
})

const HiddenSettings = styled.div({
    height: 0,
    overflow: 'hidden',

    [mediaQuery.phone()]: {
        height: 'auto'
    }
})

interface SoundSettingsMenuProps extends SoundSettingsProps {
    exerciseId: string
    onClose?: () => void
}

function SoundSettingsMenu (props: SoundSettingsMenuProps) {
    const {
        exerciseId,
        onClose
    } = props
    const { colors } = useTheme()
    const control = useSoundSettingsControl (exerciseId)
    const metronomeVolume = useMetronomeVolume (exerciseId)

    const preventMouseEvent = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const changeMetronomeVolume = (volume: number) => {
        control.setMetronome(volume)
    }

    return (
        <Container
            className={css({
                backgroundColor: colors.primaryColor.shade1,
                border: `1px solid ${colors.primaryColor.shade2}`,
                boxShadow: `0px 0px 20px ${colors.primaryColor.shade2}`
            })}
            onClick={preventMouseEvent}
        >
            <Header className={css({
                borderBottom: `1px solid ${colors.primaryColor.shade2}`
            })}>
                <LocalisedText fontSize={1.5} textKey="sound-settings" className={css({
                    flex: '1 0 0'
                })} />
                <Icon icon="close" onClick={onClose} className={css({
                    flex: '0 1 24px'
                })}></Icon>
            </Header>
            <Content>
                <Row>
                    <Col all={12}>
                        <LocalisedText textKey="metronome-volume" />
                    </Col>
                </Row>
                <Row>
                    <Col all={12}>
                        <NumericSlider value={metronomeVolume} onChange={changeMetronomeVolume} />
                    </Col>
                </Row>
            </Content>
        </Container>
    )
}

interface SoundSettingsProps {
    exerciseId: string
}

export function SoundSettings (props: SoundSettingsProps) {
    const {
        exerciseId
    } = props
    const [soundSettings, setSoundSettings] = useState(false)
    const iconRef = useRef <SVGSVGElement> (null)
    const closeSoundSettings = (e?: MouseEvent) => {
        if (!e?.target || !iconRef.current || !checkIfParent(e.target as Element, iconRef.current)) {
            setSoundSettings(false)
        }
    }

    const toggleSoundSettings = (e: React.MouseEvent) => {
        setSoundSettings(!soundSettings)
    }

    useEffect(() => {
        document.addEventListener('click', closeSoundSettings)
        return () => {
            document.removeEventListener('click', closeSoundSettings)
        }
    })

    return (
        <AutoHug className={css({
            position: 'relative'
        })}>
            <Icon ref={iconRef} icon="volume" onClick={toggleSoundSettings} />
            {soundSettings && (
                <SoundSettingsMenu exerciseId={exerciseId} onClose={closeSoundSettings} />
            )}
        </AutoHug>
    )
}