import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { Auto } from "../../component/auto-layout";
import { useServices } from "../../context/services";
import { LocalisedText } from "../../hoc/text";
import { useAppDispatch } from "../../store";
import { EtudeBriefDetails } from "../../types";
import EtudeDetails from "./etude-details";

export function EtudeEditor () {
    const auth = useAuth()
    const services = useServices()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [etudes, setEtudes] = useState <EtudeBriefDetails[] | undefined> (undefined)

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(async () => {
                const result = await services.practiceAndHistoryClient.getMyEtudes()
                setEtudes (result)
            })
        }
    }, [auth.isAuthenticated])

    const onDelete = async (id: string) => {
        dispatch(async () => {
            if (etudes) {
                await services.practiceAndHistoryClient.deleteEtude(id)
                etudes.splice(etudes.findIndex(x => x.id === id), 1)
                setEtudes([...etudes])
            }
        })
    }

    const onEdit = (id: string) => {
        navigate(`/etude-editor/edit/${id}`)
    }

    const createEtude = () => {
        navigate(`/etude-editor/new`)
    }

    const signin = () => {
        auth.navigateTo('signin')
    }

    return (
        <>
        {auth.isAuthenticated ? (
            <Auto>
                {etudes && (
                    <>
                        {!etudes.length && (
                            <Auto horizontalAlignment="center" paddingTop={64} spacing={8}>
                                <LocalisedText textKey="no-created-etude" fontSize={1.4} />
                                <LocalisedText variant="link" textKey="created-first-etude" fontSize={1.4} onClick={createEtude} />
                            </Auto>
                        )}
                        {etudes.map(etude => (
                            <EtudeDetails key={`etude-${etude.id}`} etude={etude} onDelete={onDelete} onEdit={onEdit} />
                        ))}
                    </>
                )}
            </Auto>
        ) : (
            <Auto
                verticalAlignment="center"
                horizontalAlignment="center"
                spacing={4}
                className={css({
                    height: '60vh'
                })}
            >
                <LocalisedText textKey="message-to-signin" fontSize={1.4} />
                <LocalisedText textKey='signin' variant="link" fontSize={1.4} onClick={signin} />
            </Auto>
        )}
    </>
    )
}