import { PropsWithChildren } from "react"
import { Message as GenericMessage,  MessageStyle } from "../component/message"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface LocalisedMessageProps {
    type?: 'success' | 'info' | 'warning' | 'error'
    hideIcon?: boolean
    titleKey?: string
    textKey?: string
    timeout?: number
    onClose?: () => void
}

export function LocalisedMessage (props: PropsWithChildren<LocalisedMessageProps>) {
    const {
        type,
        hideIcon,
        titleKey,
        textKey,
        timeout,
        onClose,
        children
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const style: MessageStyle = {
        backgroundColor: colors.primaryColor.shade1
    }

    return <GenericMessage
        type={type}
        hideIcon={hideIcon}
        title={titleKey && localisationStrings[titleKey]}
        text={textKey && localisationStrings[textKey]}
        timeout={timeout}
        onClose={onClose}
        style={style}
    >
        {children}
    </GenericMessage>
}

interface NonlocalisedMessageProps {
    type?: 'success' | 'info' | 'warning' | 'error'
    hideIcon?: boolean
    title?: string
    text?: string
    timeout?: number
    onClose?: () => void
}

export function NonlocalisedMessage (props: PropsWithChildren<NonlocalisedMessageProps>) {
    const {
        type,
        hideIcon,
        title,
        text,
        timeout,
        onClose,
        children
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const style: MessageStyle = {
        backgroundColor: colors.primaryColor.shade1
    }

    return <GenericMessage
        type={type}
        hideIcon={hideIcon}
        title={title}
        text={text}
        timeout={timeout}
        onClose={onClose}
        style={style}
    >
        {children}
    </GenericMessage>
}