import { useTheme } from '../context/theme'
import { useLocalisationContext } from '../context/localisation'
import { CollapsablePanel as GenericCollapsablePanel, CollapsablePanelStyle } from '../component/collapsable-panel'
import { PropsWithChildren } from 'react'

interface CollapsablePanelProps {
    labelKey?: string
    labelParams?: { [key: string]: string | number }
    collapsed?: boolean
    disable?: boolean
    className?: string
}

export function CollapsablePanel (props: PropsWithChildren<CollapsablePanelProps>) {
    const {
        labelKey,
        labelParams,
        collapsed,
        disable,
        className,
        children
    } = props
    const context = useLocalisationContext()
    const { colors } = useTheme()

    const style: CollapsablePanelStyle = {
        borderColor: colors.primaryColor.shade2,
        textColor: colors.primaryColor.shade6
    }
    
    return <GenericCollapsablePanel label={context.interpolate(labelKey, labelParams)} collapsed={collapsed} disable={disable} className={className} style={style}>{children}</GenericCollapsablePanel>
}