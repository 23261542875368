import { PropsWithChildren } from "react";
import { CommonTextProps } from "./types";

export function H3 (props: PropsWithChildren<CommonTextProps>) {
    const {
        className,
        title,
        onClick,
        children
    } = props

    return (
        <h3 className={className} onClick={onClick} title={title}>{children}</h3>
    )
}