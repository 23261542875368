import { css } from "@emotion/css";
import { Auto, AutoFill, AutoGap, AutoHug } from "../../../component/auto-layout";
import { Col, Row } from "../../../component/grid-layout";
import { useTheme } from "../../../context/theme";
import { LocalisedText } from "../../../hoc/text";
import { ColorItem } from "./color-item";
import { ThemeItem } from "./theme-item";

export function Appearances () {
    const theme = useTheme()
    
    return (
        <Auto>
            <LocalisedText textKey="appearances" variant="h2"/>
            <AutoGap size={32} />
            <LocalisedText textKey="theme" variant="h3" />
            <AutoGap size={16} />
            <AutoFill direction="horizontal" className={css({ maxWidth: '600px' })}>
                <Row>
                    <Col all={6} md={3}><ThemeItem theme="system-default"/></Col>
                    <Col all={6} md={3}><ThemeItem theme="light" /></Col>
                    <Col all={6} md={3}><ThemeItem theme="dimmed" /></Col>
                    <Col all={6} md={3}><ThemeItem theme="dark" /></Col>
                </Row>
            </AutoFill>
            <AutoGap size={32} />
            <LocalisedText textKey="secondary-color" variant="h3" />
            <AutoGap size={16} />
            <AutoFill direction="horizontal" spacing={16} className={css({ maxWidth: '600px', flexWrap: 'wrap' })}>
                <AutoHug><ColorItem secondaryColor="blue"/></AutoHug>
                <AutoHug><ColorItem secondaryColor="green"/></AutoHug>
                <AutoHug><ColorItem secondaryColor="orange"/></AutoHug>
                <AutoHug><ColorItem secondaryColor="purple"/></AutoHug>
                <AutoHug><ColorItem secondaryColor="red"/></AutoHug>
                <AutoHug><ColorItem secondaryColor="yellow"/></AutoHug>
            </AutoFill>
        </Auto>
    )
}