import styled from "@emotion/styled";
import { PropsWithChildren } from "react";
import { Icon, IconType } from "../icon";
import { ListStyle } from "./types";

const LI = styled.li({
    marginBottom: '8px',

    ':last-child': {
        marginBottom: 0
    }
})

const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
})

const Content = styled.div({
    display: 'inline'
})

interface ListItemProps {
    icon?: IconType
    style?: ListStyle
}

export function ListItem (props: PropsWithChildren<ListItemProps>) {
    const {
        icon,
        style,
        children
    } = props

    const iconStyle = {
        stroke: style?.textColor
    }

    return (
        <LI>
            <Container>
                {icon && (
                    <Icon icon={icon} style={iconStyle} size={1.2} />
                )}
                <Content>
                    {children}
                </Content>
            </Container>
        </LI>
    )
}
