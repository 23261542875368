import {
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom"
import Main from './page/main';
import { EtudeForm } from "./page/etude-form";
import { Settings } from './page/settings';
import { EtudeEditor } from "./page/etude-editor";
import { EtudeDetails } from "./page/etude-details";
import { SubscribedEtudes } from "./page/subscribed-etudes";
import { Player } from "./page/player";

const EtudeFormWithId = () => {
  const { id } = useParams()
  const navigate = useNavigate ()
  
  if (!id) {
    navigate ('/')
    return <></>
  }

  return (
    <EtudeForm id={id} />
  )
}

const EtudeDetailsWithId = () => {
  const { id } = useParams()
  const navigate = useNavigate ()

  if (!id) {
    navigate ('/')
    return <></>
  }

  return <EtudeDetails id={id} />
}

const PlayerWithId = () => {
  const { etudeId, exerciseId } = useParams ()
  const navigate = useNavigate ()

  if (!etudeId || !exerciseId) {
    navigate ('/')
    return <></>
  }

  return <Player etudeId={etudeId} exerciseId={exerciseId} />
}

export default function App() {
  return (
      <Routes>
        <Route path="/subscribed-etudes" element={
          <SubscribedEtudes />
        } />
        <Route path="/etude-editor" element={
          <EtudeEditor />
        } />
        <Route path="/etude-editor/new" element={
          <EtudeForm />
        } />
        <Route path="/etude-editor/edit/:id" element={
          <EtudeFormWithId />
        } />
        <Route path="/play/:etudeId/:exerciseId" element={
          <PlayerWithId />
        } />
        <Route path="/etude/:id" element={
          <EtudeDetailsWithId />
        } />
        <Route path="/settings/*" element={
          <Settings />
        } />
        <Route path="*" element={
          <Main />
        } />
      </Routes>
  )
}
