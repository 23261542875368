import styled from "@emotion/styled"

interface AutoGapProps {
    size: number
}

export function AutoGap (props: AutoGapProps) {
    const {
        size
    } = props

    const Div = styled.div({
        width: `${size}px`,
        height: `${size}px`
    })
    
    return <Div />
}