import { AutoHug } from "../component/auto-layout"
import { Button, ButtonStyle } from "../component/button"
import { Icon } from "../component/icon"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface SubscribeButtonProps {
    subscribed: boolean
    onSubscribe?: () => void
    onUnsubscribe?: () => void
}

export function SubscribeButton (props: SubscribeButtonProps) {
    const {
        subscribed,
        onSubscribe,
        onUnsubscribe
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const style: ButtonStyle = {
        backgroundColor: colors.primaryColor.shade1,
        borderColor: colors.primaryColor.shade2,
        textColor: colors.primaryColor.shade6,
        highlightColor: colors.primaryColor.shade5
    }
    
    const iconStyle = {
        stroke: colors.primaryColor.shade1
    }
    const click = () => {
        if (subscribed) {
            onUnsubscribe && onUnsubscribe()
        } else {
            onSubscribe && onSubscribe()
        }
    }

    return (
        <Button variant='inverted-round' style={style} onClick={click}>
            <AutoHug direction="horizontal" spacing={4}>
                <Icon icon={subscribed ? 'minus' : 'plus'} size={1.2} style={iconStyle} />
                {localisationStrings[subscribed ? 'unsubscribe' : 'subscribe']}
            </AutoHug>
        </Button>
    )
}