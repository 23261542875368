import { css, cx } from "@emotion/css"
import { Property } from 'csstype'
import { CommonTextProps } from "./types"
import { H1 } from "./h1"
import { H2 } from "./h2"
import { H3 } from "./h3"
import { Paragraph } from "./paragraph"
import { Span } from "./span"
import styles from './index.module.scss'
import { Link } from "./link"

export type TextVariant = 'h1' | 'h2' | 'h3' | 'paragraph' | 'link'

const variantMapping: { [key in TextVariant]: (props: CommonTextProps) => JSX.Element } = {
    'h1': H1,
    'h2': H2,
    'h3': H3,
    'paragraph': Paragraph,
    'link': Link
}

interface TextProps extends CommonTextProps {
    /**
     * Rem size of text font. Rem is relative to font size of html tag.
     */
    fontSize?: number

    fontWeight?: Property.FontWeight

    fontStyle?: Property.FontStyle

    variant?: TextVariant

    color?: string

    text: string

    onClick?: () => void
}

export function Text (props: TextProps) {
    const {
        fontSize,
        fontWeight,
        fontStyle,
        color,
        variant,
        text,
        className,
        onClick
    } = props

    const classes = [
        variant ? styles[variant] : undefined,
        css({
            fontWeight,
            fontStyle,
            fontSize: fontSize ? `${fontSize}rem !important` : undefined,
            cursor: onClick ? 'pointer' : undefined
        }),
        css({
            wordBreak: 'break-word',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            // max-height: 2.4rem;
            // max-width: 100%;
            whiteSpace: 'normal',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
        }),

        css({
            color
        }),
        className
    ].filter(x => x)

    const TextElement = variant ? (variantMapping[variant] ?? Span) : Span

    return <TextElement className={classes.join(' ')} onClick={onClick} title={text}>{text}</TextElement>
}