import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { mediaQuery } from "../util/device-detection";
import { Auto, AutoFill, AutoHug } from "./auto-layout";
import { Icon } from "./icon";
import { Text } from "./text";

const Container = styled.div({
    width: '100%',
    marginBottom: '32px'
})

const UpperContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    padding: '8px 0',
    paddingBottom: '16px',

    [mediaQuery.device('md')]: {
        flexDirection: 'row',
        gap: '32px',
        padding: '16px 0'
    }
})

const BelowContainer = styled.div({
    width: '100%',
})

const ImageContainer = styled.div({
    width: '100%',

    [mediaQuery.device('md')]: {
        width: '300px'
    }
})

const DetailsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: '0 1 0',

    [mediaQuery.device('md')]: {
        flex: '1 0 0'
    }
})

const PreviewImage = styled.img({
    width: '100%',
    backgroundColor: '#fafafa',
    borderRadius: '8px',
    overflow: 'hidden',
})

const DummyPreviewImage = styled.div({
    width: '100%',
    height: '100%',
    opacity: '.2'
})

const TitleImage = styled.img({
    width: '32px',
    height: '32px',
    borderRadius: '32px',
})

export interface EtudeDetailsStyle {
    primaryTextColor?: string
    secondaryTextColor?: string
}

interface EtudeDetailsProps {
    title: string
    subtitle?: string
    description?: string
    style?: EtudeDetailsStyle
    previewImageSource?: string
    previewImageElement?: ReactNode
    titleImageSource?: string
    titleImageElement?: ReactNode
    belowSubtitleDetails?: ReactNode
    belowDescriptionDetails?: ReactNode
    belowDetails?: ReactNode
    hideActionButtons?: boolean
    hideImageInPhone?: boolean
    onEdit?: () => void
    onDelete?: () => void
}

export function EtudeDetails (props: EtudeDetailsProps) {
    const {
        title,
        subtitle,
        description,
        style,
        previewImageSource,
        previewImageElement,
        titleImageSource,
        titleImageElement,
        belowSubtitleDetails,
        belowDescriptionDetails,
        belowDetails,
        hideActionButtons,
        hideImageInPhone,
        onEdit,
        onDelete
    } = props

    const iconStyle = {
        stroke: style?.secondaryTextColor
    }

    return (
        <Container>
            <UpperContainer>
                <ImageContainer className={css({
                    [mediaQuery.phone()]: {
                        display: hideImageInPhone ? 'none' : undefined
                    }
                })}>
                    {previewImageElement ? (
                        previewImageElement
                    ) : previewImageSource ? (
                        <PreviewImage src={previewImageSource}  />
                    ) : (
                        <DummyPreviewImage className={css({
                            backgroundColor: style?.primaryTextColor
                        })} />
                    )}
                </ImageContainer>
                <DetailsContainer>
                    <Auto direction="horizontal" spacing={16}>
                        {titleImageElement ? (
                            titleImageElement
                        ) : titleImageSource ? (
                            <TitleImage src={previewImageSource} />
                        ) : (
                            undefined
                        )}
                        <AutoFill verticalAlignment="center">
                            <AutoHug direction="horizontal" spacing={16} verticalAlignment="center">
                                <Text text={title} variant="h2" fontWeight={600} color={style?.primaryTextColor}></Text>
                                {hideActionButtons !== true && (
                                    <AutoHug direction="horizontal" spacing={8}>
                                        <Icon icon="edit" style={iconStyle} size={1.2} onClick={onEdit} />
                                        <Icon icon="delete" style={iconStyle} size={1.2} onClick={onDelete} />
                                    </AutoHug>
                                )}
                            </AutoHug>
                            {subtitle && (
                                <Text text={subtitle} fontSize={0.9} color={style?.secondaryTextColor}></Text>
                            )}
                        </AutoFill>
                    </Auto>
                    {belowSubtitleDetails && (
                        <Auto>
                            {belowSubtitleDetails}
                        </Auto>
                    )}
                    {description && (
                        <Auto paddingTop={4}>
                            <Text text={description} color={style?.primaryTextColor}></Text>
                        </Auto>
                    )}
                    {belowDescriptionDetails && (
                        <Auto paddingTop={16}>
                            {belowDescriptionDetails}
                        </Auto>
                    )}
                </DetailsContainer>
            </UpperContainer>
            {belowDetails && (
                <BelowContainer>
                    {belowDetails}
                </BelowContainer>
            )}
        </Container>
    )
}