type Arguments<T extends Function> = T extends (arg: infer A1) => void ? [A1] : [unknown]

export class EventEmitter<Events extends { [event: string]: Function }> {
    private handlers: { [event in keyof Events]?: Function[] }

    constructor () {
        this.handlers = {}
    }

    on <Event extends keyof Events> (event: Event, cb: Events[Event]) {
        if (!this.handlers[event]) {
            this.handlers[event] = []
        }

        this.handlers[event]?.push(cb)
    }

    off <Event extends keyof Events> (event: Event, cb: Events[Event]) {
        if (this.handlers[event]) {
            const index = this.handlers[event]?.indexOf(cb)
            if (index !== undefined && index >= 0) {
                this.handlers[event]?.splice(index, 1)
            }
        }
    }

    trigger <Event extends keyof Events> (event: Event, ...arg: Arguments<Events[Event]>) {
        this.handlers[event]?.forEach(handler => handler(...arg))
    }
}