import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { PropsWithChildren, useEffect, useRef } from "react"
import { constants } from "../../constants"
import { mediaQuery } from "../../util/device-detection"
import { useNoteTrackerPosition, useRenderingDetails, useRenderingDetailsControl } from "../../context/player"
import { SheetMusic as HOCSheetMusic } from "../../hoc/sheet-music"

const Container = styled.div({
    minHeight: `calc(100vh - ${constants.ui.height.playerHeader + constants.ui.height.playerControlPanel + 300}px)`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: `${constants.ui.height.playerHeader}px`,
    marginBottom: `${constants.ui.height.playerControlPanel}px`,

    [mediaQuery.phone()]: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        minHeight: 'auto',
        marginTop: constants.ui.height.header,
        padding: '0 32px',
        paddingTop: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
})

const InnerContainer = styled.div({
    
})

interface SheetMusicProps {
    exerciseId: string
}

export function SheetMusic (props: PropsWithChildren<SheetMusicProps>) {
    const {
        exerciseId
    } = props
    const containerRef = useRef <HTMLDivElement> (null)
    const renderingDetails = useRenderingDetails (exerciseId)
    const renderingDetailsControl = useRenderingDetailsControl (exerciseId)
    const noteTrackerPosition = useNoteTrackerPosition (exerciseId)

    const setWidth = () => {
        const width = containerRef.current?.getBoundingClientRect().width
        if (width) {
            renderingDetailsControl.setWidth(width)
        }
    }

    useEffect (() => {
        setWidth ()
    }, [exerciseId, renderingDetails?.width])

    useEffect (() => {
        window.addEventListener('resize', setWidth)

        return () => {
            window.removeEventListener('resize', setWidth)
        }
    })

    return (
        <Container ref={containerRef}>
            {renderingDetails && (
                <InnerContainer className={css({
                    [mediaQuery.phone()]: {
                        marginLeft: `${noteTrackerPosition && noteTrackerPosition.x > 20 ? (20 - noteTrackerPosition.x) * 5 : 0}px`,
                        width: renderingDetails ? `${renderingDetails.width * 5}px` : undefined,
                        minWidth: renderingDetails ? `${renderingDetails.width * 5}px` : undefined
                    }
                })}>
                    <HOCSheetMusic renderingDetails={renderingDetails}>
                        {props.children}
                    </HOCSheetMusic>
                </InnerContainer>
            )}
        </Container>
    )
}