import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { PropsWithChildren, useState } from "react";
import { Hidden } from "../component/grid-layout";
import { BottomMenu } from "./bottom-menu";
import { Header } from "./header";
import { LeftMenu } from "./left-menu";
import { MobileSettingsMenu } from "./mobile-settings-menu";
import { constants } from '../constants'
import { mediaQuery } from "../util/device-detection";
import { useLocation } from "react-router";
import { ProfileMenu } from "./profile-menu";

const HeaderContainer = styled.div({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: `${constants.ui.height.header}px`,
    zIndex: 2
})

const HeaderContent = styled.div({
    position: 'relative',
    margin: '0 auto',
    maxWidth: `${constants.ui.width.pageMaxWidth}px`,
    height: '100%'
})

const ContentContainer = styled.div({
    margin: '0 auto',
    width: '100%',
    maxWidth: `${constants.ui.width.pageMaxWidth}px`
})

const Content = styled.div({
    marginTop: `${constants.ui.height.header}px`,
    marginBottom: `${constants.ui.height.bottomMenu}px`,
    maxWidth: `${constants.ui.width.pageMaxWidth}px`,
    padding: '12px 16px',
})

export function MasterPage (props: PropsWithChildren<{}>) {
    const {
        children
    } = props
    const [openProfileMenu, setOpenProfileMenu] = useState (false)
    const [mobileSettingsMenu, setMobileSettingsMenu] = useState (false)
    const location = useLocation()
    
    const headerHidden = !![
        /\/play\/.*/
    ].find (x => x.test(location.pathname))

    const classes = {
        leftMenu: css({
            position: 'absolute',
            top: '56px',
            left: '0'
        })
    }
    return (
        <>
            <HeaderContainer>
                <HeaderContent>
                    <Header onMobileSettingsClick={() => setMobileSettingsMenu(true)} onProfileMenuClick={() => setOpenProfileMenu(!openProfileMenu)} />
                    {!headerHidden && (
                        <Hidden md andSmaller>
                            <LeftMenu className={classes.leftMenu} />
                        </Hidden>
                    )}
                    {openProfileMenu && (
                        <ProfileMenu className={css({
                            position: 'absolute',
                            top: '100%',
                            right: '24px'
                        })} onClose={() => setOpenProfileMenu(false)} />
                    )}
                </HeaderContent>
            </HeaderContainer>
            <ContentContainer>
                <Content className={css({
                    marginLeft: 0,

                    [mediaQuery.device('lg')]: {
                        marginBottom: 0,
                        marginLeft: headerHidden ? 0 : `${constants.ui.width.leftMenu}px`
                    }
                })}>
                    {children}
                </Content>
            </ContentContainer>
            {!headerHidden && (
                <Hidden lg andBigger>
                    <BottomMenu />
                </Hidden>
            )}
            {mobileSettingsMenu && (
                <MobileSettingsMenu onClose={() => setMobileSettingsMenu(false)} />
            )}
        </>
    )
}