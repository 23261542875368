import { Auto, AutoFill, AutoHug } from "../auto-layout";
import { Search } from "./search";
import { HeaderStyle } from "./types";
import { Text } from "../text";
import { Icon } from "../icon";
import React, { ReactNode, useState } from "react";
import { css } from "@emotion/css";
import { Signin } from "./signin";
import { Hidden } from "../grid-layout";

export * from './types'

interface HeaderProps {
    searchLabel: string
    searchText?: string
    signinText: string
    style?: HeaderStyle
    profileAuthenticated?: boolean
    profileElement: ReactNode
    onSearch?: (text: string) => void
    onAddEtudeClick?: () => void
    onSettingsClick?: () => void
    onMobileSettingsClick?: () => void
    onProfileClick?: () => void
    onSigninClick?: () => void
    onBrandClick?: () => void
}

export function Header (props: HeaderProps) {
    const {
        style,
        searchLabel,
        searchText,
        signinText,
        profileAuthenticated,
        profileElement,
        onBrandClick,
        onSearch,
        onAddEtudeClick,
        onProfileClick,
        onSigninClick,
        onSettingsClick,
        onMobileSettingsClick
    } = props

    const [showSearchBar, setShowSearchBar] = useState (false)

    const showSearch = () => {
        setShowSearchBar(true)
    }

    const profileClick = (e: React.MouseEvent) => {
        onProfileClick && onProfileClick()
        e.stopPropagation()
    }

    return (
        <AutoFill direction="horizontal" paddingLeft={24} paddingRight={24} className={css({
            background: style?.backgroundColor
        })}>
            {showSearchBar ? (
                <>
                    <AutoFill direction="horizontal" horizontalAlignment="center" verticalAlignment="center" spacing={16}>
                        <Search style={style} label={searchLabel} text={searchText} onSearch={onSearch} />
                        <AutoHug className={css({
                            cursor: 'pointer'
                        })} onClick={() => setShowSearchBar(false)}>
                            <Icon icon="close"></Icon>
                        </AutoHug>
                    </AutoFill>
                </>
            ) : (
                <>
                    <Auto direction="horizontal" width={200} height='fill' verticalAlignment="center">
                        <AutoHug className={css({
                            cursor: 'pointer'
                        })} onClick={onBrandClick}>
                            <Text fontSize={2} fontWeight={700} fontStyle='italic' text="etudee" />
                        </AutoHug>
                    </Auto>
                    <AutoFill horizontalAlignment="center" verticalAlignment="center">
                        {/* <Hidden md andSmaller>
                            <Search style={style} label={searchLabel} text={searchText} onSearch={onSearch} />
                        </Hidden> */}
                    </AutoFill>
                    <Auto direction="horizontal" width={200} height='fill' verticalAlignment="center" horizontalAlignment="right" spacing={32}>
                        <AutoHug direction="horizontal" spacing={24}>
                            {/* <Hidden lg andBigger>
                                <AutoHug className={css({
                                    cursor: 'pointer'
                                })} onClick={showSearch}>
                                    <Icon icon="search" />
                                </AutoHug>
                            </Hidden> */}
                            {profileAuthenticated && (
                                <AutoHug className={css({
                                    cursor: 'pointer'
                                })} onClick={onAddEtudeClick}>
                                    <Icon icon="squared-plus" />
                                </AutoHug>
                            )}
                            <Hidden md andSmaller>
                                <AutoHug className={css({
                                    cursor: 'pointer'
                                })} onClick={onSettingsClick}>
                                    <Icon icon="gear" />
                                </AutoHug>
                            </Hidden>
                            <Hidden lg andBigger>
                                <AutoHug className={css({
                                    cursor: 'pointer'
                                })} onClick={onMobileSettingsClick}>
                                    <Icon icon="gear" />
                                </AutoHug>
                            </Hidden>
                        </AutoHug>
                        {profileAuthenticated ? (
                            <AutoHug className={css({
                                cursor: 'pointer'
                            })} onClick={profileClick}>
                                {profileElement}
                            </AutoHug>
                        ) : (
                            <Signin text={signinText} style={style} onClick={onSigninClick} />
                        )}
                    </Auto>
                </>
            )}
        </AutoFill>
    )
}