import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

const Background = styled.div({
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.2
})

const Content = styled.div({
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export interface OverlayStyle {
    color?: string
}

interface OverlayProps {
    open?: boolean
    style?: OverlayStyle
    className?: string
    zIndex?: number
    onClick?: () => void
}

export function Overlay (props: PropsWithChildren<OverlayProps>) {
    const {
        open,
        style,
        className,
        zIndex,
        children,
        onClick
    } = props

    return open ? (
        <>
            <Background
                className={css({
                    backgroundColor: style?.color,
                    zIndex: zIndex ?? 99
                })} />
            <Content
                className={css({
                    zIndex: zIndex ?? 99
                })}
                onClick={onClick}
            >
                {children}
            </Content>
        </>
    ) : <></>
}