import { RecommendationsTypes } from '@mom/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface RecommendationState {
    recommendations: RecommendationsTypes.Recommendation[]
    lastKey?: string
}
  
const initialState: RecommendationState = {
    recommendations: []
}

export default createSlice({
    name: 'recommendation',
    initialState,
    reducers: {
        setRecommendations (state, payload: PayloadAction<RecommendationsTypes.GetRecommendationsResponse>) {
            const {
                recommendations,
                lastKey
            } = payload.payload
            
            state.recommendations = recommendations
            state.lastKey = lastKey
        }
    }
})
