import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Auto, AutoFill } from "./auto-layout";
import { Text } from "./text";

const ImageContainer = styled.div({
    width: '100%'
})

const PreviewImage = styled.img({
    width: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fafafa'
})

const TitleImage = styled.img({
    width: '32px',
    height: '32px',
    borderRadius: '32px',
})

const DummyPreviewImage = styled.div({
    width: '100%',
    height: '100%',
    opacity: '.2'
})

const DummyTitleImage = styled.div({
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    opacity: '.2'
})

export interface EtudeCardStyle {
    primaryTextColor?: string
    secondaryTextColor?: string
}

interface EtudeCardProps {
    title: string
    subtitle?: string
    style?: EtudeCardStyle
    previewImageSource?: string
    previewImageElement?: ReactNode
    hideTitleImage?: boolean
    titleImageSource?: string
    titleImageElement?: ReactNode
    additionalDetails?: ReactNode
    onClick?: () => void
}

export function EtudeCard (props: EtudeCardProps) {
    const {
        title,
        subtitle,
        style,
        previewImageSource,
        previewImageElement,
        hideTitleImage,
        titleImageSource,
        titleImageElement,
        additionalDetails,
        onClick
    } = props

    return (
        <Auto direction="vertical" padding={8} spacing={8} paddingBottom={32}>
            <AutoFill>
                <ImageContainer onClick={onClick} className={css({
                    cursor: onClick ? 'pointer' : undefined
                })}>
                    {previewImageElement ? (
                        previewImageElement
                    ) : previewImageSource ? (
                        <PreviewImage src={previewImageSource} />
                    ) : (
                        <DummyPreviewImage className={css({
                            backgroundColor: style?.primaryTextColor
                        })} />
                    )}
                </ImageContainer>
            </AutoFill>
            <Auto direction="horizontal" spacing={16} paddingTop={8}>
                {hideTitleImage !== true && (
                    <Auto width={32} height={32}>
                        {titleImageElement ? (
                            titleImageElement
                        ) : titleImageSource ? (
                            <TitleImage src={previewImageSource} />
                        ) : (
                            <DummyTitleImage className={css({
                                backgroundColor: style?.primaryTextColor
                            })} />
                        )}
                    </Auto>
                )}
                <AutoFill direction="horizontal" verticalAlignment="center">
                    <Auto direction="vertical" spacing={4}>
                        <Text text={title} fontWeight={600} color={style?.primaryTextColor} onClick={onClick} className={css({
                            cursor: onClick ? 'pointer' : undefined
                        })}></Text>
                        {subtitle && (
                            <Text text={subtitle} fontSize={0.9} color={style?.secondaryTextColor}></Text>
                        )}
                    </Auto>
                </AutoFill>
            </Auto>
            {Boolean(additionalDetails) && (
                additionalDetails
            )}
        </Auto>
    )
}