import { PropsWithChildren } from "react";
import { Modal as GenericModal, ModalClassName, ModalStyle } from "../component/modal";
import { useTheme } from "../context/theme";

interface ModalProps {
    open?: boolean
    className?: ModalClassName
    onClose?: () => void
}

export function Modal (props: PropsWithChildren<ModalProps>) {
    const {
        open,
        className,
        children,
        onClose
    } = props
    const { colors } = useTheme ()

    const style: ModalStyle = {
        overlayColor: colors.primaryColor.shade6,
        backgroundColor: colors.primaryColor.shade1
    }

    return <GenericModal open={open} style={style} className={className} onClose={onClose}>{children}</GenericModal>
}