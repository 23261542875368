import { PracticeAndHistoryTypes } from "@mom/types";
import { EtudeBriefDetails, EtudeDetails, EtudeDetailsResponse, EtudeExerciseDetails, UserNoteToEvaluate } from "../types";
import HttpClient, { HttpClientConfiguration } from "./client";

interface PracticeAndHistoryClientOptions extends HttpClientConfiguration {
    
}

export class PracticeAndHistoryClient {
    private httpClient: HttpClient
    constructor (options: PracticeAndHistoryClientOptions) {
        this.httpClient = new HttpClient(options)
    }

    getEtude (id: string): Promise<EtudeDetailsResponse> {
        return this.httpClient.get(`/etude/${id}`)
    }

    getExercise (etudeId: string, exerciseId: string): Promise<EtudeExerciseDetails> {
        return this.httpClient.get(`/etude/${etudeId}/exercise/${exerciseId}`)
    }

    getPreviewImageUploadLink (id: string): Promise<{ id: string, url: string, fields: { [key: string]: string } }> {
        return this.httpClient.get(`/preview-image/upload-link/${id}`)
    }

    async uploadPreviewImage (etudeId: string, dataUrl: string, options?: { onUploadProgress?: (event: { percentage: number }) => void }) : Promise<{ id: string, file: File, success: boolean }[]> {
        const [metaDataSection, base64DataSection] = dataUrl.split(';')
        const [,mimeString] = metaDataSection.split(':')
        const [,base64Data] = base64DataSection.split(',')
        const byteString = atob(base64Data);

        var array = [];
        for(var i = 0; i < byteString.length; i++) {
            array.push(byteString.charCodeAt(i));
        }
        const blob = new Blob([new Uint8Array(array)], {type: mimeString});
        const uploadLink = await this.getPreviewImageUploadLink(etudeId)

        if (options?.onUploadProgress) {
            options.onUploadProgress({ percentage: 0 })
        }

        var form = new FormData()
        for (const key in uploadLink.fields) {
            form.append(key, uploadLink.fields[key])
        }
        form.append('file', blob)

        return this.httpClient.post(uploadLink.url, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            disableAuth: true,
            onUploadProgress: (event) => {
                if (options?.onUploadProgress) {
                    options.onUploadProgress ({
                        percentage: event.percentage
                    })
                }
            }
        })
    }

    createEtude (details: EtudeDetails): Promise<{ id: string }> {
        return this.httpClient.post('/etude', details)
    }

    updateEtude (id: string, details: EtudeDetails): Promise<void> {
        return this.httpClient.put(`/etude/${id}`, details)
    }

    deleteEtude (id: string): Promise<void> {
        return this.httpClient.delete(`/etude/${id}`)
    }

    getMyEtudes (): Promise<EtudeBriefDetails[]> {
        return this.httpClient.get(`/my-etudes`)
    }

    subscribe (etudeId: string) {
        return this.httpClient.post(`/subscribed-etude/${etudeId}/subscription`, {})
    }

    unsubscribe (etudeId: string) {
        return this.httpClient.delete(`/subscribed-etude/${etudeId}/subscription`)
    }

    getSubscriptionStatus (etudeId: string) : Promise<{ subscribed: boolean }> {
        return this.httpClient.get(`/subscribed-etude/${etudeId}/subscription`)
    }

    getSubscribedEtudes () {
        return this.httpClient.get <EtudeDetailsResponse[]> ('/subscribed-etudes')
    }

    getSubscribedExercises () {
        return this.httpClient.get <PracticeAndHistoryTypes.GetSubscribedExercisesResponse> ('/subscribed-etudes/exercises')
    }

    postUserNotes (exerciseId: string, repetition: number, userNotes?: UserNoteToEvaluate[]) {
        return this.httpClient.post ('/user-notes', {
            exerciseId,
            repetition,
            userNotes
        })
    }
}