import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "../../component/grid-layout";
import { useServices } from "../../context/services";
import { useTheme } from "../../context/theme";
import { ColorSelector } from "../../hoc/color-selector";
import { Icon } from "../../hoc/icon";
import { Modal } from "../../hoc/modal";
import { PreviewSheetMusic } from "../../hoc/sheet-music";
import { Tab, Tabs } from "../../hoc/tabs";
import { LocalisedText } from "../../hoc/text";
import { Upload } from "../../hoc/upload";
import { actions, useAppDispatch, useAppSelector } from "../../store";
import { mediaQuery } from "../../util/device-detection";

const SupportedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg'
]

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',

    [mediaQuery.phone()]: {
        height: '100%'
    }
})

const Header = styled.div({
    display: 'none',

    [mediaQuery.phone()]: {
        display: 'flex',
        flex: '0 1 0',
        padding: '16px 32px'
    }
})

const Content = styled.div({
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '16px',

    [mediaQuery.phone()]: {
        flex: '1 0 0',
        overflow: 'auto'
    }
})

const UpperSection = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [mediaQuery.device('sm')]: {
        flexDirection: 'row',
    }
})

const CanvasContainer = styled.div({
    [mediaQuery.device('sm')]: {
        flex: '1 0 0'
    }
})

const Canvas = styled.canvas({
    backgroundColor: '#fff',
    borderRadius: '12px',
    width: '100%'
})

const PropertiesContainer = styled.div({
    width: '200px',

    [mediaQuery.device('sm')]: {
        flex: '0 1 200px'
    }
})

const LowerSection = styled.div({
    height: '300px',

    [mediaQuery.phone()]: {
        flex: '1 0 0'
    }
})

interface ThumbnailEditorProps {
    onClose: () => void
}

export function ThumbnailEditor (props: ThumbnailEditorProps) {
    const {
        onClose
    } = props
    const canvasRef = useRef <HTMLCanvasElement> (null)
    const { colors } = useTheme()
    const { config } = useServices()
    const dispatch = useAppDispatch()
    const exercises = useAppSelector(x => x.etudeEditor.form.exercises)
    const previewImage = useAppSelector(x => x.etudeEditor.form.previewImage)
    const previewImageSource = useAppSelector(x => x.etudeEditor.form.previewImageSource)

    const [selectedTab, setSelectedTab] = useState <number> (0)

    const preventEvent = (e: React.MouseEvent | React.TouchEvent) => {
        e.stopPropagation()
    }

    const selectBackgroundColor = (color: string) => {
        dispatch(actions.etudeEditor.setPreviewImageExerciseProperties({ backgroundColor: color }))
    }

    const selectExercise = (id: string) => {
        dispatch(actions.etudeEditor.setPreviewImage({ type: 'exercise', exerciseId: id }))
    }

    const selectImage = (event: { files: File[], size: number }) => {
        if (canvasRef.current) {
            const canvas = canvasRef.current
            const context = canvas.getContext("2d")

            if (context) {
                if (SupportedFileTypes.includes(event.files[0].type)) {
                    const reader = new FileReader()
                    reader.readAsDataURL(event.files[0])
                    reader.onload = () => {
                        if (typeof reader.result === 'string') {
                            const image = new Image ()
                            image.src = reader.result
                            image.onload = () => {
                                context.drawImage(image, 0, 0, canvas.width, canvas.height)
                                dispatch(actions.etudeEditor.setPreviewImage({ type: 'my-image', dataUrl: canvas.toDataURL('image/png') }))
                            }
                        }
                    }
                }
                else {
                    dispatch(actions.feedback.logError(`File type '${event.files[0].type}' is not supported.`))
                }
            }
        }
    }

    const renderThumbnail = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current
            const context = canvas.getContext("2d")

            if (context) {
                if (previewImage?.dataUrl) {
                    const image = new Image ()
                    image.src = previewImage?.dataUrl
                    image.onload = () => {
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                    }
                } else if (previewImage?.type === 'exercise') {
                    const image = new Image ()
                    image.crossOrigin = 'anonymous'
                    image.src = `${config.cdn.baseUrl}/images/preview-image/${previewImage.exerciseId}`
                    image.onload = () => {
                        context.fillStyle = previewImage.properties?.backgroundColor ?? '#fafafa'
                        context.fillRect(0, 0, canvas.width, canvas.height)
                        const height = canvas.width * image.naturalHeight / image.naturalWidth
                        context.drawImage(image, 0, (canvas.height - height) / 2, canvas.width, height)
                        dispatch(actions.etudeEditor.setPreviewImageDataUrl(canvas.toDataURL('image/png')))
                    }
                } else if (previewImageSource) {
                    const image = new Image ()
                    image.src = previewImageSource
                    image.onload = () => {
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                    }
                }
            }
        }
    }

    useEffect(() => {
        renderThumbnail ()
    }, [previewImage])

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current
            const context = canvas.getContext("2d")

            if (context && !previewImage && previewImageSource) {
                const image = new Image ()
                image.src = previewImageSource
                image.onload = () => {
                    context.drawImage(image, 0, 0, canvas.width, canvas.height)
                }
            }
        }
    }, [previewImageSource])
    
    return (
        <Modal open onClose={onClose} className={{
            content: css({
                width: '800px',
                maxWidth: '100%',

                [mediaQuery.phone()]: {
                    width: '100%',
                    height: '100%'
                }
            })
        }}>
            <Container onClick={preventEvent} onTouchMove={preventEvent}>
                <Header className={css({
                    borderBottom: `1px solid ${colors.primaryColor.shade2}`
                })}>
                    <LocalisedText fontSize={1.5} textKey="thumbnail-editor" className={css({
                        flex: '1 0 0'
                    })} />
                    <Icon icon="close" onClick={onClose} className={css({
                        flex: '0 1 24px'
                    })}></Icon>
                </Header>
                <Content>
                    <UpperSection>
                        <CanvasContainer>
                            <Canvas ref={canvasRef} width={640} height={360} className={css({
                                border: `1px solid ${colors.primaryColor.shade2}`
                            })}></Canvas>
                        </CanvasContainer>
                        <PropertiesContainer>
                            {previewImage?.type === 'exercise' && (
                                <>
                                    <Row>
                                        <Col>
                                            <LocalisedText variant="h3" textKey="thumbnail-exercise-properties" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <LocalisedText textKey="background-color" />
                                        </Col>
                                        <Col>
                                            <ColorSelector color={previewImage?.properties?.backgroundColor} onChange={selectBackgroundColor} />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </PropertiesContainer>
                    </UpperSection>
                    <LowerSection>
                        <Tabs value={selectedTab} onChange={setSelectedTab} classNames={{
                            container: css({
                                height: '100%'
                            })
                        }}>
                            <Tab titleKey="exercises" classNames={{
                                container: css({
                                    margin: '0 -8px',
                                    padding: '0 8px',
                                    flex: `1 0 0`,
                                    overflowY: 'auto',

                                    [mediaQuery.phone()]: {
                                        overflowY: 'unset'
                                    }
                                })
                            }}>
                                    <Row>
                                        {exercises.filter(x => x.previewImageReady).map((exercise, index) => (
                                            <Col key={`exercise-${index}`} all={6} sm={4} className={css({
                                                marginBottom: '8px'
                                            })}>
                                                <PreviewSheetMusic source={`${config.cdn.baseUrl}/images/preview-image/${exercise.id}`} onClick={() => selectExercise(exercise.id)} />
                                            </Col>
                                        ))}
                                    </Row>
                            </Tab>
                            <Tab titleKey="my-images">
                                <Row>
                                        <Col all={6} sm={4}>
                                            <Upload labelKey="upload" onChange={selectImage}></Upload>
                                        </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </LowerSection>
                </Content>
            </Container>
        </Modal>
    )
}