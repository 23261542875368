import React from "react"
import { PropsWithChildren } from "react"
import { Tabs as GenericTabs, Tab as GenericTab, TabsStyle, TabClassNames, TabsClassNames } from "../component/tabs"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface TabProps {
    titleKey: string
    classNames?: TabClassNames
}

export const Tab = Object.assign(
    (props: PropsWithChildren<TabProps>) => {
        const {
            titleKey,
            classNames,
            children
        } = props
        const { localisationStrings } = useLocalisationContext()

        return (
            <GenericTab title={localisationStrings[titleKey]} classNames={classNames}>{children}</GenericTab>
        )
    }, {
        __mom__type: 'tab'
    }
)

interface TabsProps {
    value?: number
    classNames?: TabsClassNames
    onChange?: (tabIndex: number) => void
}

export function Tabs (props: PropsWithChildren<TabsProps>) {
    const {
        value,
        classNames,
        children,
        onChange
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()

    const titles = React.Children.toArray(children).filter((x: any) => x.type['__mom__type'] === 'tab').map((tab: any) => localisationStrings[tab.props.titleKey])

    const style: TabsStyle = {
        activeColor: colors.secondaryColor.shade1,
        textColor: colors.primaryColor.shade6
    }

    return <GenericTabs titles={titles} value={value} style={style} classNames={classNames} onChange={onChange}>{children}</GenericTabs>
}