import { useAppSelector } from "../store"
import { Overlay } from "../hoc/overlay"
import { useTheme } from "../context/theme"

export default function LoadingFeedback () {
    const { colors } = useTheme()
    const status = useAppSelector(state => state.feedback.status)
    return (
        <Overlay open={status === 'loading'}>
                <svg width={100} height={100} viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
                    <path fill={colors.secondaryColor.shade1} stroke={colors.secondaryColor.shade1} d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform 
                            attributeName="transform" 
                            attributeType="XML" 
                            type="rotate"
                            dur="1s" 
                            from="0 50 50"
                            to="360 50 50" 
                            repeatCount="indefinite" />
                    </path>
                </svg>
        </Overlay>
    )
}