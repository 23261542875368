import { useState } from "react"
import { useServices } from "../../context/services"
import Confirmation from "../../hoc/confirmation"
import { EtudeDetails } from "../../hoc/etude-details"
import { EtudeBriefDetails } from "../../types"

interface Props {
    etude: EtudeBriefDetails
    onDelete?: (id: string) => void
    onEdit?: (id: string) => void
}

export default function (props: Props) {
    const { etude, onEdit, onDelete } = props
    const [confirm, setConfirm] = useState (false)
    const { config } = useServices()

    const edit = () => {
        onEdit && onEdit(etude.id)
    }

    const confirmDelete = () => {
        setConfirm (true)
    }

    const doDelete = () => {
        onDelete && onDelete(etude.id)
        setConfirm (false)
    }

    const cancelDelete = () => {
        setConfirm (false)
    }

    return (
        <>
            <EtudeDetails
                title={etude.title}
                description={etude.description}
                exercises={etude.exercises}
                previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${etude.id}`}
                onEdit={edit}
                onDelete={confirmDelete}
            />
            <Confirmation open={confirm} messageKey="delete-etude-confirmation-message" messageParam={{ title: etude.title }} onConfirm={doDelete} onCancel={cancelDelete} />
        </>
    )
}