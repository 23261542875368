import { css } from "@emotion/css"
import { useTheme } from "../../context/theme"
import { useNoteTracker, useNoteTrackerPosition } from "../../context/player"

interface NoteTrackerProps {
    exerciseId: string
}

export function NoteTracker (props: NoteTrackerProps) {
    const {
        exerciseId
    } = props
    const noteTracker = useNoteTracker(exerciseId)
    const position = useNoteTrackerPosition(exerciseId)
    const { colors } = useTheme()

    return (
        <>
            {noteTracker && position && (
                <svg x={position.x} y={position.y} className={css({
                    stroke: colors.secondaryColor.shade1,
                    opacity: 0.6
                })}>
                    <path d={`M2 2 V${6 + 10 * (position.staveCount * 2 - 1)}`} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )}
        </>
    )
}
