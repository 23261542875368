import { useServices } from "../../context/services"
import { SubscribedEtudeDetails } from "../../hoc/subscribed-etude-details"
import { EtudeDetailsResponse } from "../../types"
import ExerciseCard from "./exercise-card"

interface Props {
    etude: EtudeDetailsResponse
    onPlay: (id: string) => void
}

export default function (props: Props) {
    const {
        etude,
        onPlay
    } = props
    const { config } = useServices()

    return (
        <>
            <SubscribedEtudeDetails
                title={etude.title}
                description={etude.description}
                exercises={etude.exercises}
                previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${etude.id}`}
                exerciseElement={(exercise) => (
                    <ExerciseCard id={exercise.id} title={exercise.title} onPlay={onPlay} />
                )}
            />
        </>
    )
}