import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../auth";
import { Col, Hidden, Row } from "../../component/grid-layout";
import { ExerciseCard } from "./exercise-card";
import { EtudeCard } from "./etude-card";
import { asyncActions, useAppDispatch, useAppSelector } from "../../store";

export default function Main () {
    const auth = useAuth ()
    const navigate = useNavigate ()
    const dispatch = useAppDispatch ()
    const recommendations = useAppSelector (x => x.recommendation.recommendations)
    const subscribedExercises = useAppSelector (x => x.exercise.subscribedExerciseDetails)

    useEffect (() => {
        dispatch(asyncActions.recommendation.loadRecommendations())
        if (auth.isAuthenticated) {
            dispatch(asyncActions.exercise.loadExerciseList())
        }
    }, [])

    const viewDetails = (id: string) => {
        navigate (`/etude/${id}`)
    }

    const play = async (etudeId: string, exerciseId: string) => {
        navigate (`/play/${etudeId}/${exerciseId}`)
    }
    
    return (
        <>
            <Row>
                {auth.isAuthenticated && (
                    <>
                        <Col all={12} sm={6} md={4} xl={3}>
                            {subscribedExercises[0] && (
                                <ExerciseCard key={`exercise-${subscribedExercises[0].id}`} id={subscribedExercises[0].id} title={subscribedExercises[0].title} onPlay={(exerciseId) => play(subscribedExercises[0].etudeId, exerciseId)} />
                            )}
                        </Col>
                        <Hidden xs andSmaller>
                            <Col all={12} sm={6} md={4} xl={3}>
                                {subscribedExercises[1] && (
                                    <ExerciseCard key={`exercise-${subscribedExercises[1].id}`} id={subscribedExercises[1].id} title={subscribedExercises[1].title} onPlay={(exerciseId) => play(subscribedExercises[1].etudeId, exerciseId)} />
                                )}
                            </Col>
                        </Hidden>
                        <Hidden sm andSmaller>
                            <Col all={12} sm={6} md={4} xl={3}>
                                {subscribedExercises[2] && (
                                    <ExerciseCard key={`exercise-${subscribedExercises[2].id}`} id={subscribedExercises[2].id} title={subscribedExercises[2].title} onPlay={(exerciseId) => play(subscribedExercises[2].etudeId, exerciseId)} />
                                )}
                            </Col>
                        </Hidden>
                        <Hidden lg andSmaller>
                            <Col all={12} sm={6} md={4} xl={3}>
                                {subscribedExercises[3] && (
                                    <ExerciseCard key={`exercise-${subscribedExercises[3].id}`} id={subscribedExercises[3].id} title={subscribedExercises[3].title} onPlay={(exerciseId) => play(subscribedExercises[3].etudeId, exerciseId)} />
                                )}
                            </Col>
                        </Hidden>
                    </>
                )}
            </Row>
            <Row>
                {recommendations.map(recommendation => (
                    <Col
                        key={`recommendation-${recommendation.id}`}
                        all={12}
                        sm={6}
                        md={4}
                        xl={3}
                    >
                        <EtudeCard
                            id={recommendation.id}
                            previewExerciseId={recommendation.previewExerciseId}
                            title={recommendation.title}
                            username={recommendation.userName}
                            onClick={viewDetails}
                        />
                    </Col>
                ))}
            </Row>
        </>
    )
}