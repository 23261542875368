export function LandscapeOrientationIcon() {
    return (
        <>
            <path d="M7 17H3.81818C2.81534 17 2 16.1592 2 15.125V3.875C2 2.84082 2.81534 2 3.81818 2H10.1818C11.1847 2 12 2.84082 12 3.875V12" strokeWidth="1.5"/>
            <path d="M22 13.8182C22 12.8153 21.1592 12 20.125 12H8.875C7.84082 12 7 12.8153 7 13.8182V20.1818C7 21.1847 7.84082 22 8.875 22H20.125C21.1592 22 22 21.1847 22 20.1818V13.8182Z" strokeWidth="1.5"/>
            <path d="M9.5 15.5L9.5 18.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 4C18.3283 4 19.5 6.5 19.5 9.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M20.8284 8.41418L19.4142 9.8284L17.9999 8.41418" strokeLinecap="round" strokeLinejoin="round"/>
        </>
    )
}``