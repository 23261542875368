import { useState } from "react"
import { Auto } from "../../component/auto-layout"
import { Button } from "../../hoc/button"
import { LocalisedText } from "../../hoc/text"
import { Textbox } from "../../hoc/textbox"
import { SigninDetails } from "../types"

interface SigninProps {
    onSignin: (details: SigninDetails) => void
    onNavigate: (authState: 'signin' | 'signup') => void
}

export default function Signin (props: SigninProps) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const login = () => {
        props.onSignin({ email, password })
    }

    const googleLogin = () => {

    }

    const navigateToSignup = () => {
        props.onNavigate('signup')
    }
    
    return (
        <Auto direction='vertical' spacing={12}>
            <Auto>
                <Textbox labelKey='email' type='email' value={email} onChange={setEmail}></Textbox>
            </Auto>
            <Auto paddingBottom={12}>
                <Textbox labelKey='password' type='password' value={password} onChange={setPassword}></Textbox>
            </Auto>
            <Auto paddingBottom={12}>
                <Button textKey='signin' variant="inverted" fullWidth onClick={login} />
            </Auto>
            {/* <Auto paddingBottom={12}>
                <Button textKey='google-signin' variant="inverted" fullWidth onClick={googleLogin} />
            </Auto> */}
            <Auto paddingBottom={12} horizontalAlignment="center"  spacing={4}>
                {/* <LocalisedText textKey='or-lowercase' /> */}
                <LocalisedText textKey='create-account' variant="link" onClick={navigateToSignup} />
            </Auto>
        </Auto>
    )
}