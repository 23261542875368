import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Icon, IconType } from "./icon"

const Container = styled.div({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: '56px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: 'border-box'
})

const MenuItem = styled.div({
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    maxWidth: '125px',
    boxSizing: 'border-box'
})

const MenuItemText = styled.label({
    fontSize: '0.7rem',
    textOverflow: 'ellipsis'
})

export interface BottomMenuItemDetails {
    id: string
    icon: IconType
    text: string
}

export interface BottomMenuStyle {
    backgroundColor?: string
    textColor?: string
    selectedColor?: string
}

interface ButtomMenuProps {
    selected?: string
    items: BottomMenuItemDetails[]
    style?: BottomMenuStyle
    className?: string
    onChange?: (item: BottomMenuItemDetails) => void
}

export function BottomMenu (props: ButtomMenuProps) {
    const {
        selected,
        items,
        style,
        className,
        onChange
    } = props

    const classes = [
        className,
        css({
            backgroundColor: style?.backgroundColor,
            color: style?.textColor
        })
    ].filter(x => x)

    return (
        <Container className={classes.join(' ')}>
            {items.map(item => (
                <MenuItem key={`menu-item-${item.id}`} onClick={() => onChange && onChange(item)}>
                    <Icon icon={item.icon} style={{ stroke: item.id === selected ? style?.selectedColor : style?.textColor }} />
                    <MenuItemText className={css({
                        color: item.id === selected ? style?.selectedColor : style?.textColor
                    })}>{item.text}</MenuItemText>
                </MenuItem>
            ))}
        </Container>
    )
}