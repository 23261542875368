import { EventEmitter } from "./event-emitter"

type SoundSettingsEvents = {
    changeMetronome: (volume: number) => void
}

interface SoundSettingsOptions {
    settings: {
        metronome: number
    }
}

export class SoundSettings extends EventEmitter<SoundSettingsEvents> {
    public settings: {
        metronome: number
    }

    constructor (options: SoundSettingsOptions) {
        super ()
        this.settings = options.settings
    }

    setMetronome (metronome: number) {
        this.settings.metronome = metronome
        this.trigger ('changeMetronome', metronome)
    }
}
