import { css } from "@emotion/css"
import styled from "@emotion/styled"

export interface ToggleButtonStyle {
    toggleOnColor?: string
    toggleOffColor?: string
    textColor?: string
    backgroundColor?: string
}

interface ToggleButtonProps {
    labelOn?: string
    labelOff?: string
    value?: boolean
    style?: ToggleButtonStyle
    onChange?: (value: boolean) => void
}

const Container = styled.div({
    height: '24px',
    padding: '0 12px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'border-box',
    transition: 'background-color 200ms cubic-bezier(0, 0, 0.2, 1)'
})

const Button = styled.div({
    position: 'absolute',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    boxSizing: 'border-box',
    transition: 'margin 200ms cubic-bezier(0, 0, 0.2, 1), left 200ms cubic-bezier(0, 0, 0.2, 1)',
    backgroundColor: '#fff'
})

const Label = styled.label({
    cursor: 'inherit',
    userSelect: 'none'
})

export function ToggleButton (props: ToggleButtonProps) {
    const {
        labelOn,
        labelOff,
        value,
        style,
        onChange
    } = props

    const toggle = () => {
        onChange && onChange(!value)
    }

    return (
        <Container className={css({
            backgroundColor: value ? style?.toggleOnColor : style?.toggleOffColor,
            justifyContent: value ? 'flex-start' : 'flex-end',
            width: labelOn && labelOff ? '60px' : '48px'
        })} onClick={toggle}>
            {labelOn && labelOff && (
                <Label className={css({
                    color: value ? style?.textColor : style?.backgroundColor
                })}>
                    { value ? labelOn : labelOff }
                </Label>
            )}
            <Button className={css({
                left: value ? '100%' : '0%',
                marginLeft: value ? '-22px' : '2px',
            })} />
        </Container>
    )
}