import { List as GenericList, ListItem as GenericListItem, ListStyle } from '../component/list'
import { useTheme } from '../context/theme'
import { PropsWithChildren } from 'react'
import { IconType } from '../component/icon'
import React from 'react'

interface ListItemProps {
    icon?: IconType
}

export function ListItem (props: PropsWithChildren<ListItemProps>) {
    const {
        icon,
        children
    } = props
    const { colors } = useTheme()

    const style: ListStyle = {
        textColor: colors.primaryColor.shade6
    }

    return (
        <GenericListItem icon={icon} style={style}>
            {children}
        </GenericListItem>
    )
}

interface ListProps {
    
}

export function List (props: PropsWithChildren<ListProps>) {
    const {
        children
    } = props
    
    return (
        <GenericList>
            {children}
        </GenericList>
    )
}
