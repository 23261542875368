import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

const Container = styled.div({
    marginTop: '16px'
})

export interface TabClassNames {
    container: string
}

interface TabProps {
    title: string
    classNames?: TabClassNames
}

export const Tab = Object.assign(
    (props: PropsWithChildren<TabProps>) => {
        const {
            classNames,
            children
        } = props

        return (
            <Container className={classNames?.container}>{children}</Container>
        )
    }, {
        __mom__type: 'tab'
    }
)