export function SquaredPlusIcon() {
    return (
        <>
            <path d="M12 7 V17 M7 12 H17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.99982 1H19.9997C20.8278 1 21.5349 1.29296 22.1208 1.87888C22.7067 2.4648 22.9997 3.17184 22.9997 4V19.9998C22.9997 20.828 22.7067 21.535 22.1208 22.121C21.5349 22.7069 20.8278 22.9998 19.9997 22.9998H3.99982C3.17166 22.9998 2.46462 22.7069 1.8787 22.121C1.29278 21.535 0.999817 20.828 0.999817 19.9998V4C0.999817 3.17184 1.29278 2.4648 1.8787 1.87888C2.46462 1.29296 3.17166 1 3.99982 1V1Z" strokeWidth={2} />
        </>
    )
}



