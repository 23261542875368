import { SheetMusicTypes } from "@mom/types"
import { constants } from "../../../constants"
import { SheetMusicEvaluationContext, SheetMusicNoteEvaluationResult, UserNoteToEvaluate } from "../../../types"
import { createSheetMusicEvaluationContext, evaluateUserNote } from "../../../util/exercise"
import { EventEmitter } from "./event-emitter"

const options = {
    thresholds: constants.exercise.scoringThresholds
}
type FeedbackEvents = {
    'change': (evaluations: SheetMusicNoteEvaluationResult[]) => void
}

interface FeedbackOptions {
    enabled: boolean
    timeline: any
}

export class Feedback extends EventEmitter<FeedbackEvents> {
    private enabled: boolean
    private timeline: any
    private evaluationContext: SheetMusicEvaluationContext
    private userNotes: UserNoteToEvaluate[]
    
    public evaluationResults: SheetMusicNoteEvaluationResult[]

    constructor (options: FeedbackOptions) {
        super ()

        this.enabled = options.enabled
        this.timeline = options.timeline
        this.userNotes = []
        this.evaluationResults = []
    }

    reset () {
        this.userNotes = []
        this.evaluationResults = []

        this.trigger('change', this.evaluationResults)
    }

    setRenderingDetails (renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails) {
        this.evaluationContext = createSheetMusicEvaluationContext(renderingDetails, this.timeline, { width: renderingDetails.width })
        this.evaluateAllNotes ()
    }
    
    setEnabled (enabled: boolean) {
        this.enabled = enabled
        this.evaluateAllNotes ()
        if (!this.enabled) {
            this.trigger('change', this.evaluationResults = [])
        }
    }

    evaluateNote (userNote: UserNoteToEvaluate) {
        this.userNotes.push (userNote)

        if (this.enabled && this.evaluationContext) {
            this.evaluationResults = [
                ...this.evaluationResults,
                evaluateUserNote (this.evaluationContext, userNote, options)
            ]
            this.trigger('change', this.evaluationResults)
        }
    }

    private evaluateAllNotes () {
        if (this.enabled && this.evaluationContext && this.userNotes.length) {
            this.evaluationResults = this.userNotes.map(userNote => evaluateUserNote (this.evaluationContext, userNote, options))
            this.trigger('change', this.evaluationResults)
        }
    }
}