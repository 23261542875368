import styled from "@emotion/styled";
import { sheetMusic, svg } from "@mom/ui";
import { useNoteEvaluationResult } from "../../context/player";

const SVG = styled.svg({
    width: '100%',
    height: '100%'
})

interface EvaluatedNotesProps {
    exerciseId: string
}

export function EvaluatedNotes (props: EvaluatedNotesProps) {
    const {
        exerciseId
    } = props
    const evaluationResult = useNoteEvaluationResult(exerciseId)

    const evaluatedNotes = evaluationResult?.map(note => ({
        details: note.noteDetails,
        color: note.noting === 'correct' ? 'green' : 'red',
        text: note.timing && note.timing !== 'on-time' ? note.timing.replace('-', ' ') : undefined
    })) || []

    

    return (
        <SVG dangerouslySetInnerHTML={{ __html: svg.toHTMLString(sheetMusic.NoteList({ notes: evaluatedNotes })) }} className="no-theme">

        </SVG>
    )
}