import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import { Icon } from "./icon"
import { Textarea } from "./textarea"
import { Textbox } from "./textbox"

const Container = styled.div({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    width: '100%'
})

const TextboxContainer = styled.div({
    flex: '1 0 0'
})

const Text = styled.span({
    padding: '9px 0 9px 17px',
    fontSize: '1rem',
    lineHeight: '1.2rem'
})

type UIEvent = React.FocusEvent | React.KeyboardEvent | React.MouseEvent | MouseEvent

export interface EditableTextStyle {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    labelColor?: string
}

interface EditableTextProps {
    label: string
    value?: string
    style?: EditableTextStyle
    type?: 'textbox' | 'textarea'
    onChange?: (text: string) => void
}

export function EditableText (props: EditableTextProps) {
    const {
        label,
        value,
        style,
        type,
        onChange
    } = props
    const [editable, setEditable] = useState (false)
    const [textCopy, setTextCopy] = useState (value ?? '')

    const iconStyle = {
        stroke: style?.textColor
    }

    const stopPropagation = (e: UIEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const edit = (e: UIEvent) => {
        setTextCopy (value ?? '')
        setEditable (true)
        stopPropagation (e)
    }

    const undo = (e: UIEvent) => {
        onChange && onChange (textCopy)
        setEditable (false)
        stopPropagation (e)
    }

    const save = (e: UIEvent) => {
        setEditable (false)
        stopPropagation (e)
    }

    const textboxKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            save (e)
        } else if (e.key === 'Escape') {
            undo (e)
        }
    }

    useEffect (() => {
        document.addEventListener ('click', undo)

        return () => {
            document.removeEventListener ('click', undo)
        }
    })

    return (
        <Container onClick={stopPropagation}>
            {editable ? (
                <>
                    <TextboxContainer>
                        {type === 'textarea' ? (
                            <Textarea label={label} text={value} focused onChange={onChange} style={style} onKeyDown={textboxKeyDown}></Textarea>
                        ) : (
                            <Textbox label={label} value={value} focused onChange={onChange} style={style} onKeyDown={textboxKeyDown}></Textbox>
                        )}
                    </TextboxContainer>
                    <Icon icon="check" style={iconStyle} onClick={save} />
                    <Icon icon="close" style={iconStyle} onClick={undo} />
                </>
            ) : (
                <>
                    <Text className={css({ color: value ? style?.textColor : style?.labelColor })} onClick={edit}>{value || label}</Text>
                    <Icon icon="edit" style={iconStyle} onClick={edit} />
                </>
            )}
        </Container>
    )
}