import { useServices } from "../../context/services"
import { ExerciseDetails } from "../../hoc/exercise-details"

interface Props {
    id: string
    title: string
    description?: string
    onPlayClick: (id: string) => void
}

export default function (props: Props) {
    const {
        id,
        title,
        description,
        onPlayClick
    } = props
    const { config } = useServices()

    return (
        <ExerciseDetails title={title} description={description} onPlayClick={() => onPlayClick(id)} previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${id}`} />
    )
}