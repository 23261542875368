import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

export const useClockState = (id: string) => {
    const { clock } = usePlayer (id)
    const [state, setState] = useState (clock.currentState)

    useEffect (() => {
        clock.on ('changeState', setState)
        return () => {
            clock.off ('changeState', setState)
        }
    })

    return state
}
