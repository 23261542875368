import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "./button";

function convertHSL2RGB ([h, s, l]: [number, number, number]) {
    s /= 100;
    l /= 100;
  
    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c/2,
        r = 0,
        g = 0,
        b = 0;
    
    if (0 <= h && h < 60) {
        r = c; g = x; b = 0;  
    } else if (60 <= h && h < 120) {
        r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
        r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
        r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
        r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
        r = c; g = 0; b = x;
    }

    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return [r, g, b]
}

function convertRGB2Hex([r, g, b]: [number, number, number]) {
    let rStr = r.toString(16),
        gStr = g.toString(16),
        bStr = b.toString(16)
  
    if (rStr.length == 1)
        rStr = "0" + rStr;
    if (gStr.length == 1)
        gStr = "0" + gStr;
    if (bStr.length == 1)
        bStr = "0" + bStr;
  
    return "#" + rStr + gStr + bStr;
}

function convertHSL2Hex ([h, s, l]: [number, number, number]) {
    const [r, g, b] = convertHSL2RGB([h, s, l])
    return convertRGB2Hex([r, g, b])
}

function randomColors () {
    const hues = Array.from(Array(6).keys()).map(i => (Math.random() * 60 + i * 60 + 30) % 360)
    const s = Math.random() * 30 + 60
    const l = Math.random() * 30 + 60

    return hues.map(h => convertHSL2Hex([h, s, l]))
}

const DropdownWidth = 200

const Container = styled.div({
    position: 'relative',
    width: 'calc(18px + 1.2rem)',
    height: 'calc(18px + 1.2rem)',
    borderRadius: '4px',
    cursor: 'pointer'
})

const Dropdown = styled.div({
    position: 'absolute',
    width: `${DropdownWidth}px`,
    top: '100%',
    marginTop: '8px',
    borderRadius: '4px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

const Row = styled.div({
    flex: '1 0 0',
    alignSelf: 'stretch',
    display: 'flex',
    flexWrap: 'wrap'
})

const Col = styled.div({
    flex: '1 0 33.33%'
})

const ColorCard = styled.div({
    width: '100%',
    aspectRatio: '1 / 1',
    overflow: 'hidden',
    cursor: 'pointer'
})

export interface ColorSelectorStyle {
    borderColor?: string
    backgroundColor?: string
    textColor?: string
    highlightColor?: string
}

interface ColorSelectorProps {
    color?: string
    generateLabel?: string
    style?: ColorSelectorStyle
    onChange?: (color: string) => void
}

export function ColorSelector (props: ColorSelectorProps) {
    const {
        color,
        style,
        generateLabel,
        onChange
    } = props
    const containerRef = useRef <HTMLDivElement> (null)
    const [translateX, setTranslateX] = useState (50)
    const [open, setOpen] = useState (false)
    const [hexSet, setHexSet] = useState <string[]> ()

    const generateColors = () => {
        setHexSet (randomColors())
    }

    const calculateTranslate = () => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect()

            if (rect.left - DropdownWidth / 2 < 0) {
                setTranslateX (0)
            } else if (rect.right + DropdownWidth / 2 > window.innerWidth) {
                setTranslateX (100)
            } else {
                setTranslateX (50)
            }
        }
    }

    const toggleDropdown = (e?: React.MouseEvent) => {
        if (!hexSet) {
            generateColors ()
        }
        if (!open) {
            calculateTranslate ()
        }
        setOpen (!open)
        e?.stopPropagation()
    }

    const closeDropdown = () => {
        setOpen (false)
    }

    const selectColor = (color: string) => {
        onChange && onChange (color)
    }

    const preventEvent = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    useEffect(() => {
        document.addEventListener('click', closeDropdown)

        return () => {
            document.removeEventListener('click', closeDropdown)
        }
    })

    return (
        <Container ref={containerRef} className={css({
            border: `1px solid ${style?.borderColor ?? ''}`,
            backgroundColor: color
        })} onClick={toggleDropdown}>
            {open && (
                <Dropdown onClick={preventEvent} className={css({
                    backgroundColor: style?.backgroundColor,
                    border: `1px solid ${style?.borderColor ?? ''}`,
                    left: `${translateX}%`,
                    transform: `translateX(-${translateX}%)`
                })}>
                    <Button variant="inverted" style={style} onClick={generateColors}>{generateLabel}</Button>
                    <Row>
                        {hexSet?.map(hex => (
                            <Col key={`color-${hex}`}>
                                <ColorCard onClick={() => selectColor(hex)} className={css({
                                    backgroundColor: hex
                                })} />
                            </Col>
                        ))}
                        
                    </Row>
                </Dropdown>
            )}
        </Container>
    )
}