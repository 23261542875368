import { useServices } from "../../context/services"
import { ExerciseCard } from "../../hoc/exercise-card"

interface Props {
    id: string
    title: string
    onPlay: (id: string) => void
}

export default function (props: Props) {
    const {
        id,
        title,
        onPlay
    } = props
    const { config } = useServices()

    const play = () => {
        onPlay && onPlay(id)
    }

    return (
        <>
            <ExerciseCard
                title={title}
                previewImageSource={`${config.cdn.baseUrl}/images/preview-image/${id}`}
                onPlayClick={play}
            />
        </>
    )
}