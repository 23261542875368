import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Icon } from "../hoc/icon";
import { mediaQuery } from "../util/device-detection";
import { Auto } from "./auto-layout";
import { EditableText, EditableTextStyle } from "./editable-text";

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    padding: '8px 0',
    paddingBottom: '16px',

    [mediaQuery.device('md')]: {
        flexDirection: 'row',
        gap: '32px',
        padding: '16px 0'
    }
})

const ImageContainer = styled.div({
    width: '100%',

    [mediaQuery.device('md')]: {
        width: '300px'
    }
})

const DetailsContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 0',

    [mediaQuery.device('md')]: {
        flex: '1 0 0'
    }
})

const PreviewImage = styled.img({
    width: '100%',
    backgroundColor: '#fafafa',
    borderRadius: '8px',
    overflow: 'hidden',
})

const DummyPreviewImage = styled.div({
    width: '100%',
    height: '100%',
    opacity: '.2'
})

export interface EditableEtudeStyle {
    backgroundColor?: string
    borderColor?: string
    textColor?: string
    labelColor?: string
}

interface EditableEtudeProps {
    title: string
    description: string
    titleLabel: string
    descriptionLabel: string
    style?: EditableEtudeStyle
    previewImageSource?: string
    previewImageElement?: ReactNode
    hideImageInPhone?: boolean
    onChange?: (value: { title: string, description: string }) => void
    onDelete?: () => void
}

export function EditableEtude(props: EditableEtudeProps) {
    const {
        title,
        description,
        titleLabel,
        descriptionLabel,
        style,
        previewImageSource,
        previewImageElement,
        hideImageInPhone,
        onChange,
        onDelete
    } = props

    const editableTextStyle: EditableTextStyle = {
        backgroundColor: style?.backgroundColor,
        borderColor: style?.borderColor,
        labelColor: style?.labelColor,
        textColor: style?.textColor
    }

    const changeTitle = (title: string) => {
        onChange && onChange({
            title,
            description
        })
    }

    const changeDescription = (description: string) => {
        onChange && onChange({
            title,
            description
        })
    }

    return (
        <Container>
            <ImageContainer className={css({
                [mediaQuery.phone()]: {
                    display: hideImageInPhone ? 'none' : undefined
                }
            })}>
                {previewImageElement ? (
                    previewImageElement
                ) : previewImageSource ? (
                    <PreviewImage src={previewImageSource} />
                ) : (
                    <DummyPreviewImage className={css({
                        backgroundColor: style?.textColor
                    })} />
                )}
            </ImageContainer>
            <DetailsContainer>
                <Auto direction="vertical" spacing={16}>
                    <Auto direction="horizontal" spacing={16}>
                        <EditableText label={titleLabel} value={title} style={style} onChange={changeTitle} />
                        <Icon icon="delete" onClick={onDelete} />
                    </Auto>
                    <EditableText type="textarea" label={descriptionLabel} value={description} style={style} onChange={changeDescription} />
                </Auto>
            </DetailsContainer>
        </Container>
    )
}