import { useInputDevice } from "@mom/ui";
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { UserNoteToEvaluate } from "../../types";
import { useAudio, useInstrument } from "../audio";
import { useServices } from "../services";
import { Player } from "./components/player";
import { PlayerRepository } from "./components/player-repository";

interface PlayerControl {
    toggle (): void
    stop (): void
}

const PlayerRepositoryContext = createContext <PlayerRepository | undefined> (undefined)

export const usePlayerLoader = (etudeId: string, exerciseId: string) => {
    const context = useContext (PlayerRepositoryContext)
    
    if (!context) {
        throw new Error (`Create player provider to use the player context.`)
    }

    const player = context.get(exerciseId)
    const [_, setPlayer] = useState <Player | undefined> (player)

    useEffect (() => {
        if (!player) {
            context.load(etudeId, exerciseId).then(setPlayer)
        }
    }, [etudeId, exerciseId])

    return player
}

export const usePlayer = (exerciseId: string) => {
    const context = useContext (PlayerRepositoryContext)
    
    if (!context) {
        throw new Error (`Create player provider to use the player context.`)
    }

    const player = context.get(exerciseId)

    if (!player) {
        throw new Error ('Player details are not loaded')
    }
    
    return player
}

export const useExerciseDetails = (exerciseId: string) => {
    const player = usePlayer (exerciseId)
    return player.exerciseDetails
}

export const usePlayerControl = (id: string): PlayerControl => usePlayer(id)

interface PlayerProviderProps {
    
}

export const usePlayerEnded = (exerciseId: string, cb: (e: { exerciseId: string, repetition: number, userNotes?: UserNoteToEvaluate[] }) => void) => {
    const { clock, result, playerSettings, exerciseDetails } = usePlayer (exerciseId)

    const end = () => {
        cb({
            exerciseId: exerciseDetails.id,
            repetition: playerSettings.repetition ?? 1,
            userNotes: result.enabled ? result.notes : undefined
        })
    }

    useEffect(() => {
        clock.on('end', end)

        return () => {
            clock.off('end', end)
        }
    }, [exerciseDetails.id, playerSettings.repetition, result.notes])
}

export function PlayerProvider (props: PropsWithChildren<PlayerProviderProps>) {
    const {
        children
    } = props

    const audio = useAudio()
    const inputDevice = useInputDevice()
    const instrument = useInstrument ()
    const services = useServices ()

    const value = useMemo(() => new PlayerRepository ({ audio, inputDevice, instrument, services }), [])

    return (
        <PlayerRepositoryContext.Provider value={value}>
            {children}
        </PlayerRepositoryContext.Provider>
    )
}
