import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { PropsWithChildren } from "react"
import { IconType } from "./icon"

const HtmlButton = styled.button({
    padding: '8px 16px',
    fontSize: '1rem',
    lineHeight: '1.2rem',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
})

export type ButtonVariant = 'inverted' | 'text' | 'round' | 'inverted-round'

export interface ButtonStyle {
    borderColor?: string
    backgroundColor?: string
    textColor?: string
    highlightColor?: string
}

interface ButtonProps {
    variant?: ButtonVariant
    style?: ButtonStyle
    className?: string
    fullWidth?: boolean
    onClick?: (e: React.MouseEvent) => void
}

export const Button = React.forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>((props, ref) => {
    const {
        variant,
        style,
        className,
        fullWidth,
        children,
        onClick
    } = props

    const classes = [
        className,
        css({
            width: fullWidth ? '100%' : undefined,
            justifyContent: fullWidth ? 'center' : undefined,

            '&:active': {
                background: variant !== 'inverted' ? style?.highlightColor : 'inherited',
                opacity: variant === 'inverted' ? '0.9' : 'inherited'
            }
        }),
        (!variant || variant === 'round') && (css({
            border: `1px solid ${style?.borderColor}`,
            borderRadius: variant === 'round' ? `calc(18px + 1.2rem)` : '4px',
            background: 'transparent',
            color: style?.textColor
        })),
        (variant === 'inverted' || variant === 'inverted-round') && (css({
            border: `1px solid ${style?.textColor}`,
            borderRadius: variant === 'inverted-round' ? `calc(18px + 1.2rem)` : '4px',
            background: style?.textColor,
            color: style?.backgroundColor
        })),
        variant === 'text' && (css({
            border: '1px solid transparent',
            background: 'transparent',
            color: style?.textColor
        }))
    ].filter(x => x)

    return <HtmlButton ref={ref} className={classes.join(' ')} onClick={onClick}>{children}</HtmlButton>
})