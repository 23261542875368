import { css } from "@emotion/css"
import styled from "@emotion/styled"
import { Auto, AutoFill } from "../../component/auto-layout"
import { Hidden } from "../../component/grid-layout"
import { constants } from "../../constants"
import { useTheme } from "../../context/theme"
import { PersonalisedIcon } from "../../hoc/icon"
import { Numeric } from "../../hoc/numeric"
import { mediaQuery } from "../../util/device-detection"
import { useClockState, usePlayerControl, usePlayerSettingsControl, useRepetition, useTempo } from "../../context/player"
import { PlayerSettings } from "./player-settings"
import { SoundSettings } from "./sound-settings"
import { Button } from "../../hoc/button"

const Container = styled.div({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: `${constants.ui.height.playerControlPanel}px`,
    zIndex: 2
})

const HiddenSettings = styled.div({
    width: 'auto',
    display: 'flex',
    gap: '24px',

    [mediaQuery.phone()]: {    
        width: 0,
        overflow: 'hidden'
    }
})

const HiddenExercises = styled.div({
    display: 'none',
    [mediaQuery.phone()]: {
        display: 'inline'
    }
})

interface ControlPanelProps {
    exerciseId: string
    hideExerciseList?: boolean
    onOpenExerciseList: () => void
}

export function ControlPanel (props: ControlPanelProps) {
    const {
        exerciseId,
        hideExerciseList,
        onOpenExerciseList
    } = props
    const { colors } = useTheme ()
    const playerControl = usePlayerControl (exerciseId)
    const settingsSetter = usePlayerSettingsControl (exerciseId)
    const tempo = useTempo (exerciseId)
    const repetition = useRepetition (exerciseId)
    const clockState = useClockState (exerciseId)

    const changeTempo = (tempo: number | undefined) => {
        settingsSetter.setTempo (tempo)
    }

    const changeRepetition = (repetition: number | undefined) => {
        settingsSetter.setRepetition (repetition)
    }

    const play = () => {
        playerControl?.toggle ()
    }

    const openExerciseList = (e: React.MouseEvent) => {
        onOpenExerciseList ()
        e.preventDefault ()
        e.stopPropagation ()
    }

    return (
        <Container className={css({
            padding: '0 16px',

            [mediaQuery.phone()]: {
                padding: '0 32px'
            }
        })}>
            <AutoFill direction="horizontal" className={css({
                backgroundColor: colors.primaryColor.shade1,
                maxWidth: `${constants.ui.width.pageMaxWidth}px`,
                margin: '0 auto',
            })}>
                <Hidden md andSmaller>
                    <AutoFill>
                    
                    </AutoFill>
                </Hidden>
                <Auto width='hug' height='fill' verticalAlignment="center" horizontalAlignment="center">
                    <PersonalisedIcon icon={clockState === 'running' ? 'pause' : 'play'} size={5} onClick={play} />
                </Auto>
                <AutoFill direction="horizontal" verticalAlignment="center" horizontalAlignment="right" spacing={24}>
                    <HiddenSettings>
                        <Numeric value={repetition} labelKey="repetition" onChange={changeRepetition} />
                        <Numeric value={tempo} labelKey="tempo" onChange={changeTempo} />
                    </HiddenSettings>
                    {!hideExerciseList && (
                        <HiddenExercises>
                            <Button icon="playlist" variant='text' textKey="exercises" onClick={openExerciseList} />
                        </HiddenExercises>
                    )}
                    <SoundSettings exerciseId={exerciseId} />
                    <PlayerSettings exerciseId={exerciseId} />
                </AutoFill>
            </AutoFill>
        </Container>
    )
}