import { css } from "@emotion/css";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Auto, AutoHug } from "../../component/auto-layout";
import { useExerciseResult, useExerciseResultControl, usePlayerControl, usePlayerEnded } from "../../context/player";
import { useServices } from "../../context/services";
import { useTheme } from "../../context/theme";
import { Icon, PersonalisedIcon } from "../../hoc/icon";
import { Overlay } from "../../hoc/overlay";
import { LocalisedText, NonlocalisedText, PersonalisedLocalisedText } from "../../hoc/text";
import { useAppSelector } from "../../store";
import { mediaQuery } from "../../util/device-detection";

const messageMapping = {
    'congragulations-message-above-90': (overall: number) => overall >= 90,
    'congragulations-message-above-80': (overall: number) => overall >= 80,
    'congragulations-message-above-60': (overall: number) => overall >= 60,
    'congragulations-message-below-60': (overall: number) => overall < 60
}

interface ResultProps {
    exerciseId: string
}

export function Result (props: ResultProps) {
    const {
        exerciseId
    } = props
    const { colors } = useTheme()
    const { practiceAndHistoryClient } = useServices()
    const navigate = useNavigate ()
    const result = useExerciseResult(exerciseId)
    const resultControl = useExerciseResultControl (exerciseId)
    const playerControl = usePlayerControl (exerciseId)
    const exercises = useAppSelector (x => x.exercise.subscribedExerciseDetails)
    const nextExercise = exercises.find(x => x.id !== exerciseId)
    usePlayerEnded(exerciseId, (e) => {
        practiceAndHistoryClient.postUserNotes(e.exerciseId, e.repetition, e.userNotes)
    })

    const onClose = () => {
        resultControl.reset()
    }

    const onReplay = () => {
        playerControl.toggle()
    }

    const onNext = () => {
        if (nextExercise) {
            playerControl.stop()
            navigate (`/play/${nextExercise.etudeId}/${nextExercise.id}`)
        }
    }

    const messageKey = Object.entries(messageMapping).find(x => typeof result?.overall === 'number' && x[1](result.overall))?.[0] ?? 'congragulations-message'

    return (
        <>
            {result && (
                <Overlay open>
                    <Auto className={css({
                        width: '100%',
                        maxWidth: '600px',
                        backgroundColor: colors.primaryColor.shade1,

                        [mediaQuery.phone()]: {
                            height: '100%',
                            maxWidth: '100%'
                        }
                    })}>
                        <Auto padding='12px' direction='horizontal'>
                            <Auto></Auto>
                            <AutoHug><Icon icon="close" size={2.4} onClick={onClose}/></AutoHug>
                        </Auto>
                        <Auto horizontalAlignment="center" className={css({
                            padding: '48px 12px 60px',

                            [mediaQuery.phone()]: {
                                margin: 'auto 0',
                                transform: 'translateY(-10vh)',

                                [mediaQuery.device('md')]: {
                                    transform: 'translateY(-5vh)',
                                    padding: '0px 12px 24px'
                                }
                            }
                        })}>
                            <Auto horizontalAlignment="center" className={css({
                                paddingBottom: '60px',

                                [mediaQuery.phone()]: {
                                    [mediaQuery.device('md')]: {
                                        paddingBottom: '24px'
                                    }
                                }
                            })}>
                                <LocalisedText variant="h1" fontSize={2.4} textKey={messageKey} className={css({
                                    textAlign: 'center'
                                })} />
                            </Auto>
                            {result.enabled && (
                                <>
                                    <Auto paddingBottom={24} horizontalAlignment="center">
                                        <LocalisedText variant="h2" textKey="overall-score" />
                                        <NonlocalisedText variant="h2" text={`${result.overall}`} />
                                    </Auto>
                                    <AutoHug direction="horizontal" spacing={24} className={css({
                                        paddingBottom: '96px',

                                        [mediaQuery.phone()]: {
                                            [mediaQuery.device('md')]: {
                                                paddingBottom: '24px'
                                            }
                                        }
                                    })}>
                                        <AutoHug spacing={12}>
                                            <LocalisedText variant="h3" textKey="noting-score" />
                                            <LocalisedText variant="h3" textKey="timing-score" />
                                        </AutoHug>
                                        <AutoHug spacing={12}>
                                            <NonlocalisedText variant="h3" text={`${result.noting}`} />
                                            <NonlocalisedText variant="h3" text={`${result.timing}`} />
                                        </AutoHug>
                                    </AutoHug>
                                </>
                            )}
                            <Auto direction="horizontal" horizontalAlignment="center" width={300} spacing={36}>
                                <AutoHug horizontalAlignment="center" onClick={onReplay} className={css({
                                    cursor: 'pointer'
                                })}>
                                    <PersonalisedIcon icon="replay" size={3} />
                                    <PersonalisedLocalisedText textKey="replay" />
                                </AutoHug>
                                {nextExercise && (
                                    <AutoHug horizontalAlignment="center" onClick={onNext} className={css({
                                        cursor: 'pointer'
                                    })}>
                                        <PersonalisedIcon icon="next" size={3} />
                                        <PersonalisedLocalisedText textKey="next" />
                                    </AutoHug>
                                )}
                            </Auto>
                        </Auto>
                    </Auto>
                </Overlay>
            )}
        </>
    )
}