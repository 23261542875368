import styled from "@emotion/styled"
import { useInputDevice } from "@mom/ui"
import { useEffect, useState } from "react"
import { Auto, AutoGap } from "../component/auto-layout"
import { useTheme } from "../context/theme"
import { LocalisedText } from "./text"

export default function InputSettings () {
    const inputDeviceProvider = useInputDevice()
    const { colors } = useTheme ()
    const [devices, setDevices] = useState(inputDeviceProvider.devices())
    const selectedDevice = devices.find(x => x.selected)

    const selectDevice = (id: string) => {
        inputDeviceProvider.connectDevice(id)
    }

    const statechanged = () => {
        setDevices(inputDeviceProvider.devices())
    }

    useEffect (() => {
        inputDeviceProvider.on('statechanged', statechanged)
        return () => {
            inputDeviceProvider.off('statechanged', statechanged)
        }
    })

    const DevicesUL = styled.ul({
        width: '100%',
        maxWidth: '600px',
        border: `1px solid ${colors.primaryColor.shade2}`
    })
    
    const DeviceLI = styled.li({
        padding: '16px 32px',
        borderBottom: `1px solid ${colors.primaryColor.shade2}`,
        cursor: 'pointer',
        ':last-child': {
            border: '0'
        },
        '&.selected': {
            background: colors.primaryColor.shade2
        }
    })
    
    return (
        <Auto>
            <LocalisedText variant="h2" textKey="input-devices"/>
            <AutoGap size={32} />
            {selectedDevice ? (
                <LocalisedText variant="paragraph" textKey="selected-input-device" textParam={{device: selectedDevice.name}} />
            ) : (
                <LocalisedText variant="paragraph" textKey="no-selected-input-device-message" />
            )}
            <AutoGap size={32} />
            <LocalisedText variant="h3" textKey="available-input-devices"></LocalisedText>
            <AutoGap size={16} />
            {Boolean(devices && devices.length) ? (
                <DevicesUL>
                    {devices.map(device => (
                        <DeviceLI
                            key={`midi-device-${device.id}`}
                            className={device.selected ? 'selected' : undefined}
                            onClick={() => selectDevice(device.id)}
                        >
                            {device.name}
                        </DeviceLI>
                    ))}
                </DevicesUL>
            ) : (
                <LocalisedText textKey="no-input-device-message" variant="paragraph" />
            )}
        </Auto>
    )
}