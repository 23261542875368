import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

export const useNoteTrackerPosition = (id: string) => {
    const { noteTracker  } = usePlayer (id)
    const [position, setPosition] = useState <{ x: number, y: number, staveCount: number } | undefined> (undefined)

    useEffect (() => {
        noteTracker.on('changePosition', setPosition)
        return () => {
            noteTracker.off('changePosition', setPosition)
        }
    })

    return position
}