import { useState } from "react";
import { Auto, AutoFill, AutoHug } from "../../component/auto-layout";
import { Button } from "../../hoc/button";
import { ProgressBar } from "../../hoc/progress-bar";
import { Upload as HOCUpload } from "../../hoc/upload";
import { asyncActions, useAppDispatch, useAppSelector } from "../../store";

export function Upload () {
    const dispatch = useAppDispatch()
    const uploadDetails = useAppSelector(x => x.etudeEditor.upload)
    const [files, setFiles] = useState <File[]> ([])
    
    const changeFiles = (event: { files: File[] }) => {
        setFiles(event.files)
    }

    const upload = async () => {
        if (files.length) {
            await dispatch(asyncActions.etudeEditor.upload(files))
            setFiles([])
        }
    }

    return (
        <>
            {uploadDetails ? (
                <ProgressBar value={uploadDetails.percentage ?? 0} />
            ) : (
                <Auto direction="horizontal" spacing={16}>
                    <Auto>
                        <HOCUpload labelKey="exercise-files" files={files} multiple onChange={changeFiles} />
                    </Auto>
                    <AutoHug>
                        <Button variant="inverted" textKey='upload' onClick={upload} />
                    </AutoHug>
                </Auto>
            )}
        </>
    )
}