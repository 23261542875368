import { css } from "@emotion/css"
import { PropsWithChildren } from "react"
import { Auto } from "../auto-layout"
import { LeftMenuStyle } from "./types"

interface LeftMenuItemProps {
    selected?: boolean
    style?: LeftMenuStyle
    onClick?: () => void
}

export function LeftMenuItem (props: PropsWithChildren<LeftMenuItemProps>) {
    const {
        selected,
        style,
        onClick,
        children
    } = props

    return (
        <Auto
            direction='horizontal'
            verticalAlignment='center'
            spacing={20}
            paddingLeft={16}
            paddingRight={16}
            paddingTop={10}
            paddingBottom={10}
            className={css({
                cursor: !selected ? 'pointer' : undefined,
                background: selected ? style?.selectedBackground : undefined,
                borderRadius: 10
            })}
            onClick={onClick}
        >
            {children}
        </Auto>
    )
}