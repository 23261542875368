import { Numeric as GenericNumeric, NumericStyle } from "../component/numeric"
import { useLocalisationContext } from "../context/localisation"
import { useTheme } from "../context/theme"

interface NumericProps {
    value?: number
    labelKey?: string
    className?: string
    onChange?: (value?: number) => void
}

export function Numeric (props: NumericProps) {
    const {
        value,
        labelKey,
        className,
        onChange
    } = props
    const { colors } = useTheme()
    const { localisationStrings } = useLocalisationContext()
    const style: NumericStyle = {
        borderColor: colors.primaryColor.shade2,
        textColor: colors.primaryColor.shade6
    }

    return (
        <GenericNumeric value={value} label={labelKey && localisationStrings[labelKey]} className={className} onChange={onChange} style={style} />
    )
}