import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

interface ResultControl {
    reset: () => void
}

export const useExerciseResult = (id: string) => {
    const { result } = usePlayer(id)
    const [exerciseResult, setExerciseResult] = useState (result.result)

    useEffect(() => {
        result.on('change', setExerciseResult)
        
        return () => {
            result.off('change', setExerciseResult)
        }
    })

    return exerciseResult
}

export const useExerciseResultControl = (id: string): ResultControl => {
    const { result } = usePlayer(id)
    return result
}