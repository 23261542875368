import { LevelCapabilitiesTypes } from "@mom/types"
import { PracticeTypes } from "@mom/types/domain/practice"
import { Config } from "../config"
import { MusicDataClient, PracticeAndHistoryClient, RecommendationsClient } from "../http"
import { WebSocketClient } from "../websocket"

export * from './evaluation-context'

export type ClassName<Type extends string> = {
    [key in Type]?: string
}

export const SortedUserNotes: PracticeTypes.UserNoteType[] = ['C', 'CD', 'D', 'DE', 'E', 'F', 'FG', 'G', 'GA', 'A', 'AB', 'B']

export const AccidentalAlters: { [key in LevelCapabilitiesTypes.AccidentalType]: number } = {
    'double-flat': -2,
    'flat': -1,
    'natural': 0,
    'sharp': 1,
    'double-sharp': 2
}

export interface Services {
    config: Config
    webSocketClient: WebSocketClient
    practiceAndHistoryClient: PracticeAndHistoryClient
    musicDataClient: MusicDataClient
    recommendationsClient: RecommendationsClient
}

export interface Etude extends EtudeDetails {
    id: string
}

export interface EtudeBriefDetails {
    id: string
    title: string
    description: string
    exerciseCount: number
    previewExerciseId: string
    exercises: {
        id: string
        title: string
    }[]
}

export interface EtudeDetailsResponse {
    id: string
    title: string
    description: string
    exerciseCount: number
    previewExerciseId: string
    exercises: {
        id: string
        title: string
        description: string
    }[]
}

export interface EtudeDetails {
    title: string
    description: string
    exercises: {
        id: string
        title: string
        description: string
    }[]
}

export interface EtudeExerciseDetails {
    id: string
    title: string
    etudeId: string
    etudeTitle: string
}
