import { SheetMusicTypes } from "@mom/types"
import { useEffect, useState } from "react"
import { usePlayer } from "./player-repository"

interface RenderingDetailsControl {
    setWidth (width: number): void
}

export const useRenderingDetails = (id: string) => {
    const player = usePlayer(id)
    const { renderingDetails } = player
    const [sheetMusicRenderingDetails, setSheetMusicRenderingDetails] = useState <SheetMusicTypes.SheetMusicRenderingDetails> (renderingDetails.renderingDetails)

    useEffect(() => {
        renderingDetails.on('changeRenderingDetails', setSheetMusicRenderingDetails)
        
        return () => {
            renderingDetails.off('changeRenderingDetails', setSheetMusicRenderingDetails)
        }
    })

    return sheetMusicRenderingDetails
}

export const useRenderingDetailsControl = (id: string): RenderingDetailsControl => {
    const { renderingDetails } = usePlayer(id)
    return renderingDetails
}