import { css } from "@emotion/css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAuth } from "../../auth";
import { Auto } from "../../component/auto-layout";
import { useServices } from "../../context/services";
import { LocalisedText } from "../../hoc/text";
import { EtudeDetailsResponse } from "../../types";
import EtudeDetails from "./etude-details";

export function SubscribedEtudes () {
    const auth = useAuth ()
    const navigate = useNavigate()
    const dispatch = useDispatch ()
    const { practiceAndHistoryClient } = useServices()
    const [etudes, setEtudes] = useState <EtudeDetailsResponse[] | undefined> (undefined)

    useEffect(() => {
        if (auth.isAuthenticated) {
            dispatch(async () => {
                const result = await practiceAndHistoryClient.getSubscribedEtudes()
                setEtudes(result)
            })
        }
    }, [auth.isAuthenticated])

    const play = async (etudeId: string, exerciseId: string) => {
        navigate (`/play/${etudeId}/${exerciseId}`)
    }

    const signin = () => {
        auth.navigateTo('signin')
    }

    return (
        <>
            {auth.isAuthenticated ? (
                <Auto>
                    {etudes && (
                        <>
                            {!etudes.length && (
                                <Auto horizontalAlignment="center" paddingTop={64}>
                                    <LocalisedText textKey="no-subscribed-etude" fontSize={1.4} />
                                </Auto>
                            )}
                            {etudes.map(etude => (
                                <EtudeDetails key={`etude-${etude.id}`} etude={etude} onPlay={(exerciseId) => play(etude.id, exerciseId)} />
                            ))}
                        </>
                    )}
                </Auto>
            ) : (
                <Auto
                    verticalAlignment="center"
                    horizontalAlignment="center"
                    spacing={4}
                    className={css({
                        height: '60vh'
                    })}
                >
                    <LocalisedText textKey="message-to-signin" fontSize={1.4} />
                    <LocalisedText textKey='signin' variant="link" fontSize={1.4} onClick={signin} />
                </Auto>
            )}
        </>
        
    )
}