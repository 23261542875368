import { PracticeAndHistoryTypes } from '@mom/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ExerciseState {
    subscribedExerciseDetails: PracticeAndHistoryTypes.SubscribedExerciseDetails[]
    exerciseDetails: {
        [key: string]: {
            id: string
            title: string
            etudeId: string
            etudeTitle: string
            timeline: any
        }
    }
}
  
const initialState: ExerciseState = {
    subscribedExerciseDetails: [],
    exerciseDetails: {}
}

export default createSlice({
    name: 'exercise',
    initialState,
    reducers: {
        setSubscribedExerciseDetails (state, action: PayloadAction<PracticeAndHistoryTypes.SubscribedExerciseDetails[]>) {
            state.subscribedExerciseDetails = action.payload
        },
        setExerciseDetails (state, action: PayloadAction<{ id: string, title: string, etudeId: string, etudeTitle: string, timeline: any }>) {
            const {
                id,
                title,
                etudeId,
                etudeTitle,
                timeline
            } = action.payload

            state.exerciseDetails[id] = {
                id,
                title,
                etudeId,
                etudeTitle,
                timeline
            }
        }
    }
})
