import { css } from "@emotion/css"
import styled from "@emotion/styled"
import React, { useEffect, useRef, useState } from "react"
import { Icon, IconStyle } from "./icon"

const Container = styled.div({
    padding: '4px 16px',
    borderRadius: '4px',
    display: 'inline-flex',
    alignItems: 'center'
})

const InnerContainer = styled.div({
    flex: '0 1 0',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
})

const Label = styled.div({
    fontSize: '1rem',
    lineHeight: '1.2rem',
    padding: '4px 0',
})

const Input = styled.input({
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontFamily: 'inherit',
    margin: 0,
    border: 0,
    padding: '4px 0',
    paddingLeft: '4px',
    outline: 'none',
    appearance: 'none',
    boxSizing: 'border-box',

    '::-webkit-outer-spin-button': {
        appearance: 'none',
        margin: 0
    },
    '::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0
    }
})

const HiddenContainer = styled.div({
    width: 0,
    height: 0,
    overflow: 'hidden'
})

const Span = styled.span({
    fontSize: '1rem',
    fontFamily: 'inherit',
    padding: '0 4px'
})

export interface NumericStyle {
    borderColor?: string
    textColor?: string
}

interface NumericProps {
    value?: number
    label?: string
    className?: string
    style?: NumericStyle
    onChange?: (value?: number) => void
}

export function Numeric (props: NumericProps) {
    const {
        value,
        label,
        className,
        style,
        onChange
    } = props
    const spanRef = useRef <HTMLSpanElement> (null)
    const inputRef = useRef <HTMLInputElement> (null)

    const iconStyle: IconStyle = {
        stroke: style?.textColor
    }

    const classes = [
        className,
        css({
            border: `1px solid ${style?.borderColor}`
        })
    ].filter(x => x)

    const preventNonnumeric = (e: React.KeyboardEvent) => {
        if(!/([0-9]|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|Tab)/.test(e.key)) {
            e.preventDefault()
        }
    }

    const change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.currentTarget.value
        if (text) {
            const newValue = parseInt(text)
            if (newValue >= 0 && text === `${newValue}`) {
                onChange && onChange(newValue)
            }
        } else {
            onChange && onChange(undefined)
        }
    }

    const increment = () => {
        const newValue = (value ?? 0) + 1
        onChange && onChange(newValue)
    }

    const decrement = () => {
        if (value) {
            if (value > 0) {
                onChange && onChange(value - 1)
            }
        } else {
            onChange && onChange(0)
        }
    }

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            const rect = spanRef.current.getBoundingClientRect()
            inputRef.current.style.width = `${rect.width}px`
        }
    })

    return (
        <Container className={classes.join(' ')}>
            <Input
                ref={inputRef}
                type='number'
                value={value ?? ''}
                className={css({
                    color: style?.textColor
                })}
                onChange={change}
                onKeyDown={preventNonnumeric}
            />
            <InnerContainer>
                {label && (
                    <Label className={css({
                        color: style?.textColor
                    })}>
                        {label}
                    </Label>
                )}
                <Icon icon="plus" style={iconStyle} onClick={increment} />
                <Icon icon="minus" style={iconStyle} onClick={decrement} />
            </InnerContainer>
            <HiddenContainer>
                <Span ref={spanRef}>{value}</Span>
            </HiddenContainer>
        </Container>
    )
}