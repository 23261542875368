import { WebSocketClient } from ".";

interface NativeClientOptions {
    endpoint: string
    tokenExchangeHandler: () => Promise<string>
}

const retries = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89]

export class NativeWSClient implements WebSocketClient {
    private client: WebSocket | null
    private eventHandlers: { [event: string]: ((data: any) => void)[] }
    private retryCount: number

    constructor (private props: NativeClientOptions) {
        this.eventHandlers = {}
        this.retryCount = 0
    }

    async connect(): Promise<void> {
        const onOpen = () => {
            console.log ('Websocket is opened')
            this.retryCount = 0
        }

        const onError = (event: Event) => {
            console.error ('Websocket error is occured', event)
        }

        const onMessage = (event: MessageEvent) => {
            console.log ('Websocket message is received')
            const { action, message } = JSON.parse(event.data)
            this._dispatch(action, message)
        }

        const retry = () => {
            if (this.client) {
                this.client.removeEventListener('open', onOpen)
                this.client.removeEventListener('close', retry)
                this.client.removeEventListener('error', onError)
                this.client.removeEventListener('message', onMessage)

                this.client = null
            }

            if (retries[this.retryCount]) {
                setTimeout(this.connect.bind(this), retries[this.retryCount] * 1000);
                this.retryCount++
            }
        }

        try {
            const { endpoint, tokenExchangeHandler } = this.props
            const token = await tokenExchangeHandler()

            if (!token) {
                return
            }

            this.client = new WebSocket(`${endpoint}?auth_token=${token}`)
            this.client.addEventListener('open', onOpen)
            this.client.addEventListener('close', retry)
            this.client.addEventListener('error', onError)
            this.client.addEventListener('message', onMessage)
        } catch (err) {
            console.error ('Websocket error is occured', err)
            retry()
        }
    }

    private _dispatch <T> (event: string, data: T) {
        if (this.eventHandlers[event]) {
            this.eventHandlers[event].forEach(handler => {
                handler(data)
            })
        }
    }

    subscribe<T>(event: string, handler: (data: T) => void): void {
        if (!this.eventHandlers[event]) {
            this.eventHandlers[event] = []
        }
        this.eventHandlers[event].push(handler)
    }
    unsubscribe<T>(event: string, handler: (data: T) => void): void {
        if (this.eventHandlers[event]) {
            const index = this.eventHandlers[event].findIndex(x => x === handler)
            if (index >= 0) {
                this.eventHandlers[event].splice(index)
            }
        }
    }

}