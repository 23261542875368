import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import { PropsWithChildren } from "react";

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxSizing: 'border-box'
})

const TabTitleContainer = styled.div({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    gap: '32px'
})

const TabTitle = styled.div({
    padding: '8px 0 6px',
    cursor: 'pointer',
    userSelect: 'none'
})

const TabUnderlineContainer = styled.div({
    position: 'relative',
    boxSizing: 'border-box',
    height: '.25rem'
})

const TabUnderline = styled.div({
    position: 'absolute',
    boxSizing: 'border-box',
    height: '.25rem',
    borderTopLeftRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    transition: 'left 200ms cubic-bezier(0, 0, 0.2, 1), width 200ms cubic-bezier(0, 0, 0.2, 1)'
})

export interface TabsClassNames {
    container?: string
}

export interface TabsStyle {
    activeColor?: string
    textColor?: string
}

interface TabsProps {
    value?: number
    style?: TabsStyle
    classNames?: TabsClassNames
    titles?: string[]
    onChange?: (tabIndex: number) => void
}

export function Tabs (props: PropsWithChildren<TabsProps>) {
    const {
        value,
        titles,
        style,
        classNames,
        children,
        onChange
    } = props
    const [underline, setUnderline] = useState <{ left: number, width: number }> ()
    const titleRef = useRef <HTMLDivElement> (null)
    const tabs = React.Children.toArray(children).filter((x: any) => x.type['__mom__type'] === 'tab')
    const tabTitles = tabs.map((tab: any) => tab.props.title)

    const moveUnderlineTo = (el?: HTMLDivElement | null) => {
        if (!el) return

        const parentRect = el.parentElement?.getBoundingClientRect()
        if (parentRect) {
            const titleRect = el.getBoundingClientRect()

            const newUnderline = {
                left: titleRect.left - parentRect.left,
                width: titleRect.width
            }

            if (!underline || underline.left !== newUnderline.left || underline.width !== newUnderline.width) {
                setUnderline(newUnderline)
            }
        }
    }

    useEffect(() => {
        moveUnderlineTo(titleRef.current)
    })

    const select = (event: React.MouseEvent<HTMLDivElement>, value: number) => {
        onChange && onChange(value)
    }

    return (
        <Container className={classNames?.container}>
            <TabTitleContainer>
                {tabTitles.map((title, index) => (
                    <TabTitle
                        key={`tab-${index}`}
                        ref={index === value ? titleRef : undefined}
                        className={css({
                            color: index === value ? style?.activeColor : style?.textColor
                        })}
                        onClick={(e) => select(e, index)}
                    >
                        {title || titles?.[index]}
                    </TabTitle>
                ))}
            </TabTitleContainer>
            <TabUnderlineContainer>
                {underline && (
                    <TabUnderline className={css({
                        backgroundColor: style?.activeColor,
                        left: underline.left,
                        width: underline.width
                    })}/>
                )}
            </TabUnderlineContainer>
            {typeof value === 'number' && tabs[value]}
        </Container>
    )
}