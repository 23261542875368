import { Colors, SecondaryColorType, Theme, SecondaryColor, PrimaryColor } from "./types"

export const primaryColors: { [theme in Theme]: PrimaryColor } = {
    'dark': {
        shade1: '#0C0C0C',
        shade2: '#353535',
        shade3: '',
        shade4: '',
        shade5: '#AAAAAA',
        shade6: '#F0F0F0'
    },
    'dimmed': {
        shade1: '#14202E',
        shade2: '#323E4C',
        shade3: '',
        shade4: '',
        shade5: '#8B99A5',
        shade6: '#D1DFEB'
    },
    'light': {
        shade1: '#FFFFFF',
        shade2: '#E1E1E1',
        shade3: '',
        shade4: '',
        shade5: '#606060',
        shade6: '#0F0F0F'
    }
}

export const secondaryColors: { [secondaryColor in SecondaryColorType]: SecondaryColor } = {
    blue: {
        shade1: '#2C91EA'
    },
    green: {
        shade1: '#2FAF73'
    },
    orange: {
        shade1: '#F87123'
    },
    purple: {
        shade1: '#6952FA'
    },
    red: {
        shade1: '#EF2776'
    },
    yellow: {
        shade1: '#FCCD2E'
    }
}
