import { SquaredPlusIcon } from "./squared-plus"
import { CircledXIcon } from "./circled-x"
import { DownIcon } from "./down"
import { GearIcon } from "./gear"
import { HomeIcon } from "./home"
import { OneNoteIcon } from "./one-note"
import { TwoNotesIcon } from "./two-notes"
import { UpIcon } from "./up"
import { UploadIcon } from "./upload"
import { AlertIcon } from "./alert"
import { WarningIcon } from "./warning"
import { InfoIcon } from "./info"
import { SuccessIcon } from "./success"
import { CloseIcon } from "./close"
import { css } from "@emotion/css"
import { SearchIcon } from "./search"
import { PlayIcon } from "./play"
import { SubscribeIcon } from "./subscribe"
import { LikeIcon } from "./like"
import { CircledPlayIcon } from "./circled-play"
import { CheckIcon } from "./check"
import { DeleteIcon } from "./delete"
import { EditIcon } from "./edit"
import React, { useRef } from "react"
import { MinusIcon } from "./minus"
import { PlusIcon } from "./plus"
import { LandscapeOrientationIcon } from "./landscape-orientation"
import { VolumeIcon } from "./volume"
import { PauseIcon } from "./pause"
import { PlaylistIcon } from "./playlist"
import { ReplayIcon } from "./replay"
import { NextIcon } from "./next"
import { SignoutIcon } from "./signout"

const iconMap = {
    'alert': AlertIcon,
    'check': CheckIcon,
    'circled-play': CircledPlayIcon,
    'circled-x': CircledXIcon,
    'close': CloseIcon,
    'delete': DeleteIcon,
    'down': DownIcon,
    'edit': EditIcon,
    'gear': GearIcon,
    'home': HomeIcon,
    'info': InfoIcon,
    'landscape-orientation': LandscapeOrientationIcon,
    'like': LikeIcon,
    'minus': MinusIcon,
    'next': NextIcon,
    'one-note': OneNoteIcon,
    'pause': PauseIcon,
    'play': PlayIcon,
    'playlist': PlaylistIcon,
    'plus': PlusIcon,
    'replay': ReplayIcon,
    'search': SearchIcon,
    'signout': SignoutIcon,
    'squared-plus': SquaredPlusIcon,
    'subscribe': SubscribeIcon,
    'success': SuccessIcon,
    'two-notes': TwoNotesIcon,
    'up': UpIcon,
    'upload': UploadIcon,
    'volume': VolumeIcon,
    'warning': WarningIcon
}

const fill: { [icon in IconType]?: boolean } = {
    'signout': true,
    'volume': true
}

export type IconType = keyof typeof iconMap

export interface IconStyle {
    stroke?: string
}

interface IconProps {
    icon: IconType
    style?: IconStyle
    className?: string
    /**
     * Size of the icon in rem units
     */
    size?: number
    onClick?: (e: React.MouseEvent) => void
}

export const Icon = React.forwardRef <SVGSVGElement, IconProps> ((props, ref) => {
    const {
        icon,
        style,
        className,
        size,
        onClick
    } = props

    useRef <SVGSVGElement> ()
    const definedSize = typeof size === 'number' && size > 0 ? `${size}rem` : '24px'
    const classes = [
        className,
        css({
            stroke: style?.stroke,
            width: definedSize,
            height: definedSize,
            fill: fill[icon] ? undefined : 'none',
            cursor: onClick ? 'pointer' : undefined
        })
    ].filter(x => x)

    const IconElement = iconMap[icon]
    return (
        <svg ref={ref} viewBox="0 0 24 24" className={classes.join(' ')} onClick={onClick}>
            <g>
                <IconElement />
            </g>
        </svg>
    )
})