import { useEffect, useState } from "react";
import { useServices } from "../../context/services";
import Confirmation from "../../hoc/confirmation";
import { EditableExercise } from "../../hoc/editable-exercise";
import { PreviewSheetMusic } from "../../hoc/sheet-music";
import { actions, useAppDispatch, useAppSelector } from "../../store";

interface ExerciseProps {
    id: string
    title: string
    description: string
}

export function Exercise (props: ExerciseProps) {
    const {
        id,
        title,
        description
    } = props
    const dispatch = useAppDispatch()
    const { config } = useServices()
    const [musicTimeline, setMusicTimeline] = useState <any> ()
    const [openConfirmation, setOpenConfirmation] = useState (false)
    const exercise = useAppSelector(x => x.etudeEditor.form.exercises.find(x => x.id === id))

    const changeExerciseDetails = (value: { id: string, title: string, description: string }) => {
        dispatch(actions.etudeEditor.setEtudeExerciseDetails(value))
    }

    const deleteExercise = () => {
        dispatch(actions.etudeEditor.removeEtudeExercise(id))
    }

    return (
        <>
            <EditableExercise id={id} title={title} description={description} onChange={changeExerciseDetails} onDelete={() => setOpenConfirmation(true)} previewImageSource={exercise?.previewImageReady ? `${config.cdn.baseUrl}/images/preview-image/${id}` : undefined} />
            <Confirmation open={openConfirmation} messageKey="delete-exercise-confirmation-message" messageParam={{ title }} onConfirm={deleteExercise} onCancel={() => setOpenConfirmation(false)} />
        </>
    )
}