import { useNavigate } from "react-router";
import { MobileMenu as GenericMobileMenu, MobileMenuItemDetails, MobileMenuStyle } from "../component/mobile-menu";
import { useTheme } from "../context/theme";
import { useLocalisationContext } from "../context/localisation";
import { Overlay } from "./overlay";

const routeMap: { [key: string]: string } = {
    "appearances": "/settings",
    "input-devices": "/settings/input-devices"
}

interface MobileSettingsMenuProps {
    onClose: () => void
}

export function MobileSettingsMenu (props: MobileSettingsMenuProps) {
    const {
        onClose
    } = props
    const { localisationStrings } = useLocalisationContext()
    const theme = useTheme()
    const navigate = useNavigate()

    const menuItems = [{
        id: 'appearances',
        text: localisationStrings['appearances']
    }, {
        id: 'input-devices',
        text: localisationStrings['input-devices']
    }]

    const style: MobileMenuStyle = {
        backgroundColor: theme.colors.primaryColor.shade1,
        borderColor: theme.colors.primaryColor.shade2,
        textColor: theme.colors.primaryColor.shade6
    }

    const click = (item: MobileMenuItemDetails) => {
        const routePath = routeMap[item.id]
        if (routePath) {
            navigate(routePath)
        }
        onClose()
    }

    return (
        <Overlay open={true}>
            <GenericMobileMenu items={menuItems} title={localisationStrings['settings']} style={style} onClick={click} onClose={onClose}></GenericMobileMenu>
        </Overlay>
    )
}