export function DeleteIcon() {
    return (
        <>
            <path d="M14 11V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 7V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 7H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 7L9 3H15L17 7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </>
    )
}