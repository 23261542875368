import { LevelCapabilitiesTypes, MusicDataTypes, SheetMusicTypes } from '@mom/types'
import { PracticeTypes } from '@mom/types/domain/practice'
import { sheetMusic } from '@mom/ui'
import { AccidentalAlters, MeasureEvaluationContext, NoteGroupEvaluationContext, SheetMusicEvaluationContext, SheetMusicNoteEvaluationResult, SortedUserNotes, StaveEvaluationContext, StaveNoteEvaluationContext, UserNoteEvaluationResult, UserNoteToEvaluate } from '../types'

interface CreateSheetMusicEvaluationContextOptions {
    width?: number
}

interface EvaluateUserNoteOptions {
    thresholds: {
        lateEarly: number
        tooLateEarly: number
        limit: number
    }
    indexes?: {
        measure?: number
        noteGroup?: number
    }
}

export function createSheetMusicEvaluationContext (renderingDetails: SheetMusicTypes.SheetMusicRenderingDetails, timeline: MusicDataTypes.SheetMusicTimeline, options: CreateSheetMusicEvaluationContextOptions): SheetMusicEvaluationContext {
    let {width} = options
    width = width || 1000

    const evaluationContext: SheetMusicEvaluationContext = {
        measures: []
    }
    let timedMeasureIndex = 0
    for (const [lineIndex, line] of renderingDetails.lines.entries()) {
        for (const [measureIndex, measure] of line.measures.entries()) {
            const accidentalIndex: MusicDataTypes.SheetMusicAccidentalsIndex[] = []
            const timedMeasure = timeline.measures[timedMeasureIndex ++]
            const evaluationMeasure: MeasureEvaluationContext = {
                clefs: measure.clefs.map(x => x?.clef),
                keySignatures: measure.keySignatures.map(x => x?.keySignature),
                staves: line.staves.map(x => ({ position: x.position })),
                noteGroups: []
            }
            for (const [noteGroupIndex, noteGroup] of measure.noteGroups.entries()) {
                const timedNoteGroup = timedMeasure.noteGroups[noteGroupIndex]

                // Find next note
                const nextNoteGroup = measure.noteGroups[noteGroupIndex + 1] ||
                    line.measures[measureIndex + 1]?.noteGroups[0] ||
                    renderingDetails.lines[lineIndex + 1]?.measures[0]?.noteGroups[0]

                const evaluationNoteGroup: NoteGroupEvaluationContext = {
                    staveNotes: [],
                    position: noteGroup.position,
                    nextPosition: {
                        x: !nextNoteGroup || nextNoteGroup.position.y !== noteGroup.position.y ? width : nextNoteGroup.position.x,
                        y: noteGroup.position.y
                    },
                    size: noteGroup.size,
                    startDuration: timedNoteGroup.startDuration,
                    endDuration: timedNoteGroup.endDuration
                }
                for (const [staveIndex, staveNote] of noteGroup.staveNotes.entries()) {
                    const timedStaveNote = timedNoteGroup.staveNotes[staveIndex]

                    if (staveNote && timedStaveNote) {
                        evaluationNoteGroup.staveNotes.push(staveNote && timedStaveNote && {
                            note: timedStaveNote.note,
                            accidentalIndex: JSON.parse(JSON.stringify(accidentalIndex[staveIndex] || {})),
                            position: staveNote.position,
                            startDuration: timedStaveNote.startDuration,
                            endDuration: timedStaveNote.endDuration
                        })

                        const note = timedStaveNote.note
                        const noteAccidentals = note.type === 'chord' ? note.notes : (note.type === 'note' ? [note] : [])
                        const staveAccidentals = accidentalIndex[staveIndex] = accidentalIndex[staveIndex] || {}
                        noteAccidentals.forEach(noteAccidental => {
                            const accidentalNote = staveAccidentals[noteAccidental.note] = staveAccidentals[noteAccidental.note] || {}
                            accidentalNote[noteAccidental.octave] = noteAccidental.accidental
                        })
                    }
                    else {
                        evaluationNoteGroup.staveNotes.push(undefined)
                    }
                }
                evaluationMeasure.noteGroups.push(evaluationNoteGroup)
            }
            evaluationContext.measures.push(evaluationMeasure)
        }
    }
    return evaluationContext
}

export function evaluateUserNote (evaluationContext: SheetMusicEvaluationContext, userNote: UserNoteToEvaluate, options: EvaluateUserNoteOptions): SheetMusicNoteEvaluationResult {
    const { thresholds, indexes } = options
    const limit = thresholds.limit
    const index = {
        measure: indexes?.measure || 0,
        noteGroup: indexes?.noteGroup || 0
    }
    const lastMeasure = evaluationContext.measures[evaluationContext.measures.length - 1]
    const lastNoteGroup = lastMeasure.noteGroups[lastMeasure.noteGroups.length - 1]
    const totalDuration = lastNoteGroup.endDuration
    const normalizedUserNoteStartDuration = userNote.startDuration % totalDuration
    const normalizedUserNote = {
        ...userNote,
        startDuration: normalizedUserNoteStartDuration < lastNoteGroup.startDuration 
            ? normalizedUserNoteStartDuration 
            : (normalizedUserNoteStartDuration - lastNoteGroup.startDuration) < (lastNoteGroup.endDuration - normalizedUserNoteStartDuration)
                ? normalizedUserNoteStartDuration
                : normalizedUserNoteStartDuration - totalDuration,    
        endDuration: userNote.endDuration ? userNote.endDuration % totalDuration : undefined
    }
    const currentClefs: MusicDataTypes.SheetMusicClef[] = []
    const currentKeySignatures: MusicDataTypes.SheetMusicKeySignature[] = []

    let firstNoteGroup: NoteGroupEvaluationContext | undefined = undefined
    let secondNoteGroup: NoteGroupEvaluationContext | undefined = undefined

    let firstMeasure: MeasureEvaluationContext | undefined = undefined
    let secondMeasure: MeasureEvaluationContext | undefined = undefined

    loop:
    // Iterate all measures in the evaluation context
    for (; index.measure < evaluationContext.measures.length; index.measure ++, index.noteGroup = 0) {
        const measure = evaluationContext.measures[index.measure]
        // Store the current clef for each stave
        measure.clefs?.forEach((clef, index) => {
            if (clef) {
                currentClefs[index] = clef
            }
        })
        // Store the current key signature for each stave
        measure.keySignatures?.forEach((keySignature, index) => {
            if (keySignature) {
                currentKeySignatures[index] = keySignature
            }
        })

        // Iterate all note groups in the measure
        for (; index.noteGroup < measure.noteGroups.length; index.noteGroup ++) {
            secondNoteGroup = measure.noteGroups[index.noteGroup]
            secondMeasure = measure

            // Break the outer loop when user note is earlier than second note
            if (normalizedUserNote.startDuration < secondNoteGroup.startDuration) {
                break loop
            }

            firstNoteGroup = measure.noteGroups[index.noteGroup]
            firstMeasure = measure
        }
    }

    const { noteGroup: noteGroupForEvaluation, measure } = (
        !firstNoteGroup || !secondNoteGroup || firstNoteGroup === secondNoteGroup
            ? (
                firstNoteGroup
                    ? {
                        noteGroup: firstNoteGroup,
                        measure: firstMeasure
                    } : {
                        noteGroup: secondNoteGroup,
                        measure: secondMeasure
                    }
            ) : (
                (normalizedUserNote.startDuration - firstNoteGroup.startDuration) < (secondNoteGroup.startDuration - normalizedUserNote.startDuration)
                    ? {
                        noteGroup: firstNoteGroup,
                        measure: firstMeasure
                    } : {
                        noteGroup: secondNoteGroup,
                        measure: secondMeasure
                    }
            )
    )
    

    const result = evaluateUserNoteWithNoteGroup(noteGroupForEvaluation, normalizedUserNote, options)

    if (result.noting === 'correct' && noteGroupForEvaluation) {
        const clef = currentClefs[result.staveIndex]
        const keySignature = currentKeySignatures[result.staveIndex] || { keySignature: { fifths: 0 } }
        const accidentalIndex = noteGroupForEvaluation.staveNotes[result.staveIndex]?.accidentalIndex || {}
        return {
            noteDetails: sheetMusic.getNoteRenderingDetails(result.note, clef, keySignature, accidentalIndex, noteGroupForEvaluation.position),
            noting: 'correct',
            timing: result.timing
        }
    } else {
        const position = calculatePositionOfUserNote(normalizedUserNote, firstNoteGroup || secondNoteGroup, currentClefs, measure?.staves || [])
        const clef = currentClefs[position.staveIndex]
        const keySignature = currentKeySignatures[position.staveIndex] || { keySignature: { fifths: 0 } }
        const accidentalIndex = noteGroupForEvaluation?.staveNotes[position.staveIndex]?.accidentalIndex || {}
        const note: MusicDataTypes.SheetMusicNote = {
            type: 'note',
            note: normalizedUserNote.note[0] as LevelCapabilitiesTypes.NoteType,
            octave: normalizedUserNote.octave as LevelCapabilitiesTypes.OctaveType,
            accidental: normalizedUserNote.note.length === 1 ? 'natural' : 'sharp',
            duration: 'quarter',
            dotted: false
        }
        return {
            noteDetails: sheetMusic.getNoteRenderingDetails(note, clef, keySignature, accidentalIndex, position),
            noting: 'wrong'
        }
    }
}

function evaluateUserNoteWithNoteGroup (refNoteGroup: NoteGroupEvaluationContext | undefined, userNote: UserNoteToEvaluate, options: EvaluateUserNoteOptions): UserNoteEvaluationResult {
    if (!refNoteGroup) {
        return { noting: 'wrong' }
    }

    const userNoteIndex = getUserNoteMIDIIndex(userNote)
    const matchingNoteStaveIndex = refNoteGroup.staveNotes.findIndex(
        staveNote => staveNote?.note.type === 'note' ? userNoteIndex === getStaveNoteMIDIIndex(staveNote.note) :
            staveNote?.note.type === 'chord' ? staveNote.note.notes.find(chordNote => userNoteIndex === getStaveNoteMIDIIndex(chordNote)) :
            false
    )
    const matchingNote = refNoteGroup.staveNotes[matchingNoteStaveIndex]

    if (userNoteIndex !== undefined && matchingNote) {
        const timing = evaluateUserNoteTiming(matchingNote, userNote, options)

        if (timing) {
            return {
                noting: 'correct',
                timing,
                userNoteIndex,
                note: matchingNote.note,
                staveIndex: matchingNoteStaveIndex
            }
        }
    }

    return { noting: 'wrong' }
}

function evaluateUserNoteTiming (matchingStaveNote: StaveNoteEvaluationContext, userNote: UserNoteToEvaluate, options: EvaluateUserNoteOptions): PracticeTypes.TimingEvaluationType | undefined {
    const { thresholds } = options
    const lateEarly = thresholds.lateEarly
    const tooLateEarly = thresholds.tooLateEarly
    const limit = thresholds.limit

    const timeDiff = userNote.startDuration - matchingStaveNote.startDuration
    if (timeDiff < -limit || limit < timeDiff) {
        return undefined
    } else if (timeDiff < -tooLateEarly) {
        return 'too-early'
    } else if (timeDiff < -lateEarly) {
        return 'early'
    } else if (timeDiff > tooLateEarly) {
        return 'too-late'
    } else if (timeDiff > lateEarly) {
        return 'late'
    } else {
        return 'on-time'
    }
}

function calculatePositionOfUserNote (userNote: UserNoteToEvaluate, noteGroup: NoteGroupEvaluationContext | undefined, clefs: MusicDataTypes.SheetMusicClef[],staves: StaveEvaluationContext[]): { x: number, y: number, staveIndex: number } {
    const fClefIndex = clefs.findIndex(x => x && x.sign === 'F')
    const gClefIndex = clefs.findIndex(x => x && x.sign === 'G')
    const noteIndex = getUserNoteMIDIIndex(userNote)

    let staveIndex = clefs.findIndex(x => x)
    if (noteIndex !== undefined) {
        if (noteIndex >= 60 && gClefIndex >= 0) {
            staveIndex = gClefIndex
        } else if (noteIndex < 60 && fClefIndex >= 0) {
            staveIndex = fClefIndex
        }
    }

    const x = noteGroup 
        ? noteGroup.position.x + (noteGroup.nextPosition.x - noteGroup.position.x) * (userNote.startDuration - noteGroup.startDuration) / (noteGroup.endDuration - noteGroup.startDuration)
        : staves[staveIndex].position.x
    const y = staves[staveIndex].position.y

    return {
        x,
        y,
        staveIndex
    }
}

// 60 is C4 note, 0 is C-1 note
function getStaveNoteMIDIIndex (note: { note: LevelCapabilitiesTypes.NoteType, octave: LevelCapabilitiesTypes.OctaveType, accidental: LevelCapabilitiesTypes.AccidentalType }) {
    const noteIndex = SortedUserNotes.findIndex(x => x === note.note)

    if (noteIndex >= 0 && note.octave !== undefined) {
        return (note.octave + 1) * 12 + noteIndex + AccidentalAlters[note.accidental || 'natural']
    }
}

// 60 is C4 note, 0 is C-1 note
function getUserNoteMIDIIndex (note: UserNoteToEvaluate) {
    const noteIndex = SortedUserNotes.findIndex(x => x === note.note)

    if (noteIndex >= 0 && note.octave !== undefined) {
        return (note.octave + 1) * 12 + noteIndex
    }
}
