import { css } from "@emotion/css";
import { ReactNode } from "react";
import { EtudeDetails as GenericEtudeDetails, EtudeDetailsStyle } from "../component/etude-details";
import { EtudeStats, EtudeStatsStyle } from "../component/etude-stats";
import { Col, Row } from "../component/grid-layout";
import { useTheme } from "../context/theme";
import { CollapsablePanel } from "./collapsable-panel";

interface Exercise {
    id: string
    title: string
    description: string
}

interface SubscribedEtudeDetailsProps {
    title: string
    subtitle?: string
    description?: string
    previewImageSource?: string
    /**
     * React node that represents the preview image
     * @deprecated
     * @todo Remove the previewImageElement after preview images are stored as image files
     */
    previewImageElement?: ReactNode
    stats?: {
        played?: number
        liked?: number
        subscribed?: number
    }
    exercises?: Exercise[]
    exerciseElement: (exercise: Exercise) => ReactNode
}

export function SubscribedEtudeDetails (props: SubscribedEtudeDetailsProps) {
    const {
        title,
        subtitle,
        description,
        previewImageSource,
        previewImageElement,
        stats,
        exercises,
        exerciseElement
    } = props
    const { colors } = useTheme()
    
    const style: EtudeDetailsStyle = {
        primaryTextColor: colors.primaryColor.shade6,
        secondaryTextColor: colors.primaryColor.shade5
    }

    const statsStyle: EtudeStatsStyle = {
        textColor: colors.primaryColor.shade6
    }

    const exercisesTitle = !exercises || exercises.length <= 0 ? 'no-exercise' : exercises.length > 1 ? 'count-of-exercises' : 'count-of-exercise'
    return (
        <GenericEtudeDetails
            title={title}
            subtitle={subtitle}
            description={description}
            previewImageSource={previewImageSource}
            previewImageElement={previewImageElement}
            style={style}
            hideActionButtons
            hideImageInPhone
            belowSubtitleDetails={
                <EtudeStats played={stats?.played} liked={stats?.liked} subscribed={stats?.subscribed} style={statsStyle} className={css({ width: '210px' })} />
            }
            belowDescriptionDetails={
                exerciseElement &&
                    <CollapsablePanel labelKey={exercisesTitle} labelParams={{ count: exercises?.length || 0 }} disable>
                        {Boolean(exercises && exercises.length) && (
                            <Row>
                                {exercises?.map(exercise => (
                                    <Col key={`exercise-${exercise.id}`}  all={12} sm={6} xl={4}>
                                        {exerciseElement && exerciseElement (exercise)}
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </CollapsablePanel>
            }
        />
    )
}